import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Input from '@material-ui/core/InputBase';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import showIcon from '../../../assets/icons/show.svg';
import hideIcon from '../../../assets/icons/hide.svg';
import checkIcon from '../../../assets/icons/checkmark.svg';
import uncheckIcon from '../../../assets/icons/uncheck.svg';

import styles from '../styles/inputStyles';
import FieldWrap from '../FieldWrap';

const ShowPasswordField = ({
  classes,
  input,
  meta: { touched, error },
  inputProps,
  password,
  withMatch,
  label,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isMatch, setIsMatch] = useState(false);

  useEffect(() => {
    if (withMatch) {
      const { value } = input;
      if (password) setIsMatch(password === value);
    }
  }, [password, input, withMatch]);

  const handleSetShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <FieldWrap
        label={label}
        touched={touched}
        error={error}
      >
        <div className={classes.inputWrap}>
          <FormControl className={classes.input}>
            <Input
              {...input}
              {...inputProps}
              type={showPassword ? 'text' : 'password'}
              className={classes.input}
              classes={{
                input: classes.input,
              }}
              startAdornment={withMatch && (
                <InputAdornment position="start">
                  <div>
                    <img src={isMatch ? checkIcon : uncheckIcon} alt="icon" />
                  </div>
                </InputAdornment>
              )}
              endAdornment={(
                <InputAdornment position="end">
                  <ButtonBase onClick={handleSetShowPassword}>
                    <img src={showPassword ? hideIcon : showIcon} alt="icon" />
                  </ButtonBase>
                </InputAdornment>
              )}
              autoComplete="new-password"
            />
          </FormControl>
        </div>
      </FieldWrap>
    </div>
  );
};

ShowPasswordField.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  inputProps: PropTypes.shape({}),
  input: PropTypes.shape({
    value: PropTypes.string,
  }),
  withMatch: PropTypes.bool,
  password: PropTypes.string,
  label: PropTypes.node,
};

ShowPasswordField.defaultProps = {
  classes: {},
  input: null,
  inputProps: null,
  withMatch: false,
  meta: null,
  password: '',
  label: null,
};

export default withStyles(styles)(ShowPasswordField);
