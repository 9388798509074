import { API_ENDPOINTS } from '../../../contants/api';
import { API_REQUEST } from '../api/actions';

export const AUTH_SIGN_UP_START = 'AUTH_SIGN_UP_START';
export const AUTH_SIGN_UP_SUCCESS = 'AUTH_SIGN_UP_SUCCESS';
export const AUTH_SIGN_UP_ERROR = 'AUTH_SIGN_UP_ERROR';

export const AUTH_CHECK_PRODUCT_CONNECT_START = 'AUTH_CHECK_PRODUCT_CONNECT_START';
export const AUTH_CHECK_PRODUCT_CONNECT_SUCCESS = 'AUTH_CHECK_PRODUCT_CONNECT_SUCCESS';
export const AUTH_CHECK_PRODUCT_CONNECT_ERROR = 'AUTH_CHECK_PRODUCT_CONNECT_ERROR';

export const AUTH_CONNECT_PRODUCT_START = 'AUTH_CONNECT_PRODUCT_START';
export const AUTH_CONNECT_PRODUCT_SUCCESS = 'AUTH_CONNECT_PRODUCT_SUCCESS';
export const AUTH_CONNECT_PRODUCT_ERROR = 'AUTH_CONNECT_PRODUCT_ERROR';

export const AUTH_SIGN_IN_START = 'AUTH_SIGN_IN_START';
export const AUTH_SIGN_IN_SUCCESS = 'AUTH_SIGN_IN_SUCCESS';
export const AUTH_SIGN_IN_ERROR = 'AUTH_SIGN_IN_ERROR';

export const REFRESH_TOKEN_START = 'REFRESH_TOKEN_START';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR';

export const AUTH_RESENT_EMAIL_START = 'AUTH_RESENT_EMAIL_START';
export const AUTH_RESENT_EMAIL_SUCCESS = 'AUTH_RESENT_EMAIL_SUCCESS';
export const AUTH_RESENT_EMAIL_ERROR = 'AUTH_RESENT_EMAIL_ERROR';

export const AUTH_CONFIRM_EMAIL_START = 'AUTH_CONFIRM_EMAIL_START';
export const AUTH_CONFIRM_EMAIL_SUCCESS = 'AUTH_CONFIRM_EMAIL_SUCCESS';
export const AUTH_CONFIRM_EMAIL_ERROR = 'AUTH_CONFIRM_EMAIL_ERROR';

export const AUTH_FORGOT_PASSWORD_START = 'AUTH_FORGOT_PASSWORD_START';
export const AUTH_FORGOT_PASSWORD_SUCCESS = 'AUTH_FORGOT_PASSWORD_SUCCESS';
export const AUTH_FORGOT_PASSWORD_ERROR = 'AUTH_FORGOT_PASSWORD_ERROR';

export const AUTH_CONFIRM_PASSWORD_START = 'AUTH_CONFIRM_PASSWORD_START';
export const AUTH_CONFIRM_PASSWORD_SUCCESS = 'AUTH_CONFIRM_PASSWORD_SUCCESS';
export const AUTH_CONFIRM_PASSWORD_ERROR = 'AUTH_CONFIRM_PASSWORD_ERROR';

export const LOG_OUT = 'LOG_OUT';
export const RESET_GLOBAL_LOADING = 'RESET_GLOBAL_LOADING';
export const CHECK_TOKEN = 'CHECK_TOKEN';
export const SET_LOGOUT_STATUS = 'SET_LOGOUT_STATUS';
export const CLEAR_LOGOUT_STATUS = 'CLEAR_LOGOUT_STATUS';

export const resetGlobalLoading = (payload) => ({
  type: RESET_GLOBAL_LOADING,
  payload,
});

export const setLogoutStatus = (payload) => ({
  type: SET_LOGOUT_STATUS,
  payload,
});

export const clearLogoutStatus = (payload) => ({
  type: CLEAR_LOGOUT_STATUS,
  payload,
});

export const checkToken = (payload) => ({
  type: CHECK_TOKEN,
  payload,
});

export const logOut = (payload) => ({
  type: LOG_OUT,
  payload,
});

export const forgotPassword = (data) => ({
  type: API_REQUEST,
  payload: {
    url: API_ENDPOINTS.AUTH.FORGOT_PASSWORD,
    data,
    method: 'post',
  },
  dispatch: {
    START: AUTH_FORGOT_PASSWORD_START,
    SUCCESS: AUTH_FORGOT_PASSWORD_SUCCESS,
    ERROR: AUTH_FORGOT_PASSWORD_ERROR,
  },
  meta: {
    skipToast: true,
  },
});

export const confirmPassword = (data) => ({
  type: API_REQUEST,
  payload: {
    url: API_ENDPOINTS.AUTH.CONFIRM_PASSWORD,
    data,
    method: 'post',
  },
  dispatch: {
    START: AUTH_CONFIRM_PASSWORD_START,
    SUCCESS: AUTH_CONFIRM_PASSWORD_SUCCESS,
    ERROR: AUTH_CONFIRM_PASSWORD_ERROR,
  },
  meta: {
    skipToast: true,
  },
});

export const refreshToken = (data) => ({
  type: API_REQUEST,
  payload: {
    url: API_ENDPOINTS.AUTH.REFRESH_TOKEN,
    data,
    method: 'post',
  },
  dispatch: {
    START: REFRESH_TOKEN_START,
    SUCCESS: REFRESH_TOKEN_SUCCESS,
    ERROR: REFRESH_TOKEN_ERROR,
  },
  meta: {
    skipToast: true,
  },
});

export const signIn = (data) => ({
  type: API_REQUEST,
  payload: {
    url: API_ENDPOINTS.AUTH.LOGIN,
    data,
    method: 'post',
  },
  dispatch: {
    START: AUTH_SIGN_IN_START,
    SUCCESS: AUTH_SIGN_IN_SUCCESS,
    ERROR: AUTH_SIGN_IN_ERROR,
  },
  meta: {
    skipAuthHeader: true,
    skipToast: true,
  },
});

export const checkProductConnect = () => ({
  type: API_REQUEST,
  payload: {
    url: API_ENDPOINTS.AUTH.CHECK_PRODUCT_CONNECT,
    method: 'get',
  },
  dispatch: {
    START: AUTH_CHECK_PRODUCT_CONNECT_START,
    SUCCESS: AUTH_CHECK_PRODUCT_CONNECT_SUCCESS,
    ERROR: AUTH_CHECK_PRODUCT_CONNECT_ERROR,
  },
  meta: {
    useRakIdToken: true,
    skipToast: true,
  },
});

export const connectProduct = () => ({
  type: API_REQUEST,
  payload: {
    url: API_ENDPOINTS.AUTH.CONNECT_PRODUCT,
    method: 'post',
  },
  dispatch: {
    START: AUTH_CONNECT_PRODUCT_START,
    SUCCESS: AUTH_CONNECT_PRODUCT_SUCCESS,
    ERROR: AUTH_CONNECT_PRODUCT_ERROR,
  },
  meta: {
    useRakIdToken: true,
  },
});

export const signUp = (data) => ({
  type: API_REQUEST,
  payload: {
    url: API_ENDPOINTS.AUTH.SIGN_UP,
    data,
    method: 'post',
  },
  dispatch: {
    START: AUTH_SIGN_UP_START,
    SUCCESS: AUTH_SIGN_UP_SUCCESS,
    ERROR: AUTH_SIGN_UP_ERROR,
  },
  meta: {
    skipAuthHeader: true,
  },
});

export const confirmEmail = (data) => ({
  type: API_REQUEST,
  payload: {
    url: API_ENDPOINTS.AUTH.CONFIRM_EMAIL,
    data,
    method: 'post',
  },
  dispatch: {
    START: AUTH_CONFIRM_EMAIL_START,
    SUCCESS: AUTH_CONFIRM_EMAIL_SUCCESS,
    ERROR: AUTH_CONFIRM_EMAIL_ERROR,
  },
  meta: {
    skipAuthHeader: true,
    skipToast: true,
  },
});

export const resentEmail = (data) => ({
  type: API_REQUEST,
  payload: {
    url: API_ENDPOINTS.AUTH.RESEND_CONFIRMATION,
    data,
    method: 'post',
  },
  dispatch: {
    START: AUTH_RESENT_EMAIL_START,
    SUCCESS: AUTH_RESENT_EMAIL_SUCCESS,
    ERROR: AUTH_RESENT_EMAIL_ERROR,
  },
  meta: {
    skipAuthHeader: true,
  },
});
