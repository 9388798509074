export const API_URL = process.env.REACT_APP_API_URL;

export const API_ENDPOINTS = {
  AUTH: {
    LOGIN: '/auth/login',
    CHECK_PRODUCT_CONNECT: '/auth/check-product-connect',
    CONNECT_PRODUCT: '/auth/connect-profile',
    SIGN_UP: '/auth/signup',
    CONFIRM_EMAIL: '/auth/confirm-email',
    RESEND_CONFIRMATION: '/auth/resend-confirmation',
    REFRESH_TOKEN: '/auth/refresh',
    FORGOT_PASSWORD: '/auth/forgot-password',
    CONFIRM_PASSWORD: '/auth/confirm-password',
  },
  API: {
    VERSION: '/version',
  },
  USER_PROFILE: {
    USER_INFO: '/users/me',
    DISCONNECT_ACCOUNT: 'users/me/deauthorize',
    DELETE_ACCOUNT: 'users/me/delete',
  },
  ORGANIZATIONS: {
    _: '/organizations',
    GET_BY_SUBDOMAIN: '/organizations/subdomain',
  },
  LOCATIONS: {
    _: '/locations',
  },
  HOTSPOTS: {
    _: '/gateways',
    LOCATION: '/gateways/location',
    RELOCATE: '/gateways/relocate',
  },
  // TODO: remove after check backward compatability
  GATEWAYS: {
    _: '/gateways',
    firmWareUpdate: '/gateways/firmware-update',
    unassign: '/unassign',
    ssh: '/ssh',
  },
  SUPPORT: {
    CONTACT: '/contact-support',
  },
};
