import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import styles from './styles';

import ROUTES from '../../contants/routes';
import CustomLink from '../../components/CustomLink';

const TokenStatusPage = ({ classes }) => {
  const { type } = useParams();

  return (
    <div className={classes.wrap}>
      <div className={classes.title}>
        <FormattedMessage id={type === 'expired' ? 'tokenExpiredTitle' : 'tokenInvalidTitle'} />
      </div>
      <div className={classes.text}>
        <FormattedMessage id={type === 'expired' ? 'tokenExpiredText' : 'tokenInvalidText'} />
      </div>
      <div>
        <CustomLink>
          <NavLink to={ROUTES.LOGIN.INDEX}>
            <FormattedMessage id="backToLogin" />
          </NavLink>
        </CustomLink>
      </div>
    </div>
  );
};

TokenStatusPage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

TokenStatusPage.defaultProps = {
  classes: {},
};

export default withStyles(styles)(TokenStatusPage);
