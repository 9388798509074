export default function styles(theme) {
  return {
    wrap: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: '141px 0 85px',
      '@media (max-width: 1023px)': {
        margin: '40px 0 85px 0',
      },
    },
    titleWrap: {
      marginBottom: '44px',
      width: '100%',
    },
    errorWrap: {
      marginTop: '40px',
    },
    title: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '29px',
      lineHeight: '36px',
      letterSpacing: '-0.01em',
      marginBottom: '10px',
    },
    subTitle: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.custom.textColor.gray,
      opacity: '0.8',
      marginRight: '5px',
    },
    confirm: {
      background: theme.custom.success,
      color: theme.custom.textColor.green,
      borderRadius: '5px',
      marginTop: '40px',
      padding: '16px 20px',
      display: 'flex',
      alignItems: 'center',
      opacity: '1',
    },
    error: {
      background: theme.custom.errorLight,
      color: theme.custom.error,
      borderRadius: '5px',
      padding: '16px 20px',
      marginTop: '40px',
    },
  };
}
