import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { IntlProvider } from 'react-intl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MUIThemeProvider from './MuiThemeProvider';
import store from './store';
import history from './utils/history';
import AppRoutes from './AppRoutes/root';
import messages from './locales';

import './App.css';

toast.configure();

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <IntlProvider locale="en" messages={messages.en}>
          <MUIThemeProvider>
            <AppRoutes />
          </MUIThemeProvider>
        </IntlProvider>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
