import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import useAvatar from './hooks';

function Avatar({
  classes, profilePhoto, size, fallback,
}) {
  const { avatar, setAvatar } = useAvatar(profilePhoto);

  if (!avatar) {
    return (fallback);
  }

  return (
    <img
      className={cn(classes.root, classes[size])}
      src={avatar}
      alt="profileAvatar"
      onError={() => setAvatar(null)}
    />
  );
}

Avatar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  profilePhoto: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'large']),
  fallback: PropTypes.node,
};

Avatar.defaultProps = {
  profilePhoto: '',
  size: 'medium',
  fallback: <div />,
};


export default Avatar;
