import PropTypes from 'prop-types';

const gatewayDetailsPropTypes = PropTypes.shape({
  LNSPort: PropTypes.string,
  LNSURI: PropTypes.string,
  accessKey: PropTypes.string,
  authenticationMode: PropTypes.string,
  certificate: PropTypes.string,
  eui: PropTypes.string.isRequired,
  freqBand: PropTypes.string.isRequired,
  isUpdateAvailable: PropTypes.bool.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  mac: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  reportedFw: PropTypes.string.isRequired,
  sn: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  uptimeInMinutes: PropTypes.string.isRequired,
});

export default gatewayDetailsPropTypes;
