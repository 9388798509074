export default function styles(theme) {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalWrap: {
      background: theme.custom.textColor.primary,
      outline: 'none',
      borderRadius: '10px',
      '@media (max-width:1023px)': {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,
      },
    },
    modalContent: {
      display: 'flex',
      height: '100%',
      padding: '0 40px',
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative',
      width: '600px',
      '@media (max-width:1023px)': {
        padding: '0 40px',
        boxSizing: 'border-box',
        width: '100%',
      },
    },
    modalForm: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    close: {
      cursor: 'pointer',
    },
    header: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '28px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '78px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      padding: '0 40px',
      '@media (max-width:1023px)': {
        height: '56px',
        padding: '0 20px',
      },
    },
    content: {
      marginTop: '56px',
      display: 'flex',
      justifyContent: 'space-between',
      '@media (max-width:1023px)': {
        width: 'auto',
        flexDirection: 'column',
        marginTop: '24px',
      },
    },
    footer: {
      background: theme.custom.backgroundColor,
      height: '112px',
      padding: '0 40px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '0 0 10px 10px',
      margin: '0 -40px',
      '@media (max-width:1023px)': {
        height: '85px',
      },
    },
    buttonBlock: {
      display: 'flex',
    },
    formContent: {
      width: '600px',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '30px',
      marginBottom: '48px',
      '@media (max-width:1023px)': {
        width: '100%',
        marginBottom: '24px',
      },
    },
    fieldWrap: {
      marginBottom: '24px',
    },
    tip: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '35px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.custom.textColor.blue,
    },
    errorWrap: {
      marginBottom: '30px',
    },
    icon: {
      marginRight: '20px',
    },
  };
}
