import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import styles from './styles';

const FormBlock = ({
  withoutBorder,
  withButtons,
  subLabel,
  children,
  buttons,
  classes,
  label,
}) => (
  <div className={classes.block}>
    <div className={classes.title}>
      {label && (
      <div>
        <FormattedMessage id={label} />
      </div>
      )}
      {subLabel && (
      <div className={classes.optional}>
        <FormattedMessage id={subLabel} />
      </div>
      )}
    </div>

    <div className={classes.contentWrap}>
      <div className={cn(classes.content, { [classes.withoutBorder]: withoutBorder })}>
        {children}
      </div>

      {withButtons && buttons}
    </div>
  </div>
);

FormBlock.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node.isRequired,
  withoutBorder: PropTypes.bool,
  withButtons: PropTypes.bool,
  buttons: PropTypes.node,
  label: PropTypes.string,
  subLabel: PropTypes.string,
};

FormBlock.defaultProps = {
  withoutBorder: false,
  withButtons: false,
  buttons: <div />,
  classes: {},
  label: '',
  subLabel: '',
};

export default withStyles(styles)(FormBlock);
