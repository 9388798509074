import { Map, fromJS, List } from 'immutable';

import * as actions from './actions';

export const initialState = Map({
  list: List([]),
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.GET_LOCATIONS_SUCCESS: {
      return state.set('list', fromJS(payload));
    }
    default:
      return state;
  }
};
