export default function styles() {
  return {
    root: {
      position: 'relative',
      opacity: ({ loading }) => (loading ? 0.4 : 1),
      pointerEvents: ({ loading }) => (loading ? 'none' : ''),
    },
    loaderLayout: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
  };
}
