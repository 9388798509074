import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import styles from './styles';
import dots from '../../assets/icons/dots.svg';

const Stepper = ({ classes, steps, activeStep }) => (
  <div className={classes.stepsWrap}>
    <div className={classes.steps}>
      {
        steps.map((stepName, index, array) => {
          const step = index + 1;
          const isActive = activeStep === step;

          return (
            <React.Fragment key={step}>
              <div className={cn(classes.step)}>
                <div className={cn(classes.numWrap, { [classes.inactiveWrap]: !isActive })}>
                  <div className={cn(classes.number, { [classes.inactiveNum]: !isActive })}>
                    {step}
                  </div>
                </div>
                <div className={cn(classes.text, { [classes.inactiveText]: !isActive })}>
                  <FormattedMessage id={stepName} />
                </div>
              </div>
              { step < array.length && (
                <div className={classes.separator}>
                  <img src={dots} alt="dots" width={28} />
                </div>
              )}
            </React.Fragment>
          );
        })
        }
    </div>
  </div>
);

Stepper.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  steps: PropTypes.arrayOf(PropTypes.string),
  activeStep: PropTypes.number,
};

Stepper.defaultProps = {
  classes: {},
  steps: [],
  activeStep: 1,
};

export default withStyles(styles)(Stepper);
