import axios from 'axios';

import get from 'lodash/get';
import {
  actionChannel,
  call,
  fork,
  put,
  take,
  takeEvery,
} from 'redux-saga/effects';

import {
  API_REQUEST,
  API_REQUEST_ERROR,
  apiRequestError,
} from '../reducers/api/actions';

import { API_URL } from '../../contants/api';
import notificationsTypes from '../../contants/notificationsTypes';

import { refreshToken } from '../reducers/auth/actions';
import { showNotification } from '../reducers/notifications/actions';
import { getCookie } from '../../utils/cookies';

function fetchResource(
  {
    url, data = {}, params = {}, method = 'get',
  },
  headers = {},
) {
  return axios({
    baseURL: API_URL,
    method,
    url,
    data,
    params,
    headers,
  });
}

function* getRequestHeaders({ headers, meta }) {
  const accessToken = yield call(getCookie, 'accessToken');
  const rakIdAccessToken = yield call(getCookie, 'rakIdAccessToken');
  const expTime = yield call(getCookie, 'tokenExpireTime');
  const skipAuthHeader = get(meta, ['skipAuthHeader']);
  const useRakIdToken = get(meta, ['useRakIdToken'], false);
  const curTime = `${new Date().getHours()}${new Date().getMinutes()}`;
  const minuteInterval = 10;

  const isExpireToken = expTime - minuteInterval < curTime;
  if (isExpireToken) {
    yield call(refreshToken);
  }

  const requestHeaders = {
    Authorization: `Bearer ${useRakIdToken ? rakIdAccessToken : accessToken}`,
    ...headers,
  };

  if (skipAuthHeader) {
    delete requestHeaders.Authorization;
  }

  return requestHeaders;
}

export function* sendApiRequest(action) {
  let apiRequestResolve = null;
  let apiRequestReject = null;

  // eslint-disable-next-line no-param-reassign
  action.promise = new Promise((resolve, reject) => {
    apiRequestResolve = resolve;
    apiRequestReject = reject;
  });

  // Added to prevent uncaught error
  action.promise.catch((e) => e);

  const {
    payload,
    dispatch: { START, SUCCESS, ERROR },
    meta,
    promise,
  } = action;

  yield put({
    type: START,
    payload,
    meta,
    promise,
  });

  try {
    const requestHeaders = yield getRequestHeaders(action);

    const fetchPromise = fetchResource(payload, requestHeaders);
    const { data, headers } = yield call(
      () => fetchPromise,
      payload,
      requestHeaders,
    );

    const actionData = {
      payload: get(data, ['data'], data),
      meta,
      headers,
    };

    yield put({
      type: SUCCESS,
      ...actionData,
    });

    apiRequestResolve({
      type: SUCCESS,
      ...actionData,
    });

    return {
      ok: true,
      result: actionData,
    };
  } catch (ex) {
    const data = get(ex, ['response', 'data']);
    const errorAction = {
      type: ERROR,
      payload: data,
      meta,
    };

    yield put(apiRequestError(ex, meta));
    yield put(errorAction);
    yield apiRequestReject(errorAction);

    return {
      ok: false,
      result: errorAction,
    };
  }
}

function* notifyApiRequestError(action) {
  const responseMessage = get(action, [
    'payload',
    'response',
    'data',
    'message',
  ]);

  const responseCode = get(action, [
    'payload',
    'response',
    'data',
    'statusCode',
  ]);

  const skipToast = get(action, ['meta', 'skipToast'], false);

  if (!skipToast && responseCode !== 400) {
    yield put(
      showNotification({
        type: notificationsTypes.error,
        message: responseMessage,
      }),
    );
  }
}

export function* watchApiRequests() {
  yield takeEvery(API_REQUEST_ERROR, notifyApiRequestError);

  const requestsChannel = yield actionChannel(API_REQUEST);

  while (true) {
    const action = yield take(requestsChannel);

    if (get(action, ['meta', 'blockOthers'])) {
      yield call(sendApiRequest, action);
    } else {
      yield fork(sendApiRequest, action);
    }
  }
}
