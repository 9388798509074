import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';

import authReducer from './auth/reducer';
import requestStatus from './requestStatus/reducer';
import userProfile from './userProfile/reducer';
import organizations from './organizations/reducer';
import organization from './organization/reducer';
import api from './api/reducer';
import gateways from './gateways/reducer';
import location from './location/reducer';
import locations from './locations/reducer';
import gateway from './gateway/reducer';
import hotspots from './hotspots/reducer';
import history from '../../utils/history';

export default combineReducers({
  form: formReducer,
  router: connectRouter(history),
  auth: authReducer,
  requestStatus,
  userProfile,
  organizations,
  organization,
  locations,
  location,
  gateways,
  gateway,
  hotspots,
  api,
});
