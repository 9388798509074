import { useCallback, useState } from 'react';

const useStepper = (startStep) => {
  const [activeStep, setStep] = useState(startStep);

  const nextStep = useCallback(() => {
    setStep((currStep) => currStep + 1);
  }, []);

  const prevStep = useCallback(() => {
    setStep((currStep) => currStep - 1);
  }, []);

  return {
    nextStep,
    prevStep,
    activeStep,
  };
};

export default useStepper;
