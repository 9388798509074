import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import clockIcon from '../../assets/icons/clock.svg';
import formatTime from '../../utils/timer';

const CountdownTimer = ({
  classes, timer, timerOn, interval,
}) => {
  const [time, setTime] = useState(() => timer);
  const deadline = useMemo(() => new Date().getTime() + timer, [timer]);

  useEffect(() => {
    let timerId;

    setTime(deadline - Date.now());

    if (timerOn) {
      timerId = setInterval(() => {
        setTime(deadline - Date.now());
      }, interval);
    }

    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [deadline, interval, timer, timerOn]);

  const renderTime = () => {
    if (timerOn) {
      if (time > 0) {
        return formatTime(time);
      }
      return 'Expired';
    }

    return formatTime(time);
  };

  return (
    <div className={classes.timer}>
      <img src={clockIcon} alt="clocks" />
      <span className={classes.time}>
        {renderTime()}
      </span>
    </div>
  );
};

CountdownTimer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  timerOn: PropTypes.bool,
  timer: PropTypes.number.isRequired,
  interval: PropTypes.number,
};

CountdownTimer.defaultProps = {
  classes: {},
  timerOn: false,
  interval: 1000,
};

export default withStyles(styles)(CountdownTimer);
