export default function styles(theme) {
  return {
    wrap: {
      width: '100%',
    },
    buttonBlock: {
      marginTop: '40px',
    },
    fieldWrap: {
      marginBottom: '24px',
    },
    button: {
      height: '60px',
      paddingLeft: '60px',
      paddingRight: '60px',
    },
    termsWrap: {
      display: 'flex',
      alignItems: 'center',
    },
    termsLabel: {
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.custom.textColor.gray,
      opacity: 0.8,
      marginRight: '4px',
      borderBottom: '1.5px dotted transparent',
    },
    checkBox: {
      padding: 0,
      marginRight: '12px',
    },
    icon: {
      borderRadius: 4,
      width: 22,
      height: 22,
      border: `1px solid ${theme.custom.inputBorderColor}`,
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.03)',
      'input:hover ~ &': {
        border: '1px solid rgba(0, 0, 0, 0.4)',
      },
    },
  };
}
