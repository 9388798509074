import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';

import View from './View';

import styles from './styles';

export default compose(
  withStyles(styles),
)(View);
