import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import AuthorizedRoute from './AuthorizedRoute';
import UnauthorizedRoute from './UnauthorizedRoute';
import Login from '../pages/Login';
import Registration from '../pages/Registration';
import ForgotPassword from '../pages/ForgotPassword';
import TokenStatusPage from '../pages/TokenStatusPage';
import ConfirmPassword from '../pages/ConfirmPassword';
import EmailCheck from '../pages/EmailCheck';
import Hotspots from '../pages/Hotspots';
import ProfilePage from '../pages/Profile';
import RootElement from '../RootElement';

import ROUTES from '../contants/routes';
import mailImg from '../assets/images/mail.svg';
import introImg from '../assets/images/intro.svg';

const AppRoutes = () => (
  <Switch>
    <UnauthorizedRoute
      exact
      path={ROUTES.LOGIN.INDEX}
      component={Login}
      image={introImg}
      mainText="loginMainText"
      subText="loginSubText"
    />

    <UnauthorizedRoute
      exact
      path={ROUTES.REGISTRATION.INDEX}
      component={Registration}
      image={introImg}
      mainText="loginMainText"
      subText="loginSubText"
    />

    <UnauthorizedRoute
      exact
      path={ROUTES.FORGOT_PASSWORD.INDEX}
      component={ForgotPassword}
      image={introImg}
      mainText="loginMainText"
      subText="loginSubText"
    />

    <UnauthorizedRoute
      exact
      path={ROUTES.CONFIRM_PASSWORD.INDEX}
      component={ConfirmPassword}
      image={mailImg}
    />

    <UnauthorizedRoute
      exact
      path={ROUTES.TOKEN_STATUS.INDEX}
      component={TokenStatusPage}
      image={introImg}
    />

    <UnauthorizedRoute
      exact
      path={ROUTES.EMAIL_VERIFICATION.INDEX}
      component={EmailCheck}
      image={mailImg}
    />

    <AuthorizedRoute
      exact
      path={ROUTES.PROFILE.INDEX}
      component={ProfilePage}
    />

    <AuthorizedRoute
      exact
      path={ROUTES.HOTSPOTS.INDEX}
      component={Hotspots}
    />

    <Redirect to={ROUTES.HOTSPOTS.INDEX} />
  </Switch>
);

export default RootElement(AppRoutes);
