import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';

function Section({
  classes,
  title,
  subtitle,
  actionElement,
  withDivider,
  children,
}) {
  return (
    <div className={classes.section}>
      <div className={classes.sectionHeader}>
        <div className={classes.mainTitleWrap}>
          <h2 className={classes.title}>{title}</h2>

          {actionElement || null}
        </div>
        {
          subtitle
            ? <span className={classes.subtitle}>{subtitle}</span>
            : null
        }
      </div>

      {
        withDivider && (
          <div className={classes.dividerWrap}>
            <Divider />
          </div>
        )
      }

      <div className={classes.content}>
        {children}
      </div>
    </div>
  );
}

Section.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.string,
  actionElement: PropTypes.node,
  children: PropTypes.node.isRequired,
  withDivider: PropTypes.bool,
};

Section.defaultProps = {
  subtitle: null,
  actionElement: null,
  withDivider: false,
};

export default Section;
