import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import AutoLogoutModal from '../../components/AutoLogoutModal';
import LoaderLayout from '../../components/Loader/LoaderLayout';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import useFetchProfileData from './hooks';

const AuthorizeLayout = ({ classes, children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { loading } = useFetchProfileData();

  if (loading) {
    return (
      <LoaderLayout />
    );
  }

  return (
    <>
      <div className={classes.authLayout}>
        <header className={classes.authHeader}>
          <Header />
        </header>

        <main className={classes.authMain}>
          {children}
        </main>

        <footer className={classes.authFooter}>
          <Footer />
        </footer>
      </div>

      <AutoLogoutModal />
    </>
  );
};

AuthorizeLayout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
};

AuthorizeLayout.defaultProps = {
  classes: {},
};

export default AuthorizeLayout;
