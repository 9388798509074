import { useDispatch } from 'react-redux';
import {
  useCallback, useMemo, useState,
} from 'react';

import { runSaga } from '../../../../../../store';
import { sendApiRequest } from '../../../../../../redux/sagas/apiRequests';
import { deleteGateways } from '../../../../../../redux/reducers/gateways/actions';
import { showNotification } from '../../../../../../redux/reducers/notifications/actions';

export default function useGatewayTable(selectedIds, reFetchGateways) {
  const dispatch = useDispatch();

  const [isOpen, handleOpen] = useState(false);

  const toggleModalState = useCallback(() => handleOpen(!isOpen), [isOpen]);

  const handleDeleteGateways = useCallback(async () => {
    const { ok } = await runSaga(
      sendApiRequest,
      deleteGateways({
        data: {
          serialNumbers: selectedIds,
        },
      }),
    ).toPromise();

    if (ok) {
      reFetchGateways(selectedIds);
      toggleModalState();
      dispatch(showNotification({
        type: 'info',
        message: 'Your hotspot(s) and miner(s) have been deleted from your profile.',
      }));
    }
  }, [dispatch, reFetchGateways, selectedIds, toggleModalState]);

  return useMemo(() => ({
    selectedIds,
    handleDeleteGateways,
    isOpen,
    toggleModalState,
    entities: [],
  }), [selectedIds, handleDeleteGateways, isOpen, toggleModalState]);
}
