import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import cn from 'classnames';
import { useHistory } from 'react-router';
import CustomButton from '../../../../../components/CustomButton';
import successCheckIcon from '../../../../../assets/icons/successCheck.svg';
import ROUTES from '../../../../../contants/routes';
import styles from './styles';

const FinishHotspotAdding = ({
  classes,
  isRelocation,
}) => {
  const history = useHistory();
  const goToHotspotsList = () => history.push(ROUTES.HOTSPOTS.DEFAULT_PATH);

  return (
    <div className={classes.content}>
      <div className={classes.formWrapper}>
        {
          !isRelocation && (<div className={classes.stepTitle}><FormattedMessage id="step" /> 4</div>)
        }
        <div className={classes.title}>
          <FormattedMessage id={isRelocation ? 'updatingLocation' : 'youreAllSet'} />
          <img src={successCheckIcon} className={classes.titleIcon} alt="success-icon" />
        </div>
        <div className={classes.description}>
          <FormattedMessage id={isRelocation ? 'updatingDescription' : 'finishDescription'} />
        </div>

        <div className={classes.buttonBlock}>
          <CustomButton
            type="button"
            variant="contained"
            color="secondary"
            onClick={goToHotspotsList}
          >
            <FormattedMessage id="goToHotspots" />
          </CustomButton>
        </div>

        <div className={cn(classes.description, classes.footerDescription)}>
          {
            isRelocation ? (
              <>
                <FormattedMessage id="updatingDisclaimer" />
              </>
            ) : (
              <>
                <FormattedMessage id="hotspotAwailabilityDescription" />
                <br />
                <br />
                <FormattedMessage id="shouldConfigurationFailed" />
              </>
            )
          }
        </div>
      </div>
    </div>
  );
};

FinishHotspotAdding.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  isRelocation: PropTypes.bool,
};

FinishHotspotAdding.defaultProps = {
  classes: {},
  isRelocation: false,
};

export default withStyles(styles)(FinishHotspotAdding);
