export default function styles(theme) {
  return {
    noticeWrap: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 20,

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    noticeIcon: {
      marginRight: 20,
    },
    noticeText: {
      fontSize: 14,
      color: theme.custom.textColor.gray,
      opacity: 0.8,
    },
  };
}
