import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  change, formValueSelector, getFormValues, reduxForm,
} from 'redux-form';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router';
import cn from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';
import ButtonBase from '@material-ui/core/ButtonBase';
import axios from 'axios';
import CustomButton from '../../../../../components/CustomButton';
import FindLocationMap from '../../../components/FindLocationMap';
import infoWarning from '../../../../../assets/icons/infoWarning.svg';
import useMobileScreenDetect from '../../../../../hooks/useMobileScreenDetect';
import styles from './styles';
import { addHotspotLocation, relocateHotspot } from '../../../../../redux/reducers/hotspots/actions';
import { runSaga } from '../../../../../store';
import { sendApiRequest } from '../../../../../redux/sagas/apiRequests';
import getRequestStatus from '../../../../../redux/reducers/requestStatus/selectors';
import FormInfoBlock from '../../../../../components/FormInfoBlock';
import useResetRequestStatus from '../../../../../hooks/useResetRequestStatus';
import { API_URL } from '../../../../../contants/api';
import CustomLink from '../../../../../components/CustomLink';

const { REACT_APP_MAPBOX_TOKEN } = process.env;

const FindLocationForm = ({
  form,
  isRelocation,
  handleSubmit,
  classes,
  valid,
  pristine,
  submitting,
  nextStep,
  touch,
}) => {
  const dispatch = useDispatch();

  // request status
  const requestStatus = useSelector((state) => getRequestStatus(state, isRelocation ? 'relocateHotspot' : 'addHotspotLocation'));
  useResetRequestStatus(isRelocation ? 'relocateHotspot' : 'addHotspotLocation');

  const geocoderContainerRef = useRef();

  const formValues = useSelector(getFormValues(form));

  // maps callbacks
  const [findLocationError, setFindLocationError] = useState(null);

  const handleFindLocationError = useCallback(({ error }) => {
    if (error.message) { setFindLocationError(error.message); } else { setFindLocationError('Something went wrong, try changing your location'); }
  }, []);

  const handleFindLocationResults = useCallback((results) => {
    if (results.features.length > 0) {
      setFindLocationError(null);
    } else {
      setFindLocationError('Hotspot location doesn’t exist. Please try to find correct location.');
    }
  }, []);

  const handleFindLocationResult = useCallback(async (result, mapZoom) => {
    const { data: { features } } = await axios({
      baseURL: API_URL,
      method: 'get',
      url: `https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${result.center[0]},${result.center[1]}.json`,
      params: {
        layers: 'contour',
        limit: '50',
        access_token: REACT_APP_MAPBOX_TOKEN,
      },
    });

    setFindLocationError(null);

    dispatch(change(form, 'location', {
      longitude: result.center[0],
      latitude: result.center[1],
      altitude: String(Math.max(...features.map(({ properties: { ele } }) => ele))),
    }));
    dispatch(change(form, 'locationName', result.place_name));
    dispatch(change(form, 'mapZoom', mapZoom));
    dispatch(touch('location', 'locationName', 'mapZoom'));
  }, [dispatch, form, touch]);

  const handleClearFindLocationResult = useCallback(() => {
    dispatch(change(form, 'location', null));
    dispatch(change(form, 'locationName', ''));
    dispatch(change(form, 'mapZoom', ''));
    dispatch(touch('location', 'locationName', 'mapZoom'));
  }, [dispatch, form, touch]);

  const onSubmit = useCallback(async (values, submitDispatch) => {
    const {
      location, locationName, mapZoom, sn,
    } = values;

    const { ok, result } = await runSaga(
      sendApiRequest,
      isRelocation
        ? relocateHotspot({
          data: {
            sn, location: { ...location, name: locationName, mapZoom },
          },
        })
        : addHotspotLocation({
          data: {
            sn, location: { ...location, name: locationName, mapZoom },
          },
        }),
    ).toPromise();

    if (ok) {
      const qrCode = get(result, 'payload.qrcode', null);

      if (isRelocation && qrCode) {
        submitDispatch(change(form, 'qrcode', qrCode));
      }

      nextStep();
    }
  }, [form, isRelocation, nextStep]);

  const { isMobile } = useMobileScreenDetect();
  const [isHideMap, toggleShowingMap] = useState(false);

  useEffect(() => {
    setTimeout(() => toggleShowingMap(true), 0);
  }, []);

  return (
    <div className={classes.content}>
      <div className={classes.formWrapper}>
        {
          !isRelocation && (<div className={classes.stepTitle}><FormattedMessage id="step" /> 2</div>)
        }
        <div className={classes.title}><FormattedMessage id="findBestLocation" /></div>
        <div className={classes.description}>
          <FormattedMessage
            id="selectAccurateLocation"
            values={{
              bold: (str) => <b>{str}</b>,
            }}
          />
        </div>

        {requestStatus?.error && (
          <div className={classes.errorWrap}>
            <FormInfoBlock error={requestStatus?.errorData} />
          </div>
        )}

        {findLocationError && (
          <div className={classes.errorWrap}>
            <FormInfoBlock error={{ message: findLocationError }} />
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.fieldWrap} ref={geocoderContainerRef} />
          <div className={classes.buttonBlock}>
            <CustomButton
              type="submit"
              variant="contained"
              color="secondary"
              loading={requestStatus?.loading || submitting}
              disabled={
                !valid || pristine || requestStatus?.loading || !formValues?.location || submitting
              }
            >
              <FormattedMessage id="nextStep" />
            </CustomButton>
          </div>
        </form>
        <div className={classes.hideButtonBlock}>
          <CustomLink>
            <ButtonBase
              onClick={() => toggleShowingMap(((prevStatus) => !prevStatus))}
              disableRipple
            >
              <FormattedMessage id={isHideMap ? 'showMap' : 'hideMap'} />
            </ButtonBase>
          </CustomLink>
          <div className={classes.hideTip}>
            <img className={classes.icon} src={infoWarning} alt="infoWarning" />
            <FormattedMessage id="adjustLocationPin" />
          </div>
        </div>
      </div>
      <div className={cn(classes.mapWrapper, {
        [classes.hideMapWrapper]: isHideMap,
      })}
      >
        <FindLocationMap
          initialZoom={formValues?.mapZoom}
          initialLongitude={formValues?.location?.longitude}
          initialLatitude={formValues?.location?.latitude}
          initialPlaceName={formValues?.locationName}
          inputContainerRef={geocoderContainerRef}
          mapProps={{
            // allow vertical page scroll when dragging over the map
            touchAction: isMobile ? 'pan-y' : 'none',
          }}
          onResults={handleFindLocationResults}
          onResult={handleFindLocationResult}
          onClear={handleClearFindLocationResult}
          onError={handleFindLocationError}
        />
      </div>
      <div className={classes.mobileButtonBlock}>
        <CustomButton
          type="button"
          variant="contained"
          color="secondary"
          loading={requestStatus?.loading || submitting}
          disabled={
            !valid || pristine || requestStatus?.loading || !formValues?.location || submitting
          }
          onClick={handleSubmit(onSubmit)}
        >
          <FormattedMessage id="nextStep" />
        </CustomButton>
      </div>
    </div>
  );
};

FindLocationForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  form: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  touch: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  isRelocation: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  hotspot: PropTypes.objectOf(PropTypes.any),
  valid: PropTypes.bool,
  nextStep: PropTypes.func.isRequired,
};

FindLocationForm.defaultProps = {
  handleSubmit: () => {},
  touch: () => {},
  pristine: false,
  valid: false,
  submitting: false,
  isRelocation: false,
  hotspot: null,
  history: {},
  classes: {},
};

const basicHotspotFormSelector = formValueSelector('addBasicHotspotDetails');
export default compose(
  withStyles(styles),
  withRouter,
  connect((state, props) => ({
    initialValues: {
      sn: props.isRelocation ? props.hotspot?.sn : basicHotspotFormSelector(state, 'sn'),
      qrcode: props.isRelocation ? null : basicHotspotFormSelector(state, 'qrcode'),
      location: props.isRelocation
        ? {
          latitude: props.hotspot?.latitude,
          longitude: props.hotspot?.longitude,
        } : null,
      locationName: props.isRelocation ? props.hotspot?.locationName ?? '' : '',
      mapZoom: props.isRelocation ? props.hotspot?.mapZoom : null,
    },
  }), null),
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: (values) => {
      const errors = {};
      if (!values.location) { errors.location = 'This field cannot be empty.'; }
      return errors;
    },
  }),
)(FindLocationForm);
