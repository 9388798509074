import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import styles from './styles';

function CustomLink({
  classes,
  children,
}) {
  return (
    <span className={classes.root}>
      <span className={classes.text}>
        {children}
      </span>
    </span>
  );
}

CustomLink.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(CustomLink);
