import emptyStateHotspots from '../assets/images/emptystateHotspots.svg';

const entities = {
  hotspots: {
    name: 'hotspots',
    emptyStateImage: emptyStateHotspots,
  },
};

export default entities;
