import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { runSaga } from '../../../../store';
import { sendApiRequest } from '../../../../redux/sagas/apiRequests';
import { disconnectAccount } from '../../../../redux/reducers/userProfile/actions';
import { logOut, setLogoutStatus } from '../../../../redux/reducers/auth/actions';
import { getGatewaysIdsList } from '../../../../redux/reducers/gateways/selectors';

export default function useDisconnectAccount() {
  const dispatch = useDispatch();

  const [isOpen, handleOpen] = useState(false);

  const gatewaysList = useSelector(getGatewaysIdsList);

  const toggleModalState = useCallback(() => handleOpen(!isOpen), [isOpen]);

  const onSubmit = useCallback(async () => {
    const { ok } = await runSaga(
      sendApiRequest,
      disconnectAccount(),
    ).toPromise();

    if (ok) {
      dispatch(logOut());
      dispatch(setLogoutStatus({ success: true }));
    }
  }, [dispatch]);

  return useMemo(() => ({
    isOpen,
    toggleModalState,
    onSubmit,
    hasGateways: Boolean(gatewaysList.length),
  }), [isOpen, toggleModalState, onSubmit, gatewaysList]);
}
