export default function styles(theme) {
  return {
    content: {
      display: 'flex',
      justifyContent: 'space-between',
      '@media (max-width: 1023px)': {
        flexDirection: 'column',
      },
    },
    formWrapper: {
      marginTop: '40px',
      marginRight: '100px',
      backgroundColor: '#ffffff',
      width: '410px',
      '@media (max-width: 1023px)': {
        width: '100%',
        marginRight: '0',
        marginTop: '10px',
      },
    },
    hideButtonBlock: {
      display: 'none',
      '@media (max-width: 1023px)': {
        display: 'flex',
        alignItems: 'center',
        margin: '40px 0',
      },
    },
    hideTip: {
      display: 'flex',
      alignItems: 'flex-start',
      marginLeft: '20px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      color: theme.custom.textColor.gray,
      opacity: 0.8,
    },
    icon: {
      marginRight: '10px',
    },
    mapWrapper: {
      flexGrow: 1,
      marginTop: '-35px',
      marginRight: '-64px',
      marginBottom: '-40px',
      height: '100%',
      background: theme.custom.textColor.primary,
      '@media (max-width: 1023px)': {
        flexGrow: 0,
        marginTop: '0',
        marginRight: '0',
        marginBottom: '40px',
        height: '400px',
      },
    },
    hideMapWrapper: {
      '@media (max-width: 1023px)': {
        display: 'none',
      },
    },
    stepTitle: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      color: theme.custom.textColor.gray,
      fontSize: '11px',
      lineHeight: '16px',
      letterSpacing: '2px',
      opacity: 0.5,
      marginBottom: '10px',
      textTransform: 'uppercase',
    },
    title: {
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '-0.01em',
      marginBottom: '20px',
      '@media (max-width: 1023px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    description: {
      color: theme.custom.textColor.gray,
      opacity: 0.8,
      fontSize: '14px',
      lineHeight: '20px',
      marginBottom: '40px',
    },
    fieldWrap: {
      marginBottom: '24px',
    },
    errorWrap: {
      marginBottom: '25px',
    },
    buttonBlock: {
      display: 'flex',
      margin: '40px 0',
      paddingRight: '2px',
      justifyContent: 'flex-end',
      '@media (max-width: 1023px)': {
        display: 'none',
      },
    },
    mobileButtonBlock: {
      display: 'flex',
      margin: '0',
      flexDirection: 'column-reverse',
      '@media (min-width: 1024px)': {
        display: 'none',
      },
    },
    buttonPrev: {
      color: theme.palette.primary.main,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '48px',
      marginLeft: '52px',
      '@media (max-width: 1023px)': {
        marginTop: '20px',
        marginLeft: '0',
      },
    },
    hideMap: {
      display: 'none',
      '@media (max-width: 1023px)': {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '30px 0',
      },
    },
  };
}
