import React from 'react';
import PropTypes from 'prop-types';
import FieldWithCopy from '../../../../../../components/FieldWithCopy';

function withCopyHoC(WrappedComponent) {
  return function ownProps(props) {
    ownProps.propTypes = {
      classes: PropTypes.objectOf(PropTypes.string).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      withCopy: PropTypes.bool,
    };

    ownProps.defaultProps = {
      withCopy: false,
    };

    const { withCopy, value, classes } = props;

    return withCopy
      ? (
        <FieldWithCopy valueForCopy={value}>
          <WrappedComponent {...props} />
        </FieldWithCopy>
      )
      : (
        <div className={classes.root}>
          <div className={classes.iconFallback} />

          <WrappedComponent {...props} />
        </div>
      );
  };
}

export default withCopyHoC;
