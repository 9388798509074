import {
  useCallback,
  useMemo,
  useState,
} from 'react';

function useTable(entitiesGenerator) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [itemCount, setItemCount] = useState(0);
  const [selectedIds, setSelectedId] = useState([]);

  const entities = entitiesGenerator(page, perPage);

  const handleChangePage = useCallback((newPage) => {
    setSelectedId([]);
    setPage(newPage);
  }, []);

  const handleChangePerPage = useCallback(({ target: { value } }) => {
    setSelectedId([]);
    setPage(1);
    setPerPage(value);
  }, []);

  const handleClearSelectedIds = useCallback(() => {
    setSelectedId([]);
  }, []);

  const handleSelectId = useCallback((selectId) => {
    if (selectedIds.includes(selectId)) {
      setSelectedId(selectedIds.filter((currentId) => currentId !== selectId));
    } else {
      setSelectedId([...selectedIds, selectId]);
    }
  }, [selectedIds]);

  const handleSelectAllIds = useCallback(() => {
    if (selectedIds.length === entities.length) {
      setSelectedId([]);
    } else {
      setSelectedId(entities.map(({ id }) => id));
    }
  }, [entities, selectedIds.length]);

  return useMemo(() => ({
    entities,
    page,
    perPage,
    selectedIds,
    itemCount,
    setItemCount,
    handleChangePage,
    handleChangePerPage,
    handleSelectId,
    handleSelectAllIds,
    handleClearSelectedIds,
  }), [
    entities,
    page,
    perPage,
    selectedIds,
    itemCount,
    setItemCount,
    handleChangePage,
    handleChangePerPage,
    handleSelectId,
    handleSelectAllIds,
    handleClearSelectedIds,
  ]);
}

export default useTable;
