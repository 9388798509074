import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Field, change, reduxForm,
} from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import ButtonBase from '@material-ui/core/ButtonBase';
import TextFormField from '../../../../../components/FormElements/TextField';
import CustomButton from '../../../../../components/CustomButton';
import {
  isRequired,
  isValidGatewayName,
  isValidSerialNumber,
} from '../../../../../utils/validations';
import styles from './styles';
import { addBasicHotspotDetails, updateHotspotCreationTimer } from '../../../../../redux/reducers/hotspots/actions';
import { runSaga } from '../../../../../store';
import { sendApiRequest } from '../../../../../redux/sagas/apiRequests';
import getRequestStatus from '../../../../../redux/reducers/requestStatus/selectors';
import FormInfoBlock from '../../../../../components/FormInfoBlock';
import useResetRequestStatus from '../../../../../hooks/useResetRequestStatus';
import HotspotHelpModal from '../../../../../components/HotspotHelpModal';
import CustomLink from '../../../../../components/CustomLink';

const BasicHotspotDetailsForm = ({
  handleSubmit,
  classes,
  valid,
  pristine,
  submitting,
  nextStep,
}) => {
  const requestStatus = useSelector((state) => getRequestStatus(state, 'addBasicHotspotDetails'));
  useResetRequestStatus('addBasicHotspotDetails');

  const [isOpenHotspotHelpModal, handleHotspotHelpModalState] = useState(false);

  const onSubmit = useCallback(async (values, dispatch) => {
    const { eui, name, sn } = values;

    const {
      ok, result,
    } = await runSaga(
      sendApiRequest,
      addBasicHotspotDetails({ data: { eui, sn, name: name || null } }),
    ).toPromise();

    if (ok) {
      dispatch(updateHotspotCreationTimer({ isStartCreation: true }));
      dispatch(change('addBasicHotspotDetails', 'qrcode', { ...result.payload.qrcode }));
      nextStep();
    }
  }, [nextStep]);

  return (
    <div className={classes.content}>
      <div className={classes.formWrapper}>
        <div className={classes.stepTitle}><FormattedMessage id="step" /> 1</div>
        <div className={classes.title}><FormattedMessage id="basicHotspotDetails" /></div>
        <div className={classes.hintWrap}>
          <FormattedMessage id="cantFindHotspotDetails" />
          <CustomLink>
            <ButtonBase
              onClick={() => handleHotspotHelpModalState(!isOpenHotspotHelpModal)}
              disableRipple
            >
              <FormattedMessage id="clickHere" />
            </ButtonBase>
          </CustomLink>
        </div>

        {requestStatus?.error && (
          <div className={classes.errorWrap}>
            <FormInfoBlock error={requestStatus?.errorData} />
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.fieldWrap}>
            <Field
              name="eui"
              component={TextFormField}
              validate={[isRequired, isValidSerialNumber]}
              label={<FormattedMessage id="hotspotEui" />}
            />
          </div>
          <div className={classes.fieldWrap}>
            <Field
              name="sn"
              component={TextFormField}
              validate={[isRequired, isValidSerialNumber]}
              label={<FormattedMessage id="serialNumber" />}
            />
          </div>
          <div className={classes.fieldWrap}>
            <Field
              name="name"
              component={TextFormField}
              validate={[isValidGatewayName]}
              label={<FormattedMessage id="hotspotNameOptional" />}
            />
          </div>
          <div className={classes.buttonBlock}>
            <CustomButton
              type="submit"
              variant="contained"
              color="secondary"
              loading={requestStatus?.loading || submitting}
              disabled={!valid || pristine || requestStatus?.loading || submitting}
            >
              <FormattedMessage id="nextStep" />
            </CustomButton>
          </div>
        </form>
      </div>

      <HotspotHelpModal
        isOpen={isOpenHotspotHelpModal}
        handleModalState={() => handleHotspotHelpModalState(!isOpenHotspotHelpModal)}
      />
    </div>
  );
};

BasicHotspotDetailsForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
  nextStep: PropTypes.func.isRequired,
};

BasicHotspotDetailsForm.defaultProps = {
  handleSubmit: () => {},
  pristine: false,
  submitting: false,
  valid: false,
  history: {},
  classes: {},
};

export default compose(
  withStyles(styles),
  withRouter,
  reduxForm({
    initialValues: {
      eui: '',
      sn: '',
      name: null,
      qrcode: null,
    },
    form: 'addBasicHotspotDetails',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
)(BasicHotspotDetailsForm);
