export default function styles(theme) {
  return {
    content: {
      display: 'flex',
      justifyContent: 'center',
    },
    formWrapper: {
      marginTop: '40px',
      backgroundColor: '#ffffff',
      width: '410px',
      '@media (max-width: 1023px)': {
        width: '100%',
        marginTop: '10px',
      },
    },
    stepTitle: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      color: theme.custom.textColor.gray,
      fontSize: '11px',
      lineHeight: '16px',
      letterSpacing: '2px',
      opacity: 0.5,
      marginBottom: '10px',
      textTransform: 'uppercase',
    },
    title: {
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '-0.01em',
      marginBottom: '20px',
      '@media (max-width: 1023px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    description: {
      color: theme.custom.textColor.gray,
      opacity: 0.8,
      fontSize: '14px',
      lineHeight: '20px',
      marginBottom: '30px',
    },
    tabsWrapper: {
      width: '100%',
      margin: '20px 0 30px 0',
    },
    tab: {
      backgroundColor: theme.custom.backgroundColor,
    },
    tabContentWrapper: {
      padding: '10px 0 20px 25px',
    },
    tabHeader: {
      display: 'flex',
      justifyContent: 'center',
      color: theme.custom.textColor.gray,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '11px',
      lineHeight: '16px',
      padding: '15px 25px 15px 0',
      borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
    },
    tabContent: {
      padding: '35px 25px 20px 0',
    },
    qrCode: {
      display: 'flex',
      justifyContent: 'center',
    },
    fieldWithCopy: {
      marginBottom: '20px',
    },
    text: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
    },
    value: {
      color: theme.custom.textColor.gray,
      opacity: 0.8,
      fontWeight: 'normal',
      wordBreak: 'break-all',
    },
    label: {
      fontSize: '10px',
      color: theme.custom.textColor.gray,
      opacity: 0.5,
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    modalContent: {
      width: '600px',
      '@media (max-width:1023px)': {
        boxSizing: 'border-box',
        width: '100%',
      },
    },
    tip: {
      display: 'flex',
      alignItems: 'flex-start',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.custom.textColor.gray,
      opacity: 0.8,
    },
    icon: {
      marginRight: '20px',
    },
    buttonBlock: {
      display: 'flex',
      margin: '40px 0',
      paddingRight: '2px',
      justifyContent: 'space-between',
      '@media (max-width: 1023px)': {
        flexDirection: 'column-reverse',
      },
    },
    buttonPrev: {
      color: theme.palette.primary.main,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '48px',
      marginLeft: '52px',
      '@media (max-width: 1023px)': {
        marginTop: '20px',
        marginLeft: '0',
      },
    },
  };
}
