import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonBase from '@material-ui/core/ButtonBase';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';

import styles from './styles';
import useTooltip from '../../../hooks/useTooltip';

const FieldTooltip = ({
  contentClass,
  tooltipClass,
  arrowClass,
  tooltipId,
  children,
  classes,
}) => {
  const iconRef = useRef(null);
  const { isOpen, setIsOpen } = useTooltip(iconRef);

  const handleOpenTooltip = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const tooltipContent = (
    <div
      className={cn(classes.tooltipContent, contentClass)}
    >
      <FormattedMessage id={tooltipId} />
    </div>
  );

  return (
    <Tooltip
      classes={{
        tooltip: cn(classes.tooltip, tooltipClass),
        arrow: cn(classes.arrow, arrowClass),
      }}
      title={tooltipContent}
      {...(isMobile ? { open: isOpen } : {})}
      placement="top"
      arrow
    >
      <ButtonBase
        ref={iconRef}
        disableRipple
        onClick={handleOpenTooltip}
      >
        {children}
      </ButtonBase>
    </Tooltip>
  );
};

FieldTooltip.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  tooltipId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  contentClass: PropTypes.string,
  tooltipClass: PropTypes.string,
  arrowClass: PropTypes.string,
};

FieldTooltip.defaultProps = {
  classes: {},
  tooltipClass: '',
  contentClass: '',
  arrowClass: '',
};

export default withStyles(styles)(FieldTooltip);
