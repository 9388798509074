import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import cn from 'classnames';

import Avatar from '../Avatar';
import styles from './styles';

const PreviewBlock = ({
  classes,
  name,
  profilePhoto,
  nameClass,
  wrapClass,
  contentClass,
  withoutAvatar,
}) => {
  if (withoutAvatar) {
    return (
      <div className={cn(classes.wrap, wrapClass)}>
        <div className={cn(classes.content, contentClass)}>
          <div className={cn(classes.name, nameClass)}>
            {name.charAt(0)}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(classes.wrap, wrapClass)}>
      <div className={cn(classes.content, contentClass)}>
        <Avatar
          profilePhoto={profilePhoto}
          fallback={(
            <div className={cn(classes.wrap, wrapClass)}>
              <div className={cn(classes.content, contentClass)}>
                <div className={cn(classes.name, nameClass)}>
                  {name.charAt(0)}
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

PreviewBlock.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  name: PropTypes.string,
  profilePhoto: PropTypes.string,
  nameClass: PropTypes.string,
  wrapClass: PropTypes.string,
  contentClass: PropTypes.string,
  withoutAvatar: PropTypes.bool,
};

PreviewBlock.defaultProps = {
  classes: {},
  name: '',
  profilePhoto: '',
  wrapClass: '',
  contentClass: '',
  nameClass: '',
  withoutAvatar: false,
};

export default withStyles(styles)(PreviewBlock);
