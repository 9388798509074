export default function styles(theme) {
  return {
    root: {
      height: '48px',
      width: '196px',
      display: 'flex',
      '@media (max-width: 1023px)': {
        width: 'fit-content',
        margin: '0',
      },
    },
    toggle: {
      width: '100%',
      border: `1px solid ${theme.custom.whiteLight}`,
      borderRadius: '44px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 16px 0 8px',
      height: '48px',
      cursor: 'pointer',
      outline: 'none',
      '&:hover': {
        backgroundColor: theme.custom.whiteLight,
        transition: 'background-color 0.4s ease-in-out',
      },
      '@media (max-width: 1023px)': {
        width: 'unset',
        border: 'none',
        padding: '0 8px',
      },
    },
    name: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '13px',
      lineHeight: '20px',
      color: theme.custom.textColor.primary,
      margin: '0 20px 0 8px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: '98px',
    },
    popper: {
      zIndex: 1,
      top: '5px!important',

      [theme.breakpoints.down('sm')]: {
        top: '0!important',
        left: -25,
      },
    },
    togglePreview: {
      border: 'none',
      height: '32px',
      width: '32px',
    },
    menuWrap: {
      borderRadius: '8px',
      boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.1)',
      background: theme.custom.textColor.primary,
      padding: 0,
    },
    menuItem: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette.primary.main,
      borderTop: '1px solid rgba(0, 0, 0, 0.1)',
      padding: 16,
    },
    userInfo: {
      minHeight: '136px',
      width: '216px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    userName: {
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
      opacity: 0.8,
      wordBreak: 'break-word',
      color: theme.custom.textColor.black,
      maxWidth: '100px',
      textAlign: 'center',
    },
    userRole: {
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      color: theme.custom.textColor.gray,
      opacity: 0.7,
    },
    previewWrap: {
      border: `1px solid ${theme.palette.primary.light}`,
    },
    previewContent: {
      background: theme.palette.primary.main,
    },
    previewName: {
      color: theme.custom.textColor.primary,
    },
  };
}
