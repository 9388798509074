import React from 'react';
import { FormattedMessage } from 'react-intl';

import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as ConnectedIcon } from '../../../../../../assets/icons/onlineGateway.svg';
import { ReactComponent as DisconnectedIcon } from '../../../../../../assets/icons/offlineGateway.svg';
import { ReactComponent as PendingIcon } from '../../../../../../assets/icons/pendingGateway.svg';
import GatewayCustomTableCell from '../../components/GatewayCustomTableCell';
import GatewayTooltipIndicator from '../../components/GatewayTooltipIndicator';
import GatewayActionMenu from '../../components/GatewayActionMenu';

export const gatewayStatuses = {
  Connected: (
    <GatewayTooltipIndicator
      title={<FormattedMessage id="gatewayStatusConnected" />}
      status="Connected"
      placement="right"
    >
      <ConnectedIcon />
    </GatewayTooltipIndicator>
  ),
  Disconnected: (
    <GatewayTooltipIndicator
      title={<FormattedMessage id="gatewayStatusDisconnected" />}
      status="Disconnected"
      placement="right"
    >
      <DisconnectedIcon />
    </GatewayTooltipIndicator>
  ),
  Pending: (
    <GatewayTooltipIndicator
      title={<FormattedMessage id="gatewayStatusPending" />}
      status="Pending"
      placement="right"
    >
      <PendingIcon />
    </GatewayTooltipIndicator>
  ),
};

const gatewaysTableConfig = {
  header: [
    <FormattedMessage key="gatewayTableHeaderStatus" id="gatewayTableHeaderStatus" />,
    <FormattedMessage key="gatewayTableHeaderName" id="gatewayTableHeaderName" />,
    <FormattedMessage key="gatewayTableHeaderSN" id="gatewayTableHeaderSN" />,
    <FormattedMessage key="gatewayTableHeaderEUI" id="gatewayTableHeaderEUI" />,
  ],
  body: (reFetchGateways) => ([
    {
      id: 'status',
      mutation: (status) => (
        <div style={{ display: 'flex' }}>
          {gatewayStatuses[status]}
        </div>
      ),
    },
    {
      id: 'name',
      mutation: (name) => (
        <Tooltip placement="right" title={name}>
          <span
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              width: 100,
              whiteSpace: 'nowrap',
              display: 'block',
            }}
          >
            {name}
          </span>
        </Tooltip>
      ),
    },
    {
      id: 'sn',
      mutation: (sn) => (
        <GatewayCustomTableCell>{sn}</GatewayCustomTableCell>
      ),
    },
    {
      id: 'eui',
      mutation: (eui) => (
        <GatewayCustomTableCell>{eui}</GatewayCustomTableCell>
      ),
    },
    {
      id: 'id',
      mutation: (id, { status }) => (
        <GatewayActionMenu
          gatewayId={id}
          gatewayStatus={status}
          reFetchGateways={reFetchGateways}
        />
      ),
    },
  ]),
};

export default gatewaysTableConfig;
