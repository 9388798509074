import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';

import { useSelector } from 'react-redux';
import ConfirmPasswordForm from './containers/ConfirmPasswordForm';
import styles from './styles';
import FormInfoBlock from '../../components/FormInfoBlock';
import getRequestStatus from '../../redux/reducers/requestStatus/selectors';

const ConfirmPassword = ({ classes }) => {
  const confirmPasswordStatus = useSelector((state) => getRequestStatus(state, 'authConfirmPassword'));

  return (
    <div className={classes.wrap}>
      <div className={classes.titleWrap}>
        <div className={classes.title}>
          <FormattedMessage id="setNewPassword" />
        </div>
        {confirmPasswordStatus.error && (
        <div className={classes.errorWrap}>
          <FormInfoBlock error={confirmPasswordStatus?.errorData} />
        </div>
        )}
      </div>
      <ConfirmPasswordForm loading={confirmPasswordStatus?.loading} />
    </div>
  );
};

ConfirmPassword.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

ConfirmPassword.defaultProps = {
  classes: {},
  location: {},
};

export default withStyles(styles)(ConfirmPassword);
