export default function styles(theme) {
  return {
    wrap: {
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      height: '100%',
      '@media (max-width: 1023px)': {
        display: 'none',
      },
      '@media (min-width: 1920px)': {
        width: '65%',
      },
      position: 'fixed',
      right: '0',
      width: 'calc(100% - 622px)',
    },
    dotsWrap: {
      top: '-52px',
      right: '-122px',
      position: 'absolute',
      '@media (max-width: 1215px)': {
        right: '-65px',
      },
    },
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: 1,
    },
    imageWrap: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    image: {
      opacity: 0.99,
    },
    mainText: {
      width: '365px',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '28px',
      color: theme.custom.textColor.primary,
      margin: '20px 0',
    },
    subText: {
      width: '365px',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.custom.textColor.primary,
      opacity: 0.6,
    },
  };
}
