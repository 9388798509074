import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as UpdateIcon } from '../../../../../../assets/icons/firwareUpdate.svg';

function GatewayInfoField({
  classes,
  label,
  value,
  withUpdate = false,
  withCollapse = false,
  isUpperCase = false,
}) {
  const [isCollapsed, setCollapse] = useState(true);

  return (
    <div className={classes.infoField}>
      <div
        className={
          cn(
            classes.value,
            {
              [classes.collapse]: (withCollapse && isCollapsed),
              [classes.upperCase]: isUpperCase,
            },
          )
        }
      >
        {value || 'N/A'}

        {
          withUpdate && (
            <a
              className={classes.updateLink}
              target="_blank"
              rel="noopener noreferrer"
              href={process.env.REACT_APP_FIRMWARE_VERSIONS}
            >
              <UpdateIcon />
            </a>
          )
        }
      </div>

      <div className={classes.label}>
        <FormattedMessage id={label} />
      </div>

      {
        withCollapse && value ? (
          <ButtonBase
            className={classes.collapseButton}
            onClick={(e) => {
              e.stopPropagation();
              setCollapse(!isCollapsed);
            }}
          >
            <FormattedMessage
              id="gatewayCertificate"
              values={{ prefix: isCollapsed ? 'Expand full' : 'Collapse' }}
            />
          </ButtonBase>
        ) : null
      }
    </div>
  );
}

GatewayInfoField.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  withCollapse: PropTypes.bool,
  withUpdate: PropTypes.bool,
  isUpperCase: PropTypes.bool,
};

GatewayInfoField.defaultProps = {
  withCollapse: false,
  withUpdate: false,
  isUpperCase: false,
};

export default GatewayInfoField;
