export function parseTimeZoneJson(str) {
  let timeZoneParse;

  try {
    timeZoneParse = JSON.parse(str);
  } catch (e) {
    timeZoneParse = { offset: '+08:00', timeZone: 'Beijing, Chongqing, Hong Kong, Urumqi' };
  }

  return timeZoneParse;
}

export function convertTimeZone({ offset = '', timeZone = '' }) {
  if (offset === '+00:00') {
    return `(UTC) ${timeZone}`;
  }

  return `(UTC ${offset}) ${timeZone}`;
}
