import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useHistory } from 'react-router';

import { ReactComponent as Logo } from '../../assets/icons/WISHM_logo.svg';

import UserMenu from '../UserMenu';
import NavigationTabs from './NavigationTabs';
import ROUTES from '../../contants/routes';

function Header({ classes }) {
  const { push } = useHistory();

  return (
    <div className={classes.appHeader}>
      <div className={classes.logoTabs}>
        <div className={classes.logo}>
          <ButtonBase onClick={() => push(ROUTES.HOTSPOTS.DEFAULT_PATH)}>
            <Logo />
          </ButtonBase>
        </div>

        <NavigationTabs />
      </div>

      <UserMenu />
    </div>
  );
}

Header.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Header;
