import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';

import { runSaga } from '../../../../../../store';
import { sendApiRequest } from '../../../../../../redux/sagas/apiRequests';
import { getGatewayBySN } from '../../../../../../redux/reducers/gateways/actions';
import { getGatewayDetails } from '../../../../../../redux/reducers/gateways/selectors';

function useFetchGateway(sn, toggleModalState, isOpen) {
  const [loading, setLoading] = useState(true);
  const gatewayDetails = useSelector(getGatewayDetails);

  const fetchGateway = useCallback(async () => {
    const fetchTask = runSaga(
      sendApiRequest,
      getGatewayBySN({ sn }),
    );

    setLoading(true);

    const { ok } = await fetchTask.toPromise();

    if (!ok) {
      toggleModalState();
    }

    if (fetchTask.isCancelled()) {
      return;
    }

    setLoading(false);
  }, [sn, toggleModalState]);

  useEffect(() => {
    if (isOpen) {
      fetchGateway();
    }
  }, [fetchGateway, isOpen]);

  return useMemo(() => ({
    loading,
    gatewayDetails,
    fetchGateway,
  }), [gatewayDetails, loading, fetchGateway]);
}

export default useFetchGateway;
