import React, { Component } from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import MapGL, { Marker, NavigationControl } from 'react-map-gl';
import Geocoder from 'react-map-gl-geocoder';
import './geocoder.css';
import MarkerIcon from '../../../../components/Marker';
import styles from './styles';
import mapStyles from './constants';

const { REACT_APP_MAPBOX_TOKEN } = process.env;

class FindLocationMap extends Component {
  constructor(props) {
    super(props);

    this.hasInitCoordinates = !!(
      props.initialLatitude && props.initialLongitude && props.initialPlaceName
    );

    this.state = {
      viewport: {
        latitude: props.initialLatitude || 37.7577,
        longitude: props.initialLongitude || -122.4376,
        zoom: props.initialZoom || 10,
      },
      marker: this.hasInitCoordinates ? {
        latitude: props.initialLatitude,
        longitude: props.initialLongitude,
        setFromGeocoderResults: true,
      } : null,
      geocoderAddress: props.initialPlaceName || '',
      geocoderWasInit: false,
    };

    this.mapRef = React.createRef();
  }


  // viewport
  handleViewportChange = (newViewport) => {
    this.setState({ viewport: newViewport });
  }

  handleGeocoderViewportChange = (newViewport) => {
    this.handleViewportChange({
      ...newViewport,
      transitionDuration: 3000,
    });
  }

  // marker
  onMarkerDragEnd = (event) => {
    this.setState({
      marker: {
        longitude: event.lngLat[0],
        latitude: event.lngLat[1],
        setFromGeocoderResults: false,
      },
    });
  }

  // geocoder
  getSuggestions = () => {
    const { inputContainerRef } = this.props;

    if (inputContainerRef && inputContainerRef.current) {
      // eslint-disable-next-line react/no-find-dom-node
      const suggestions = ReactDom.findDOMNode(inputContainerRef.current);
      if (suggestions) return suggestions.querySelector('.suggestions');
    }

    return null;
  }

  hideSuggestions = () => {
    const suggestions = this.getSuggestions();
    if (suggestions) {
      suggestions.classList.add('none');
    }
  }

  handleOnGeocoderInit = () => {
    const { geocoderWasInit } = this.state;

    // hide unnecessary suggestions after take initial coordinates
    if (this.hasInitCoordinates && !geocoderWasInit) {
      this.hideSuggestions();
      this.setState({
        geocoderWasInit: true,
      });
    }
  }

  handleOnGeocoderResults = (results) => {
    const { onResults } = this.props;
    if (onResults) onResults(results);
  }

  handleOnGeocoderResult = ({ result }) => {
    const { onResult } = this.props;
    const { viewport } = this.state;
    const { center, place_name: geocoderPlaceName } = result;

    this.setState({
      marker: {
        longitude: center[0],
        latitude: center[1],
        setFromGeocoderResults: true,
      },
      geocoderAddress: geocoderPlaceName,
    });

    // hide the unnecessary display of the list of offers - after selecting an option
    this.hideSuggestions();

    if (onResult) onResult(result, viewport.zoom);
  }

  handleOnGeocoderClear = () => {
    const { onClear } = this.props;

    this.setState({
      marker: null,
      geocoderAddress: '',
    });

    if (onClear) onClear();
  }

  // map
  handleOnClickMap = (event) => {
    this.setState({
      marker: {
        longitude: event.lngLat[0],
        latitude: event.lngLat[1],
        setFromGeocoder: false,
      },
    });
  }

  render() {
    const { classes, inputContainerRef, mapProps } = this.props;
    const { viewport, marker, geocoderAddress } = this.state;

    const geocoderInputValue = marker && !marker.setFromGeocoderResults
      ? `${marker.latitude}, ${marker.longitude}`
      : geocoderAddress;

    return (
      <div className={classes.wrap}>
        <MapGL
          {...viewport}
          {...mapProps}
          ref={this.mapRef}
          mapboxApiAccessToken={REACT_APP_MAPBOX_TOKEN}
          width="100%"
          height="100%"
          mapStyle={mapStyles}
          onViewportChange={this.handleViewportChange}
          onClick={this.handleOnClickMap}
        >

          {
            marker && (
              <Marker
                offsetTop={-40}
                offsetLeft={-10}
                longitude={marker.longitude}
                latitude={marker.latitude}
                draggable
                onDragEnd={this.onMarkerDragEnd}
              >
                <MarkerIcon />
              </Marker>
            )
          }

          <div className={classes.navigations}>
            <NavigationControl onViewportChange={this.handleViewportChange} />
          </div>
        </MapGL>

        <Geocoder
          mapRef={this.mapRef}
          containerRef={inputContainerRef}
          mapboxApiAccessToken={REACT_APP_MAPBOX_TOKEN}
          reverseGeocode
          inputValue={geocoderInputValue}
          marker={false}
          enableEventLogging={false}
          clearAndBlurOnEsc
          placeholder="Search..."
          onViewportChange={this.handleGeocoderViewportChange}
          onInit={this.handleOnGeocoderInit}
          onResults={this.handleOnGeocoderResults}
          onResult={this.handleOnGeocoderResult}
          onClear={this.handleOnGeocoderClear}
          onError={this.onError}
        />
      </div>
    );
  }
}

FindLocationMap.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  inputContainerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  initialZoom: PropTypes.number,
  initialLatitude: PropTypes.number,
  initialLongitude: PropTypes.number,
  initialPlaceName: PropTypes.string,
  onResults: PropTypes.func,
  onResult: PropTypes.func,
  onError: PropTypes.func,
  onClear: PropTypes.func,
  mapProps: PropTypes.objectOf(PropTypes.any),
};

FindLocationMap.defaultProps = {
  classes: {},
  initialZoom: null,
  initialLatitude: null,
  initialLongitude: null,
  initialPlaceName: '',
  mapProps: {},
  inputContainerRef: () => {},
  onResults: () => {},
  onResult: () => {},
  onError: () => {},
  onClear: () => {},
};

export default withStyles(styles)(FindLocationMap);
