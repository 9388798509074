import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Pagination from '@material-ui/lab/Pagination';
import cn from 'classnames';

import { ReactComponent as Icon } from '../../../assets/icons/dropdownArrow.svg';
import styles from './styles';

function TableFooterCustom({
  classes,
  paginationConfig,
}) {
  const pagesCount = Math.ceil(paginationConfig.itemCount / paginationConfig.perPage);

  return (
    <div className={classes.root}>
      {
        pagesCount <= 1
          ? (<div />)
          : (
            <div className={classes.paginationWrap}>
              <ButtonBase
                className={
                  cn(
                    classes.paginationControlButton,
                    { [classes.disabled]: paginationConfig.page <= 1 },
                  )
                }
                disabled={paginationConfig.page <= 1}
                onClick={() => paginationConfig.handleChangePage(paginationConfig.page - 1)}
              >
                {'<'} Prev
              </ButtonBase>
              <Pagination
                count={pagesCount}
                page={paginationConfig.page}
                onChange={(e, newPage) => paginationConfig.handleChangePage(newPage)}
                color="primary"
                shape="rounded"
                hidePrevButton
                hideNextButton
              />
              <ButtonBase
                className={
                  cn(
                    classes.paginationControlButton,
                    { [classes.disabled]: paginationConfig.page >= pagesCount },
                  )
                }
                disabled={paginationConfig.page >= pagesCount}
                onClick={() => paginationConfig.handleChangePage(paginationConfig.page + 1)}
              >
                Next {'>'}
              </ButtonBase>
            </div>
          )
      }

      <div className={classes.rowsPerPageWrap}>
        <div className={classes.rowsPerPageText}>Items per page</div>

        <div>
          <Select
            classes={{
              outlined: classes.outlined,
              iconOutlined: classes.iconOutlined,
              icon: classes.icon,
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={paginationConfig.perPage}
            onChange={paginationConfig.handleChangePerPage}
            variant="outlined"
            IconComponent={Icon}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </div>
      </div>
    </div>
  );
}

TableFooterCustom.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  paginationConfig: PropTypes.shape({
    itemCount: PropTypes.number,
    page: PropTypes.number,
    perPage: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleChangePerPage: PropTypes.func,
  }).isRequired,
};

export default withStyles(styles)(TableFooterCustom);
