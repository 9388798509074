export default function styles(theme) {
  return {
    card: {
      padding: 20,
      borderBottom: '1px solid lightgrey',
    },
    card_header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '10px -10px 15px -10px',
    },
    card_info: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
      color: theme.custom.textColor.gray,
    },
    card_status: {
      height: 18,
      marginRight: 10,
    },
    card_content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    card_infoField: {
      color: theme.custom.textColor.gray,
      fontSize: 9,
      letterSpacing: 2,
      marginBottom: 8,
    },
  };
}
