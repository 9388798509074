import Dots from '../../assets/icons/dotsBorder.svg';
import DotsHover from '../../assets/icons/dotsBorderHover.svg';

export default function styles(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      transition: 'all .2s',
      paddingBottom: '2px',

      borderWidth: '0 0 4px 0',
      borderStyle: 'dotted',
      borderImageSource: `url(${Dots})`,
      borderImageSlice: '33% 33%',
      borderImageRepeat: 'round',

      '&:hover': {
        cursor: 'pointer',
        color: theme.custom.linkColor,
        borderImageSource: `url(${DotsHover})`,
      },
    },
    text: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontFamily: 'Inter',
      fontWeight: 'normal',
      lineHeight: '20px',
      color: 'currentColor',

      '& > a': {
        color: 'currentColor',
        textDecoration: 'none',
      },
    },
  };
}
