import { Map, fromJS } from 'immutable';

import * as actions from './actions';

const initialState = Map({});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.GET_USER_INFO_SUCCESS:
    case actions.UPDATE_USER_INFO_SUCCESS: {
      return fromJS(payload);
    }
    default:
      return state;
  }
};
