export default function styles(theme) {
  return {
    profileInfo: {
      '& > $sectionItem': {
        marginBottom: 50,

        [theme.breakpoints.down('sm')]: {
          marginBottom: 36,
        },
      },
    },
    sectionItem: {
      maxWidth: 900,
    },
  };
}
