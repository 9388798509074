import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as DotsIcon } from '../../../../../../assets/icons/verticalDots.svg';
import GatewayStyledMenu from './GatewayStyledMenu';
import GatewayStyledMenuItem from './GatewayStyledMenuItem';
import GatewayDetailsModal from '../../containers/GatewayDetailsModal';
import DeleteGatewayModal from '../../../../../../components/DeleteGatewayModal';
import ConfirmRelocateGatewayModal from '../../../../../../components/ConfirmRelocateGatewayModal';
import useGatewayActionMenu from './hooks';

function GatewayActionMenu({
  classes, gatewayId, gatewayStatus, reFetchGateways,
}) {
  const {
    anchorEl,
    open,
    isOpenDeleteModal,
    isOpenDetailsModal,
    isOpenConfirmRelocateModal,
    toggleConfirmRelocateModalState,
    toggleDeleteModalState,
    toggleDetailsModalState,
    handleDeleteGateways,
    handleRelocateGateways,
    handleClick,
    handleClose,
  } = useGatewayActionMenu(gatewayId, reFetchGateways);

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <DotsIcon />
      </IconButton>
      <GatewayStyledMenu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <GatewayStyledMenuItem
          onClick={() => {
            handleClose();
            toggleDetailsModalState();
          }}
        >
          <div className={classes.actionInfo}>
            <FormattedMessage id="gatewayBulkActionDetails" />
          </div>
        </GatewayStyledMenuItem>

        <GatewayStyledMenuItem
          disabled={gatewayStatus === 'Pending'}
          onClick={() => {
            handleClose();
            toggleConfirmRelocateModalState();
          }}
        >
          <div className={classes.actionInfo}>
            <FormattedMessage id="gatewayBulkActionRelocate" />
          </div>
        </GatewayStyledMenuItem>

        <GatewayStyledMenuItem
          onClick={() => {
            handleClose();
            toggleDeleteModalState();
          }}
        >
          <div className={classes.warningInfo}>
            <FormattedMessage id="gatewayBulkActionDelete" />
          </div>
        </GatewayStyledMenuItem>
      </GatewayStyledMenu>

      {
        isOpenDeleteModal && (
          <DeleteGatewayModal
            form="DELETE_GATEWAY_FORM"
            onSubmit={handleDeleteGateways}
            isOpen={isOpenDeleteModal}
            toggleModalState={toggleDeleteModalState}
            deleteGatewayCount={1}
          />
        )
      }

      {
        isOpenConfirmRelocateModal && (
          <ConfirmRelocateGatewayModal
            form="CONFIRM_RELOCATE_GATEWAY_FORM"
            onSubmit={handleRelocateGateways}
            isOpen={isOpenConfirmRelocateModal}
            toggleModalState={toggleConfirmRelocateModalState}
          />
        )
      }

      <GatewayDetailsModal
        gatewayId={gatewayId}
        isOpen={isOpenDetailsModal}
        toggleModalState={toggleDetailsModalState}
        toggleDeleteModalState={toggleDeleteModalState}
        toggleConfirmRelocateModalState={toggleConfirmRelocateModalState}
      />
    </div>
  );
}

GatewayActionMenu.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  gatewayId: PropTypes.string.isRequired,
  gatewayStatus: PropTypes.string.isRequired,
  reFetchGateways: PropTypes.func.isRequired,
};

export default withStyles((theme) => ({
  actionInfo: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  warningInfo: {
    fontSize: 14,
    color: theme.custom.error,
  },
}))(GatewayActionMenu);
