export default function styles() {
  return {
    root: {
      borderRadius: '50%',
      objectFit: 'cover',
    },
    medium: {
      width: '32px',
      height: '32px',
    },
    large: {
      width: '54px',
      height: '54px',
    },
  };
}
