import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/close.svg';
import GatewayStatusIndicator from '../../components/GatewayStatusIndicator';
import Loader from '../../../../../../components/Loader';
import CustomButton from '../../../../../../components/CustomButton';
import GatewayNameInfo from './NameInfo';
import GatewayBaseInfo from './BaseInfo';
import GatewayLocationInfo from './LocationInfo';
import GatewayAddressInfo from './AddressInfo';
import useFetchGateway from './useFetchGateway';
import styles from './styles';

function GatewayDetailsModal({
  classes,
  isOpen,
  toggleModalState,
  gatewayId,
  toggleDeleteModalState,
  toggleConfirmRelocateModalState,
}) {
  const {
    loading,
    gatewayDetails,
  } = useFetchGateway(gatewayId, toggleModalState, isOpen);

  return (
    <SwipeableDrawer
      classes={{
        paper: classes.paper,
      }}
      anchor="right"
      open={isOpen}
      onClose={toggleModalState}
      onOpen={toggleModalState}
    >
      {
        loading
          ? (<Loader />)
          : (
            <div className={classes.drawerWrap}>
              <div className={classes.drawerHeader}>
                <div className={classes.headerTitle}>
                  <GatewayNameInfo gatewayDetails={gatewayDetails} />

                  <ButtonBase onClick={toggleModalState}>
                    <CloseIcon />
                  </ButtonBase>
                </div>

                <div className={classes.headerStatus}>
                  <GatewayStatusIndicator status={gatewayDetails.status} />
                </div>

                <div className={classes.divider}>
                  <Divider />
                </div>
              </div>

              <div className={classes.drawerContent}>
                <GatewayBaseInfo gatewayDetails={gatewayDetails} />

                <div className={classes.divider}>
                  <Divider />
                </div>

                <GatewayLocationInfo gatewayDetails={gatewayDetails} />

                <div className={classes.divider}>
                  <Divider />
                </div>

                <GatewayAddressInfo gatewayDetails={gatewayDetails} />

                <div className={classes.divider}>
                  <Divider />
                </div>
              </div>

              <div className={classes.drawerFooter}>
                <CustomButton
                  additionalClass={classes.buttonRelocate}
                  variant="outlined"
                  color="primary"
                  disabled={gatewayDetails.status === 'Pending'}
                  onClick={toggleConfirmRelocateModalState}
                >
                  <FormattedMessage id="gatewayRelocateBtn" />
                </CustomButton>

                <ButtonBase
                  className={classes.buttonDelete}
                  onClick={toggleDeleteModalState}
                >
                  <FormattedMessage id="gatewayDeleteBtn" />
                </ButtonBase>
              </div>
            </div>
          )
      }
    </SwipeableDrawer>
  );
}

GatewayDetailsModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  gatewayId: PropTypes.string.isRequired,
  toggleModalState: PropTypes.func.isRequired,
  toggleDeleteModalState: PropTypes.func.isRequired,
  toggleConfirmRelocateModalState: PropTypes.func.isRequired,
};

export default compose(withStyles(styles))(GatewayDetailsModal);
