export default function styles(theme) {
  return {
    content: {
      height: '100%',
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      },
    },
    titleWrapperIsLoading: {
      // TODO: use it class if you need to fix loading jumping
    },
    modalContent: {
      width: '500px',
      '@media (max-width:1023px)': {
        boxSizing: 'border-box',
        width: '100%',
      },
    },
    tip: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.custom.textColor.grey,
      opacity: 0.8,
    },
    icon: {
      marginRight: '20px',
    },
  };
}
