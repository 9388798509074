import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

import { gatewayListPropTypes } from '../propTypes';

import GatewaysBulkActions from '../../../components/GatewaysBulkActions';

import styles from './styles';

function GatewayListHeader({
  classes,
  entities,
  selectedIds,
  handleSelectAllIds,
  handleDeleteGateways,
  isOpen,
  toggleModalState,
}) {
  return (
    <div className={classes.root}>
      <Checkbox
        color="primary"
        checked={
          (entities.length === selectedIds.length)
          || Boolean(selectedIds.length)
        }
        onClick={handleSelectAllIds}
        indeterminate={
          Boolean(selectedIds.length)
          && (entities.length !== selectedIds.length)
        }
      />

      {
        selectedIds.length ? (
          <GatewaysBulkActions
            isMobile
            handleDeleteGateways={handleDeleteGateways}
            deleteGatewayCount={selectedIds.length}
            isOpen={isOpen}
            toggleModalState={toggleModalState}
          />
        ) : null
      }
    </div>
  );
}

GatewayListHeader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  entities: gatewayListPropTypes.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSelectAllIds: PropTypes.func.isRequired,
  handleDeleteGateways: PropTypes.func.isRequired,
  toggleModalState: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default withStyles(styles)(GatewayListHeader);
