import ROUTES from '../../contants/routes';

export default function styles(theme) {
  return {
    authLayout: {
      backgroundColor: ({ location: { pathname } }) => (pathname === ROUTES.HOTSPOTS.DEFAULT_PATH
        ? theme.custom.backgroundColor
        : theme.custom.textColor.primary),
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      minWidth: '100%',
      minHeight: '100%',
    },
    authHeader: {},
    authMain: {
      flexGrow: 1,
      flexShrink: 1,
      alignItems: 'stretch',
      padding: '40px 64px',

      [theme.breakpoints.down('sm')]: {
        padding: '24px 20px',
      },
    },
    authFooter: {
      padding: '40px 64px',

      [theme.breakpoints.down('sm')]: {
        padding: '24px 20px',
      },
    },
  };
}
