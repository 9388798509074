import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';

import styles from './styles';
import CustomModal from '../CustomModal';
import { logOut, setLogoutStatus } from '../../redux/reducers/auth/actions';
import { logoutDelay, timeBeforeLogout } from './constants';
import { ReactComponent as ShieldIcon } from '../../assets/icons/shieldIcon.svg';

const AutoLogoutModal = ({ classes }) => {
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [timerId, setTimerId] = useState(undefined);

  const handleLogout = useCallback(() => dispatch(logOut()), [dispatch]);
  const handleSetLogoutStatus = useCallback((data) => dispatch(setLogoutStatus(data)), [dispatch]);

  const handleOnIdle = () => {
    handleOpenModal();
  };

  const { reset } = useIdleTimer({
    timeout: timeBeforeLogout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const handleOpenModal = () => {
    const timer = setTimeout(() => {
      handleLogout();
      handleSetLogoutStatus({ expired: true });
    }, logoutDelay);
    setTimerId(timer);
    setIsOpenModal(true);
  };

  const handleContinueSession = () => {
    reset();
    clearTimeout(timerId);
    setIsOpenModal(false);
  };

  return (
    <CustomModal
      handleClose={handleContinueSession}
      isOpen={isOpenModal}
      cancelText="imHere"
      titleId="hereModalTitle"
      onlyClose
    >
      <div className={classes.noticeWrap}>
        <div className={classes.noticeIcon}>
          <ShieldIcon />
        </div>

        <div className={classes.noticeText}>
          <FormattedMessage id="stillHere" />
        </div>
      </div>
    </CustomModal>
  );
};

AutoLogoutModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(
  withStyles(styles),
)(AutoLogoutModal);
