import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import ButtonBase from '@material-ui/core/ButtonBase';
import styles from './styles';
import CustomModal from '../CustomModal';
import lorem from '../../mockData';
import CustomLink from '../CustomLink';

const TermsPolicyModal = ({
  classes,
  isFooter,
  footerTitle,
  title,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOpenModal = () => setIsOpenModal(true);

  const handleCloseModal = () => setIsOpenModal(false);

  return (
    <>
      {
        isFooter
          ? (
            <ButtonBase
              onClick={handleOpenModal}
              className={classes.footerLink}
              disableRipple
            >
              <FormattedMessage id={isFooter ? footerTitle : 'termsSmall'} />
            </ButtonBase>
          )
          : (
            <CustomLink>
              <ButtonBase
                onClick={handleOpenModal}
                disableRipple
              >
                <FormattedMessage id={isFooter ? footerTitle : 'termsSmall'} />
              </ButtonBase>
            </CustomLink>
          )
      }

      <CustomModal
        handleClose={handleCloseModal}
        isOpen={isOpenModal}
        cancelText="close"
        titleId={title}
        onlyClose
      >
        <div className={classes.content}>
          {lorem}
        </div>
      </CustomModal>
    </>
  );
};

TermsPolicyModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  footerTitle: PropTypes.string,
  isFooter: PropTypes.bool,
  title: PropTypes.string,
};

TermsPolicyModal.defaultProps = {
  footerTitle: 'termsAndConditions',
  title: 'termsAndConditions',
  isFooter: false,
  classes: {},
};

export default compose(
  withStyles(styles),
)(TermsPolicyModal);
