import { API_REQUEST } from '../api/actions';
import { API_ENDPOINTS } from '../../../contants/api';

export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const CLEAR_ORGANIZATION = 'CLEAR_ORGANIZATION';

export const CREATE_ORGANIZATION_START = 'CREATE_ORGANIZATION_START';
export const CREATE_ORGANIZATION_ERROR = 'CREATE_ORGANIZATION_ERROR';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';

export const GET_ORGANIZATIONS_START = 'GET_ORGANIZATIONS_START';
export const GET_ORGANIZATIONS_ERROR = 'GET_ORGANIZATIONS_ERROR';
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS';

export const setOrganization = (payload) => ({
  type: SET_ORGANIZATION,
  payload,
});

export const clearOrganization = (payload) => ({
  type: CLEAR_ORGANIZATION,
  payload,
});

export const createOrganizations = (data) => ({
  type: API_REQUEST,
  payload: {
    url: API_ENDPOINTS.ORGANIZATIONS._,
    method: 'post',
    data,
  },
  dispatch: {
    START: CREATE_ORGANIZATION_START,
    SUCCESS: CREATE_ORGANIZATION_SUCCESS,
    ERROR: CREATE_ORGANIZATION_ERROR,
  },
});

export const getOrganizations = () => ({
  type: API_REQUEST,
  payload: {
    url: API_ENDPOINTS.ORGANIZATIONS._,
    method: 'get',
  },
  dispatch: {
    START: GET_ORGANIZATIONS_START,
    SUCCESS: GET_ORGANIZATIONS_SUCCESS,
    ERROR: GET_ORGANIZATIONS_ERROR,
  },
});
