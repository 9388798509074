export default function styles(theme) {
  return {
    content: {
      borderLeft: ({ isWarning }) => (
        isWarning
          ? '3px solid rgba(241, 52, 86, 0.9)'
          : '3px solid rgba(85, 85, 85, 0.1)'
      ),
      paddingLeft: '32px',
      marginBottom: '60px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '24px',
        borderLeft: 'none!important',
        paddingLeft: '0',
      },
    },
    withoutBorder: {
      '@media (max-width:1023px)': {
        borderBottom: 'none',
      },
    },
    block: {
      display: 'flex',
      '@media (max-width:1023px)': {
        flexDirection: 'column',
      },
    },
    optional: {
      color: theme.custom.textColor.gray,
      fontSize: '14px',
      lineHeight: '20px',
      marginTop: '10px',
      opacity: 0.6,
      fontStyle: 'italic',
    },
    title: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      opacity: 0.8,
      wordBreak: 'break-word',
      marginRight: 40,
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        margin: '0 0 20px 0',
        paddingTop: 20,
        borderTop: ({ isWarning }) => (
          isWarning
            ? '3px solid rgba(241, 52, 86, 0.9)'
            : '3px solid rgba(85, 85, 85, 0.1)'
        ),
      },
      width: '30%',
    },
    contentWrap: {
      width: '65%',
      '@media (max-width:1023px)': {
        width: 'auto',
      },
    },
  };
}
