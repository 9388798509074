import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { compose } from 'recompose';
import { withRouter } from 'react-router';
import styles from './styles';
import { getUserProfile } from '../../redux/reducers/userProfile/selectors';
import PreviewBlock from '../PreviewBlock';
import settingsIcon from '../../assets/icons/settings.svg';
import { logOut, setLogoutStatus } from '../../redux/reducers/auth/actions';
import ROUTES from '../../contants/routes';

const UserMenu = ({ classes, history: { push } }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const dispatch = useDispatch();
  const { name, role, profilePhoto } = useSelector(getUserProfile);

  const handleViewProfile = useCallback(() => {
    push(ROUTES.PROFILE.INDEX);
    setOpen(false);
  }, [push]);

  const handleLogout = useCallback(() => {
    dispatch(logOut());
    dispatch(setLogoutStatus({ success: true }));
  }, [dispatch]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleShow = () => setOpen(true);

  const handleHide = () => setOpen(false);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <div className={classes.root}>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        {...(!isMobile ? {
          onMouseEnter: handleShow,
          onMouseLeave: handleHide,
        } : {})}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <div>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  className={classes.menuWrap}
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <div className={classes.userInfo}>
                    <PreviewBlock
                      name={name}
                      profilePhoto={profilePhoto}
                      wrapClass={classes.previewWrap}
                      contentClass={classes.previewContent}
                      nameClass={classes.previewName}
                    />
                    <div className={classes.userName}>{name}</div>
                    <div className={classes.userRole}>{role}</div>
                  </div>
                  <MenuItem className={classes.menuItem} onClick={handleViewProfile}>
                    <FormattedMessage id="yourProfile" />
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={handleLogout}>
                    <FormattedMessage id="logout" />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
      <div
        onKeyPress={() => {}}
        role="button"
        tabIndex="0"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        {...(!isMobile ? {
          onMouseEnter: handleShow,
          onMouseLeave: handleHide,
        } : {})}
        className={classes.toggle}
      >
        <PreviewBlock
          withoutAvatar
          name={name}
          wrapClass={classes.togglePreview}
        />
        <div title={name} className={classes.name}>{name}</div>
        <img src={settingsIcon} alt="icon" />
      </div>
    </div>
  );
};

UserMenu.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

UserMenu.defaultProps = {
  classes: {},
};

export default compose(withStyles(styles), withRouter)(UserMenu);
