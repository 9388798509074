export default function styles(theme) {
  return {
    wrap: {
      width: '100%',
    },
    buttonBlock: {
      marginTop: '40px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    button: {
      height: '60px',
      paddingLeft: '60px',
      paddingRight: '60px',
    },
    fieldWrap: {
      marginBottom: '24px',
    },
    titleLink: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette.primary.main,
      textDecoration: 'none',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      borderBottomStyle: 'dotted',
      transition: 'all .2s',
      '&:hover': {
        color: theme.custom.linkColor,
        borderBottom: `2px dotted ${theme.custom.linkColor}`,
      },
    },
  };
}
