import { API_REQUEST } from '../api/actions';
import { API_ENDPOINTS } from '../../../contants/api';

export const GET_ORGANIZATION_START = 'GET_ORGANIZATION_START';
export const GET_ORGANIZATION_ERROR = 'GET_ORGANIZATION_ERROR';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';

export const GET_ORGANIZATION_BY_SUB_DOMAIN_START = 'GET_ORGANIZATION_BY_SUB_DOMAIN_START';
export const GET_ORGANIZATION_BY_SUB_DOMAIN_ERROR = 'GET_ORGANIZATION_BY_SUB_DOMAIN_ERROR';
export const GET_ORGANIZATION_BY_SUB_DOMAIN_SUCCESS = 'GET_ORGANIZATION_BY_SUB_DOMAIN_SUCCESS';

export const UPDATE_ORGANIZATION_START = 'UPDATE_ORGANIZATION_START';
export const UPDATE_ORGANIZATION_ERROR = 'UPDATE_ORGANIZATION_ERROR';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';

const { REACT_APP_API_PREFIX } = process.env;

export const getOrganization = (id) => ({
  type: API_REQUEST,
  payload: {
    url: `${API_ENDPOINTS.ORGANIZATIONS._}/${id}`,
    method: 'get',
  },
  dispatch: {
    START: GET_ORGANIZATION_START,
    SUCCESS: GET_ORGANIZATION_SUCCESS,
    ERROR: GET_ORGANIZATION_ERROR,
  },
});

export const getOrganizationBySubDomain = () => {
  const pathName = window ? window.location.hostname : '';
  const subdomain = pathName.split(`.${REACT_APP_API_PREFIX}`)[0];

  return {
    type: API_REQUEST,
    payload: {
      url: `${API_ENDPOINTS.ORGANIZATIONS.GET_BY_SUBDOMAIN}/${subdomain}`,
      method: 'get',
    },
    dispatch: {
      START: GET_ORGANIZATION_BY_SUB_DOMAIN_START,
      SUCCESS: GET_ORGANIZATION_BY_SUB_DOMAIN_SUCCESS,
      ERROR: GET_ORGANIZATION_BY_SUB_DOMAIN_ERROR,
    },
  };
};

export const updateOrganization = (payload) => {
  const { data, orgId } = payload;

  return {
    type: API_REQUEST,
    payload: {
      url: `${API_ENDPOINTS.ORGANIZATIONS._}/${orgId}`,
      method: 'put',
      data,
    },
    dispatch: {
      START: UPDATE_ORGANIZATION_START,
      SUCCESS: UPDATE_ORGANIZATION_SUCCESS,
      ERROR: UPDATE_ORGANIZATION_ERROR,
    },
  };
};
