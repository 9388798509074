export default function styles(theme) {
  return {
    root: {
      padding: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      [theme.breakpoints.down('sm')]: {
        display: 'block',
        padding: '20px 0',
      },
    },
    paginationWrap: {
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        marginBottom: 30,
      },
    },
    outlined: {
      fontSize: 12,
      opacity: 0.8,
      padding: '8px 36px 8px 12px',
    },
    iconOutlined: {
      right: 9,
    },
    icon: {
      width: 14,
    },
    paginationControlButton: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 9,
      letterSpacing: 2,
      margin: '0 20px',
      textTransform: 'uppercase',
    },
    rowsPerPageWrap: {
      display: 'flex',
      alignItems: 'center',
    },
    rowsPerPageText: {
      fontSize: 12,
      color: '#555555',
      margin: '0 10px',
    },
    rowsPerPageDropdown: {},
    disabled: {
      opacity: 0.4,
    },
  };
}
