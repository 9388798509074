/* eslint-disable import/no-anonymous-default-export */

import { Map, fromJS } from 'immutable';
import camelCase from 'lodash/camelCase';
import * as actions from './actions';

export const initialRequestState = Map({
  loading: false,
  error: false,
  success: false,
  redirect: false,
  errorData: null,
  redirectData: {},
});

const initialState = Map({});

export default (state = initialState, action) => {
  const { type } = action;

  if (type === actions.REQUEST_STATUS_RESET) {
    return state.set(action.payload, initialRequestState);
  }

  const matches = /(.*)_(START|SUCCESS|ERROR|REDIRECT)/.exec(type);

  if (!matches || /redux/ig.test(type)) return state;

  const [, requestName, requestState] = matches;
  const stateName = camelCase(requestName.toLowerCase());

  switch (requestState) {
    case 'START': {
      return state.set(stateName, initialRequestState.merge(fromJS({
        loading: true,
      })));
    }
    case 'SUCCESS': {
      return state.set(stateName, initialRequestState.merge(fromJS({
        success: true,
      })));
    }
    case 'ERROR': {
      return state.set(stateName, initialRequestState.merge(fromJS({
        error: true,
        errorData: action.payload,
      })));
    }
    case 'REDIRECT': {
      return state.set(stateName, initialRequestState.merge(fromJS({
        redirect: true,
        redirectData: action.payload,
      })));
    }
    default: {
      return state;
    }
  }
};
