export default function styles(theme) {
  return {
    wrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    },
    title: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '29px',
      lineHeight: '36px',
      letterSpacing: '-0.01em',
    },
    button: {
      height: '60px',
      paddingLeft: '60px',
      paddingRight: '60px',
      minWidth: '240px',
    },
    buttonContent: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    icon: {
      marginRight: '8px',
    },
    text: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.custom.textColor.gray,
      opacity: '0.8',
      margin: '40px 0',
      maxWidth: '376px',
    },
  };
}
