import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { destroy } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getHotspotsCreationTimer } from '../../../../../redux/reducers/hotspots/selectors';
import Stepper from '../../../../../components/Stepper';
import CountdownTimer from '../../../../../components/CountdownTimer';
import useStepper from '../../../../../components/Stepper/hook';
import ROUTES from '../../../../../contants/routes';
import styles from './styles';
import infoIcon from '../../../../../assets/icons/infoIcon.svg';
import Section from '../../../../../components/Section';
import Modal from '../../../../../components/CustomModal';
import BasicHotspotDetailsForm from '../BasicHotspotDetails';
import { updateHotspotCreationTimer } from '../../../../../redux/reducers/hotspots/actions';
import FindLocationForm from '../FindLocation';
import AssertLocationFee from '../AssertLocationFee';
import FinishHotspotAdding from '../FinishHotspotSetting';

const ENV_TIMER = Number(process.env.REACT_APP_HOTSPOT_TIMER);

const NewHotspot = ({ classes, startStep }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const isStartHotspotsCreation = useSelector(getHotspotsCreationTimer);

  const { activeStep, nextStep, prevStep } = useStepper(startStep);
  const steps = useMemo(() => [
    'general',
    'location',
    'payment',
    'configuration',
  ], []);

  const [isOpenExpiredModal, setIsOpenExpiredModal] = useState(false);
  const openExpiredModal = useCallback(() => setIsOpenExpiredModal(true), []);
  const closeExpiredModal = useCallback(() => setIsOpenExpiredModal(false), []);

  const onTimerExpired = useCallback(() => {
    dispatch(updateHotspotCreationTimer({ isStartCreation: false }));
    openExpiredModal();
  }, [dispatch, openExpiredModal]);

  const onUnderstoodTimerInfo = useCallback(() => {
    closeExpiredModal();
    push(ROUTES.HOTSPOTS.DEFAULT_PATH);
  }, [closeExpiredModal, push]);

  useEffect(() => {
    let timerId;

    if (isStartHotspotsCreation) {
      timerId = setTimeout(() => {
        onTimerExpired();
      }, ENV_TIMER);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [isStartHotspotsCreation, onTimerExpired]);

  useEffect(() => () => {
    // reset all forms
    dispatch(destroy('addBasicHotspotDetails', 'hotspotAddLocationForm'));

    // reset timer
    dispatch(updateHotspotCreationTimer({ isStartCreation: false }));
  }, [dispatch]);

  return (
    <Section
      title={(
        <div className={classes.titleWrapper}>
          <FormattedMessage id="newHotspot" />
          {
            activeStep > 1 && activeStep < 4 && isStartHotspotsCreation
            && (
              <CountdownTimer
                timer={ENV_TIMER}
                interval={1000}
                timerOn={isStartHotspotsCreation}
              />
            )
          }
        </div>
      )}
      withDivider
      actionElement={(
        <Stepper steps={steps} activeStep={activeStep} />
      )}
    >
      <div className={classes.content}>
        { activeStep === 1 && <BasicHotspotDetailsForm nextStep={nextStep} /> }
        { activeStep === 2 && <FindLocationForm form="hotspotAddLocationForm" nextStep={nextStep} /> }
        { activeStep === 3 && <AssertLocationFee nextStep={nextStep} prevStep={prevStep} /> }
        { activeStep === 4 && <FinishHotspotAdding /> }
      </div>
      <Modal
        isOpen={isOpenExpiredModal}
        titleId="timesUp"
        submitteText="understand"
        showClose={false}
        contentClass={classes.modalContent}
        onSubmite={onUnderstoodTimerInfo}
        handleClose={closeExpiredModal}
        modalProps={{
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        }}
      >
        <div className={classes.tip}>
          <img className={classes.icon} src={infoIcon} alt="info" />
          <FormattedMessage id="sessionRunOutTime" />
        </div>
      </Modal>
    </Section>
  );
};

NewHotspot.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  startStep: PropTypes.number,
};

NewHotspot.defaultProps = {
  classes: {},
  startStep: 1,
};

export default withStyles(styles)(NewHotspot);
