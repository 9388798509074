import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import isArray from 'lodash/isArray';
import cn from 'classnames';

import styles from './styles';

const FormInfoBlock = ({ classes, error: { message }, type }) => {
  const ref = useRef(null);

  useEffect(() => {
    ref.current.scrollIntoView();
  }, []);

  return (
    <div className={cn(classes.wrap, classes[type])} ref={ref}>
      {isArray(message)
        ? message.map((msg) => (<div key={msg} className={classes.errorMsg}>{msg}</div>))
        : <div className={classes.errorMsg}>{message}</div>}
    </div>
  );
};

FormInfoBlock.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }),
  classes: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.string,
};

FormInfoBlock.defaultProps = {
  error: {
    message: 'Error',
  },
  classes: {},
  type: 'error',
};

export default withStyles(styles)(FormInfoBlock);
