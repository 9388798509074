export default function styles(theme) {
  return {
    modalContent: {
      width: '600px',

      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    content: {
      display: 'flex',

      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
    },
    noticeWrap: {
      display: 'flex',
      marginBottom: 20,

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    noticeIcon: {
      marginRight: 20,
    },
    noticeText: {
      fontSize: 14,
      color: theme.custom.error,
    },
    formWrap: {
      marginLeft: 60,

      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    fieldWrap: {
      width: 300,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  };
}
