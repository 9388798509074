import {
  List, Map, fromJS,
} from 'immutable';
import get from 'lodash/get';

import normalizeEntities, { enhanceEntitiesId } from '../utils';
import {
  DELETE_GATEWAYS_SUCCESS,
  GET_GATEWAYS_SUCCESS,
  GET_GATEWAY_BY_SN_SUCCESS,
  UPDATE_GATEWAYS_SUCCESS,
} from './actions';

export const initialState = Map({
  gatewayDetails: Map({}),
  entities: Map({}),
  ids: List([]),
});

function gatewaysReducer(state = initialState, { type, payload, meta }) {
  switch (type) {
    case GET_GATEWAYS_SUCCESS: {
      const { entities, ids } = normalizeEntities({
        entitiesList: enhanceEntitiesId(payload.items, 'sn'),
      });
      return state
        .set('entities', fromJS(entities))
        .set('ids', fromJS(ids));
    }
    case GET_GATEWAY_BY_SN_SUCCESS: {
      return state.set('gatewayDetails', fromJS(payload));
    }
    case UPDATE_GATEWAYS_SUCCESS: {
      const [updatedGateway] = enhanceEntitiesId([payload], 'sn');
      return state
        .set('gatewayDetails', fromJS(updatedGateway))
        .setIn(['entities', payload.sn], fromJS(updatedGateway));
    }
    case DELETE_GATEWAYS_SUCCESS: {
      const ids = get(meta, 'data.serialNumbers');
      const deletedGatewayIds = ids.map((id) => id);
      const newEntities = state.get('entities').deleteAll(deletedGatewayIds);
      return state.set('entities', newEntities);
    }
    default:
      return state;
  }
}

export default gatewaysReducer;
