export default function styles(theme) {
  return {
    steps: {
      display: 'flex',
    },
    step: {
      display: 'flex',
      alignItems: 'center',
    },
    numWrap: {
      border: `1px solid ${theme.palette.primary.light}`,
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '@media (max-width: 1023px)': {
        border: 'none',
      },
    },
    inactiveWrap: {
      border: 'none',
    },
    number: {
      opacity: '0.8',
      background: theme.palette.primary.main,
      borderRadius: '50%',
      width: '32px',
      height: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.custom.textColor.primary,
      [theme.breakpoints.down('sm')]: {
        width: '24px',
        height: '24px',
        fontSize: '11px',
      },
    },
    inactiveNum: {
      color: 'rgba(85, 85, 85, 0.5)',
      background: 'rgba(85, 85, 85, 0.1)',
    },
    text: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      opacity: 0.8,
      marginLeft: '4px',
      '@media (max-width: 1023px)': {
        display: 'none',
      },
    },
    inactiveText: {
      opacity: 0.4,
    },
    separator: {
      margin: '5px 16px 0 16px',
      '@media (max-width: 1023px)': {
        margin: '5px 5px 0 5px',
      },
    },
  };
}
