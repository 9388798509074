import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import Loader from '../../Loader';

import styles from './styles';

function TableCustomLayout({
  classes,
  loading,
  children,
}) {
  return (
    <div className={classes.root}>
      {
        loading && (
          <div className={classes.loaderLayout}>
            <Loader size={70} />
          </div>
        )
      }

      {children}
    </div>
  );
}

TableCustomLayout.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(TableCustomLayout);
