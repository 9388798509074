export default function styles(theme) {
  return {
    root: {
      position: 'relative',
      opacity: ({ loading }) => (loading ? 0.4 : 1),
      pointerEvents: ({ loading }) => (loading ? 'none' : ''),
    },
    loaderLayout: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    table: {
      width: '100%',
      backgroundColor: theme.custom.textColor.primary,
      borderRadius: 8,
    },
    tableHead: {
      '& > tr > th': {
        fontSize: 9,
        letterSpacing: 2,
        textTransform: 'uppercase',
      },
    },
    tableBody: {
      '& > tr > td': {
        fontSize: 12,
      },
    },
    tableFooter: {},
  };
}
