const ROUTES = {
  LOGIN: {
    INDEX: '/login',
    DEFAULT_PATH: '/login',
  },
  UNFINISHED_PROFILE: {
    INDEX: '/unfinished-profile',
    DEFAULT_PATH: '/unfinished-profile',
  },
  REGISTRATION: {
    INDEX: '/registration',
    DEFAULT_PATH: '/registration',
  },
  FORGOT_PASSWORD: {
    INDEX: '/forgot-password',
    DEFAULT_PATH: '/forgot-password',
  },
  CONFIRM_PASSWORD: {
    INDEX: '/confirm-password',
    DEFAULT_PATH: '/confirm-password',
  },
  EMAIL_VERIFICATION: {
    createPath: ({ type }) => ['/email-verification', type].filter(Boolean).join('/'),
    INDEX: '/email-verification/:type?',
    DEFAULT_PATH: '/email-verification',
  },
  TOKEN_STATUS: {
    createPath: ({ type }) => ['/token-status', type].filter(Boolean).join('/'),
    INDEX: '/token-status/:type?',
    DEFAULT_PATH: '/token-status',
  },
  HOTSPOTS: {
    createPath: ({ path, sn }) => ['/hotspots', path, sn].filter(Boolean).join('/'),
    INDEX: '/hotspots/:path?/:sn?',
    DEFAULT_PATH: '/hotspots',
  },
  PROFILE: {
    INDEX: '/profile',
    DEFAULT_PATH: '/profile',
  },
  ORGANIZATIONS: {
    createPath: ({ path }) => ['/organizations', path].filter(Boolean).join('/'),
    INDEX: '/organizations/:path?',
    DEFAULT_PATH: '/organizations',
  },
  SETTINGS: {
    INDEX: '/settings',
    DEFAULT_PATH: '/settings',
  },
  LOCATIONS: {
    createPath: ({ path, locationId }) => ['/locations', locationId, path].filter(Boolean).join('/'),
    INDEX: '/locations/:locationId?/:path?',
    DEFAULT_PATH: '/locations',
  },
  GATEWAYS: {
    createPath: () => ['/gateways'].filter(Boolean).join('/'),
    INDEX: '/gateways',
    DEFAULT_PATH: '/gateways',
  },
  SSH_TUNNEL: {
    createPath: ({ locationId, id, organizationId }) => ['/ssh-tunnel', organizationId, locationId, id].filter(Boolean).join('/'),
    INDEX: '/ssh-tunnel/:organizationId?/:locationId?/:id?',
    DEFAULT_PATH: '/ssh-tunnel',
  },
  RELEASE_NOTES: {
    createPath: () => ['/release-notes'].filter(Boolean).join('/'),
    INDEX: '/release-notes',
    DEFAULT_PATH: '/release-notes',
  },
};

export default ROUTES;
