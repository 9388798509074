import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import FormBlock from '../../../../components/FormElements/FormBlock';
import CustomButton from '../../../../components/CustomButton';
import useDisconnectAccount from './hooks';
import DisconnectAccountModal from '../../../../components/DisconnectAccountModal';

function DisconnectAccount({ classes }) {
  const {
    isOpen,
    toggleModalState,
    onSubmit,
    hasGateways,
  } = useDisconnectAccount();

  return (
    <FormBlock label="disconnectAccountLabel" isWarning>
      <CustomButton
        additionalClass={classes.buttonWarning}
        variant="outlined"
        color="primary"
        onClick={toggleModalState}
      >
        <FormattedMessage id="disconnectAccountBtn" />
      </CustomButton>

      <div className={classes.hint}>
        <FormattedMessage id="disconnectAccountHint" />
      </div>

      {
        isOpen && (
          <DisconnectAccountModal
            isOpen={isOpen}
            handleModalState={toggleModalState}
            form="DISCONNECT_ACCOUNT_FORM"
            onSubmit={onSubmit}
            hasGateways={hasGateways}
          />
        )
      }
    </FormBlock>
  );
}

DisconnectAccount.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

DisconnectAccount.defaultProps = {
  classes: {},
};

export default DisconnectAccount;
