import { compose } from 'recompose';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { Redirect } from 'react-router';

import AuthorizeLayout from '../../layouts/AuthorizeLayout';
import {
  getIsConnectedProduct,
  getIsLoading,
  getIsLoggedInState,
} from '../../redux/reducers/auth/selectors';
import ROUTES from '../../contants/routes';
import StyledToast from '../../components/Notification/StyledToast';
import UnAuthorizeLayout from '../../layouts/UnAuthorizeLayout';
import introImg from '../../assets/images/intro.svg';
import ConnectProduct from '../../pages/ConnectProduct';
import AutoLogoutModal from '../../components/AutoLogoutModal';
import LoaderLayout from '../../components/Loader/LoaderLayout';

function AuthorizedRoute({ ...props }) {
  const isLoggedIn = useSelector(getIsLoggedInState);
  const isLoading = useSelector(getIsLoading);
  const isConnectedProduct = useSelector(getIsConnectedProduct);

  if (isLoading) {
    return (
      <LoaderLayout />
    );
  }

  if (isLoggedIn && !isConnectedProduct) {
    return (
      <UnAuthorizeLayout image={introImg} mainText="loginMainText" subText="loginSubText">
        <Route
          {...props}
          component={ConnectProduct}
        />
        <AutoLogoutModal />
        <StyledToast />
      </UnAuthorizeLayout>
    );
  }

  if (isLoggedIn) {
    return (
      <AuthorizeLayout>
        <Route {...props} />
        <StyledToast />
      </AuthorizeLayout>
    );
  }

  return <Redirect to={ROUTES.LOGIN.INDEX} />;
}

export default compose(withRouter)(AuthorizedRoute);
