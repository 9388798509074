export default function styles(theme) {
  return {
    wrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    },
    title: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '29px',
      lineHeight: '36px',
      letterSpacing: '-0.01em',
    },
    text: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.custom.textColor.gray,
      opacity: '0.8',
      margin: '40px 0',
      maxWidth: '376px',
    },
    buttonsBlock: {
      display: 'flex',
    },
    cancelBtn: {
      color: theme.palette.primary.main,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '48px',
      marginLeft: '52px',
      '&:disabled': {
        opacity: '0.5',
      },
    },
  };
}
