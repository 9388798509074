import { API_REQUEST } from '../api/actions';
import { API_ENDPOINTS } from '../../../contants/api';

export const NOTIFY_UPDATE_LOCATION = 'NOTIFY_UPDATE_LOCATION';

export const UPDATE_LOCATION_START = 'UPDATE_LOCATION_START';
export const UPDATE_LOCATION_ERROR = 'UPDATE_LOCATION_ERROR';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';

export const GET_LOCATION_START = 'GET_LOCATION_START';
export const GET_LOCATION_ERROR = 'GET_LOCATION_ERROR';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';

export const CLEAR_LOCATION_DATA = 'CLEAR_LOCATION_DATA';

export const clearLocationData = (payload) => ({
  type: CLEAR_LOCATION_DATA,
  payload,
});

export const getLocation = (payload) => {
  const { orgId, locationId } = payload;
  return {
    type: API_REQUEST,
    payload: {
      url: `${API_ENDPOINTS.ORGANIZATIONS._}/${orgId}${API_ENDPOINTS.LOCATIONS._}/${locationId}`,
      method: 'get',
    },
    dispatch: {
      START: GET_LOCATION_START,
      SUCCESS: GET_LOCATION_SUCCESS,
      ERROR: GET_LOCATION_ERROR,
    },
  };
};

export const updateLocation = (payload) => {
  const { orgId, data, locationId } = payload;
  return {
    type: API_REQUEST,
    payload: {
      url: `${API_ENDPOINTS.ORGANIZATIONS._}/${orgId}${API_ENDPOINTS.LOCATIONS._}/${locationId}`,
      method: 'put',
      data,
    },
    dispatch: {
      START: UPDATE_LOCATION_START,
      SUCCESS: UPDATE_LOCATION_SUCCESS,
      ERROR: UPDATE_LOCATION_ERROR,
    },
  };
};

export const notifyUpdateLocation = (payload) => ({
  type: NOTIFY_UPDATE_LOCATION,
  payload,
});
