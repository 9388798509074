import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Field, reduxForm,
} from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import ButtonBase from '@material-ui/core/ButtonBase';
import TextFormField from '../../../../../components/FormElements/TextField';
import CustomButton from '../../../../../components/CustomButton';
import {
  isRequired,
} from '../../../../../utils/validations';
import styles from './styles';
import ROUTES from '../../../../../contants/routes';
import { updateUserInfo } from '../../../../../redux/reducers/userProfile/actions';
import getRequestStatus from '../../../../../redux/reducers/requestStatus/selectors';
import FormInfoBlock from '../../../../../components/FormInfoBlock';
import useResetRequestStatus from '../../../../../hooks/useResetRequestStatus';
import WalletAddressHelpModal from '../../../../../components/WalletAddressHelpModal';
import Section from '../../../../../components/Section';
import CustomLink from '../../../../../components/CustomLink';

const AddHeliumWalletForm = ({
  handleSubmit,
  classes,
  valid,
  pristine,
  history,
}) => {
  const dispatch = useDispatch();

  const [isOpenFindWalletAddressModal, setIsOpenFindWalletAddressModal] = useState(false);
  const handleOpen = useCallback(() => {
    setIsOpenFindWalletAddressModal(true);
  }, []);

  const requestStatus = useSelector((state) => getRequestStatus(state, 'updateUserInfo'));

  useResetRequestStatus('updateUserInfo');

  const onSubmit = (data) => {
    dispatch(updateUserInfo({ data }));
  };

  const handleCancel = () => history.push(ROUTES.HOTSPOTS.DEFAULT_PATH);

  return (
    <Section
      title={<FormattedMessage id="addHeliumWallet" />}
      withDivider
    >
      <div className={classes.content}>
        <div className={classes.formWrapper}>
          <div className={classes.title}><FormattedMessage id="heliumWallet" /></div>
          <div className={classes.description}><FormattedMessage id="tellUsWalletAddress" /></div>

          {requestStatus?.error && (
            <div className={classes.errorWrap}>
              <FormInfoBlock error={requestStatus?.errorData} />
            </div>
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.fieldWrap}>
              <Field
                name="heliumWalletAddress"
                component={TextFormField}
                validate={[isRequired]}
                label={<FormattedMessage id="address" />}
                description={(
                  <>
                    <FormattedMessage id="cantFindWalletAddress" />
                    <CustomLink>
                      <ButtonBase
                        onClick={handleOpen}
                        disableRipple
                      >
                        <FormattedMessage id="here" />
                      </ButtonBase>
                    </CustomLink>
                  </>
                )}
              />

              <WalletAddressHelpModal
                isOpen={isOpenFindWalletAddressModal}
                handleModalState={setIsOpenFindWalletAddressModal}
              />
            </div>
            <div className={classes.buttonBlock}>
              <CustomButton
                type="submit"
                variant="contained"
                color="secondary"
                loading={requestStatus?.loading}
                disabled={!valid || pristine || requestStatus?.loading}
              >
                <FormattedMessage id="addWallet" />
              </CustomButton>

              <ButtonBase
                onClick={handleCancel}
                className={classes.buttonCancel}
                disableRipple
              >
                <FormattedMessage id="cancel" />
              </ButtonBase>
            </div>
            <div className={classes.notHaveAccount}>
              <FormattedMessage id="notHaveAccount" />
              <CustomLink>
                <ButtonBase
                  href={process.env.REACT_APP_HELIUM_APP_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  disableRipple
                >
                  <FormattedMessage id="downloadApp" />
                </ButtonBase>
              </CustomLink>
            </div>
          </form>
        </div>
      </div>
    </Section>
  );
};

AddHeliumWalletForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  valid: PropTypes.bool,
};

AddHeliumWalletForm.defaultProps = {
  handleSubmit: () => {},
  pristine: false,
  valid: false,
  history: {},
  classes: {},
};

export default compose(
  withStyles(styles),

  withRouter,

  reduxForm({
    form: 'addHeliumWalletForm',
    enableReinitialize: true,
  }),
)(AddHeliumWalletForm);
