import { Map } from 'immutable';

import * as actions from './actions';

const initialState = Map({
  isConnectedProduct: false,
  isLoggedIn: false,
  isLoading: true,
  logoutStatus: {
    success: false,
    expired: false,
    connectedError: false,
  },
});

function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.AUTH_SIGN_IN_SUCCESS:
    case actions.REFRESH_TOKEN_SUCCESS:
      return state
        .set('isLoggedIn', true);
    case actions.AUTH_CHECK_PRODUCT_CONNECT_START:
      return state.set('isLoading', true);
    case actions.AUTH_CHECK_PRODUCT_CONNECT_SUCCESS:
      return state
        .set('isConnectedProduct', !payload.isFirstLogin)
        .set('isLoading', false);
    case actions.AUTH_CONNECT_PRODUCT_SUCCESS:
      return state.set('isConnectedProduct', true);
    case actions.AUTH_CHECK_PRODUCT_CONNECT_ERROR:
    case actions.REFRESH_TOKEN_ERROR:
    case actions.LOG_OUT:
      return state.set('isLoggedIn', false).set('isLoading', false);
    case actions.RESET_GLOBAL_LOADING:
      return state.set('isLoading', false);
    case actions.SET_LOGOUT_STATUS: {
      const status = state.get('logoutStatus');
      return state.set('logoutStatus', { ...status, ...payload });
    }
    case actions.CLEAR_LOGOUT_STATUS:
      return state.set('logoutStatus', {
        success: false,
        expired: false,
        connectedError: false,
      });
    default:
      return state;
  }
}

export default authReducer;
