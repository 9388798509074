import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import { useParams } from 'react-router';

import PropTypes from 'prop-types';
import resentIcon from '../../assets/icons/resent.svg';
import checkIcon from '../../assets/icons/check.svg';
import CustomButton from '../../components/CustomButton';
import styles from './styles';

import getRequestStatus from '../../redux/reducers/requestStatus/selectors';
import { forgotPassword, resentEmail } from '../../redux/reducers/auth/actions';
import { getItem } from '../../utils/localStorageHelper';

const EmailCheck = ({ classes }) => {
  const dispatch = useDispatch();
  const { success, loading } = useSelector((state) => getRequestStatus(state, 'authResentEmail'));
  const { success: successForgot, loading: loadingForgot } = useSelector((state) => getRequestStatus(state, 'authForgotPassword'));
  const resentEmailRequest = (data) => dispatch(resentEmail(data));
  const forgotPasswordRequest = (data) => dispatch(forgotPassword(data));

  const [isResend, setIsResend] = useState(false);

  const { type } = useParams();

  const handleResentEmail = async () => {
    if (!isResend) {
      const email = getItem('userEmail');
      if (type === 'password') {
        await forgotPasswordRequest({ email }).promise;
      } else {
        await resentEmailRequest({ email }).promise;
      }
      setIsResend(true);
    }
  };

  const getText = () => {
    if (type === 'unverified') {
      return 'linkUnverifiedText';
    }
    if (type === 'expired') {
      return 'linkExpiredText';
    }
    if (type === 'password') {
      return 'resetPasswordText';
    }
    if (isResend) {
      return 'confirmationText';
    }
    return 'resentText';
  };

  const getButtonText = () => {
    if (isResend && type === 'password') {
      return 'emailResentBtn';
    }
    if (isResend) {
      return 'confirmResentBtn';
    }
    if (type === 'password') {
      return 'resentEmail';
    }
    return 'resentEmailBtn';
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.title}>
        <FormattedMessage id={type === 'expired' ? 'linkExpiredTitle' : 'checkEmailTitle'} />
      </div>
      <div className={classes.text}>
        <FormattedMessage id={getText()} />
      </div>
      <div>
        <CustomButton
          disabled={loading || success || loadingForgot || successForgot}
          loading={loading || loadingForgot}
          additionalClass={classes.button}
          onClick={handleResentEmail}
          variant="contained"
          color="secondary"
          size="large"
        >
          <div className={classes.buttonContent}>
            <img
              className={classes.icon}
              src={isResend ? checkIcon : resentIcon}
              alt="icon"
            />
            <FormattedMessage
              id={getButtonText()}
            />
          </div>
        </CustomButton>
      </div>
    </div>
  );
};

EmailCheck.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

EmailCheck.defaultProps = {
  classes: {},
};

export default withStyles(styles)(EmailCheck);
