import { useDispatch } from 'react-redux';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import ROUTES from '../../../../../../contants/routes';
import { runSaga } from '../../../../../../store';
import { sendApiRequest } from '../../../../../../redux/sagas/apiRequests';
import { deleteGateways } from '../../../../../../redux/reducers/gateways/actions';
import { showNotification } from '../../../../../../redux/reducers/notifications/actions';

export default function useGatewayActionMenu(gatewayId, reFetchGateways) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [isOpenDeleteModal, handleDeleteOpen] = useState(false);
  const [isOpenConfirmRelocateModal, handleConfirmRelocateOpen] = useState(false);
  const [isOpenDetailsModal, handleDetailsOpen] = useState(false);

  const toggleDeleteModalState = useCallback(() => {
    handleDeleteOpen(!isOpenDeleteModal);
  }, [isOpenDeleteModal]);

  const toggleConfirmRelocateModalState = useCallback(() => {
    handleConfirmRelocateOpen(!isOpenConfirmRelocateModal);
  }, [isOpenConfirmRelocateModal]);

  const toggleDetailsModalState = useCallback(() => {
    handleDetailsOpen(!isOpenDetailsModal);
  }, [isOpenDetailsModal]);

  const handleDeleteGateways = useCallback(async () => {
    const { ok } = await runSaga(
      sendApiRequest,
      deleteGateways({
        data: {
          serialNumbers: [gatewayId],
        },
      }),
    ).toPromise();

    if (ok) {
      reFetchGateways([gatewayId]);
      toggleDeleteModalState();
      dispatch(showNotification({
        type: 'info',
        message: 'Your hotspot(s) and miner(s) have been deleted from your profile.',
      }));
    }
  }, [dispatch, gatewayId, reFetchGateways, toggleDeleteModalState]);


  const handleRelocateGateways = useCallback(() => {
    push(ROUTES.HOTSPOTS.createPath({ path: 'relocate', sn: gatewayId }));
  }, [gatewayId, push]);


  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return useMemo(() => ({
    anchorEl,
    open,
    isOpenDeleteModal,
    isOpenConfirmRelocateModal,
    isOpenDetailsModal,
    toggleDeleteModalState,
    toggleDetailsModalState,
    toggleConfirmRelocateModalState,
    handleDeleteGateways,
    handleRelocateGateways,
    handleClick,
    handleClose,
  }), [
    anchorEl,
    open,
    isOpenDeleteModal,
    isOpenConfirmRelocateModal,
    isOpenDetailsModal,
    toggleDeleteModalState,
    toggleDetailsModalState,
    toggleConfirmRelocateModalState,
    handleDeleteGateways,
    handleRelocateGateways,
    handleClick,
    handleClose,
  ]);
}
