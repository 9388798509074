export default function styles(theme) {
  return {
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    iconFallback: {
      width: 16,
      height: 16,
      marginRight: 8,
    },
    infoField: {},
    value: {
      display: 'flex',
      fontSize: 16,
      marginBottom: 10,
      wordBreak: 'break-word',
    },
    updateLink: {
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',

      '& > svg': {
        marginLeft: 5,
      },
    },
    collapse: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '30vw',
      whiteSpace: 'nowrap',
      display: 'block',

      [theme.breakpoints.down('sm')]: {
        width: '80vw',
      },
    },
    upperCase: {
      textTransform: 'uppercase',
    },
    label: {
      fontSize: 10,
      fontWeight: 500,
      color: '#555555',
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    collapseButton: {
      fontSize: '12px',
      lineHeight: '16px',
      color: theme.palette.primary.main,
      textDecoration: 'none',
      borderBottom: `1.5px dotted ${theme.palette.primary.main}`,
      marginTop: 15,
      '&:hover': {
        color: theme.custom.linkColor,
        borderBottom: `1.5px dotted ${theme.custom.linkColor}`,
      },
    },
  };
}
