import React from 'react';

import TableCustom from '../../../../../../components/TableCustom';
import gatewaysTableConfig from './constants';
import useGatewayTable from './hooks';
import GatewaysBulkActions from '../../components/GatewaysBulkActions';
import gatewayTablePropTypes from './propTypes';
import withResponsiveLayout from '../../../../../../hocs/withResponsiveLayout';
import GatewayList from '../GatewayList';

function GatewayTable({
  reFetchGateways,
  loading,
  entities,
  itemCount,
  page,
  handleChangePage,
  perPage,
  handleChangePerPage,
  selectedIds,
  handleSelectId,
  handleSelectAllIds,
}) {
  const {
    handleDeleteGateways,
    isOpen,
    toggleModalState,
  } = useGatewayTable(selectedIds, reFetchGateways);

  return (
    <TableCustom
      loading={loading}
      entities={entities}
      headerConfig={gatewaysTableConfig.header}
      bodyConfig={gatewaysTableConfig.body(reFetchGateways)}
      paginationConfig={{
        itemCount,
        page,
        perPage,
        handleChangePage,
        handleChangePerPage,
      }}
      selectedIds={selectedIds}
      handleSelectId={handleSelectId}
      handleSelectAllIds={handleSelectAllIds}
      handleDeleteEntities={handleDeleteGateways}
      bulkActionElement={(
        <GatewaysBulkActions
          handleDeleteGateways={handleDeleteGateways}
          deleteGatewayCount={selectedIds.length}
          isOpen={isOpen}
          toggleModalState={toggleModalState}
        />
      )}
    />
  );
}

GatewayTable.propTypes = gatewayTablePropTypes;

export default withResponsiveLayout({
  factoryFunction: ({ isMobile }) => isMobile,
  ResponsiveView: (props) => <GatewayList {...props} />,
})(GatewayTable);
