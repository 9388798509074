import React from 'react';
import PropTypes from 'prop-types';

import Section from '../../components/Section';
import RAKInformation from './containers/RAKInformation';
import DisconnectAccount from './containers/DisconnectAccount';
import HeliumWalletFormContainer from './containers/HeliumWalletFormContainer';
import useFetchSaga from '../Hotspots/containers/HotspotsList/useFetchGateways';
import { getGatewaysEntitiesList } from '../../redux/reducers/gateways/selectors';
import { sendApiRequest } from '../../redux/sagas/apiRequests';
import { getGateways } from '../../redux/reducers/gateways/actions';
import Loader from '../../components/Loader';

function ProfilePage({ classes }) {
  const { loading } = useFetchSaga({
    entitiesSelector: getGatewaysEntitiesList,
    fetchSaga: sendApiRequest,
    args: getGateways({ params: { page: 1, limit: 10 } }),
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <Section title="My Profile" subtitle="Manage your WisHM profile.">
      <div className={classes.profileInfo}>
        <div className={classes.sectionItem}>
          <RAKInformation />
        </div>

        <div className={classes.sectionItem}>
          <HeliumWalletFormContainer />
        </div>

        <div className={classes.sectionItem}>
          <DisconnectAccount />
        </div>
      </div>
    </Section>
  );
}

ProfilePage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

ProfilePage.defaultProps = {
  classes: {},
};

export default ProfilePage;
