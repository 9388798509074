import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import errorIcon from '../../assets/icons/error.svg';
import infoIcon from '../../assets/icons/info.svg';

import styles from './styles';

const Notification = ({ classes, data }) => (
  <div className={classes.wrap}>
    <div className={classes.header}>
      <img
        className={classes.icon}
        src={data.type === 'error' ? errorIcon : infoIcon}
        alt="icon"
      />
      <div className={classes.title}>{data.type}</div>
    </div>
    <div className={classes.content}>
      <div className={classes.subTitle}>{data.message}</div>
    </div>
  </div>
);

Notification.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  data: PropTypes.objectOf(PropTypes.string),
};

Notification.defaultProps = {
  classes: {},
  data: {},
};

export default withStyles(styles)(Notification);
