import { useCallback, useMemo, useState } from 'react';

import { runSaga } from '../../../../../../store';
import { sendApiRequest } from '../../../../../../redux/sagas/apiRequests';
import { updateGateways } from '../../../../../../redux/reducers/gateways/actions';

export default function useUpdateGateway(sn) {
  const [isEdit, setEdit] = useState(false);

  const onSubmit = useCallback(async (values) => {
    const { ok } = await runSaga(
      sendApiRequest,
      updateGateways({ sn, data: { ...values } }),
    ).toPromise();

    if (ok) {
      setEdit(false);
    }
  }, [sn]);

  return useMemo(() => ({
    onSubmit,
    isEdit,
    setEdit,
  }), [
    onSubmit,
    isEdit,
    setEdit,
  ]);
}
