export default function styles(theme) {
  return {
    content: {
      display: 'flex',
      justifyContent: 'center',
    },
    formWrapper: {
      marginTop: '40px',
      backgroundColor: '#ffffff',
      width: '410px',
      '@media (max-width: 1023px)': {
        width: '100%',
        marginTop: '10px',
      },
    },
    stepTitle: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      color: theme.custom.textColor.gray,
      fontSize: '11px',
      lineHeight: '16px',
      letterSpacing: '2px',
      marginBottom: '10px',
      textTransform: 'uppercase',
      opacity: 0.5,
    },
    title: {
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '-0.01em',
      marginBottom: '10px',
      '@media (max-width: 1023px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    hintWrap: {
      color: theme.custom.textColor.gray,
      opacity: 0.8,
      fontSize: '14px',
      lineHeight: '20px',
      marginBottom: '30px',
    },
    hint: {
      opacity: 0.5,
    },
    fieldWrap: {
      marginBottom: '24px',
    },
    errorWrap: {
      marginBottom: '25px',
    },
    buttonBlock: {
      display: 'flex',
      margin: '60px 0',
      justifyContent: 'flex-end',
      '@media (max-width: 1023px)': {
        flexDirection: 'column-reverse',
      },
    },
  };
}
