import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as NoticeIcon } from '../../assets/icons/errorCircle.svg';
import { isDelete, isRequired } from '../../utils/validations';
import CustomModal from '../CustomModal';
import TextFormField from '../FormElements/TextField';
import styles from './styles';

function DeleteGatewayModal({
  classes,
  isOpen,
  toggleModalState,
  handleSubmit,
  submitting,
  valid,
  pristine,
  deleteGatewayCount,
}) {
  return (
    <CustomModal
      isOpen={isOpen}
      handleClose={toggleModalState}
      contentClass={classes.modalContent}
      titleId={deleteGatewayCount === 1 ? 'gatewayDeleteModalTitle' : 'gatewaysDeleteModalTitle'}
      submitteText="gatewayDeleteModalBtn"
      cancelText="close"
      isSubmiting={submitting}
      disableSubmitte={!valid || pristine || submitting}
      onSubmite={handleSubmit}
    >
      <div className={classes.noticeWrap}>
        <div className={classes.noticeIcon}>
          <NoticeIcon />
        </div>

        <div className={classes.noticeText}>
          <FormattedMessage
            id="gatewayDeleteModalNotice"
            values={{
              gatewayCount: deleteGatewayCount === 1 ? 'this' : deleteGatewayCount,
              prefix: deleteGatewayCount === 1 ? '' : 's',
            }}
          />
        </div>
      </div>

      <div className={classes.formWrap}>
        <form onSubmit={handleSubmit}>
          <Field
            wrapClassName={classes.fieldWrap}
            name="delete"
            component={TextFormField}
            validate={[isRequired, isDelete]}
            label={<FormattedMessage id="gatewayDeleteModalFieldLabel" />}
            disabled={submitting}
          />
        </form>
      </div>
    </CustomModal>
  );
}

DeleteGatewayModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModalState: PropTypes.func.isRequired,
  deleteGatewayCount: PropTypes.number.isRequired,
};

export default compose(
  reduxForm({}),
  withStyles(styles),
)(DeleteGatewayModal);
