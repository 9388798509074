import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { useLocation } from 'react-router';

import PasswordField from '../../../../components/FormElements/PasswordField';
import CustomButton from '../../../../components/CustomButton';
import {
  isMatchPasswords, isRequired, isValidPassword,
} from '../../../../utils/validations';
import styles from './styles';
import { confirmPassword } from '../../../../redux/reducers/auth/actions';
import ShowPasswordField from '../../../../components/FormElements/ShowPasswordField';
import parseQuery from '../../../../utils/parseQuery';

const ConfirmPasswordForm = ({
  classes, handleSubmit, valid, pristine, loading,
}) => {
  const selector = formValueSelector('forgotPasswordForm');
  const dispatch = useDispatch();
  const password = useSelector((state) => selector(state, 'password'));

  const { search } = useLocation();

  const handleConfirmPassword = useCallback(({ password: newPassword }) => {
    const searchParams = parseQuery(search);
    const { email } = searchParams;
    const { code } = searchParams;
    dispatch(confirmPassword({ email, newPassword, verificationCode: code }));
  }, [dispatch, search]);

  return (
    <div className={classes.wrap}>
      <form onSubmit={handleSubmit(handleConfirmPassword)}>
        <div className={classes.fieldWrap}>
          <Field
            name="password"
            component={PasswordField}
            validate={[isRequired, isValidPassword]}
          />
        </div>

        <div className={classes.fieldWrap}>
          <Field
            name="confirmPassword"
            label={<FormattedMessage id="confirmPassword" />}
            component={ShowPasswordField}
            validate={[isMatchPasswords]}
            password={password}
            withMatch
          />
        </div>

        <div className={classes.buttonBlock}>
          <CustomButton
            type="submit"
            variant="contained"
            color="secondary"
            loading={loading}
            additionalClass={classes.button}
            disabled={!valid || pristine || loading}
          >
            <FormattedMessage id="saveNewPassword" />
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

ConfirmPasswordForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  loading: PropTypes.bool,
  valid: PropTypes.bool,
};

ConfirmPasswordForm.defaultProps = {
  handleSubmit: () => {},
  pristine: false,
  loading: false,
  valid: false,
  classes: {},
};

export default compose(
  withStyles(styles),

  reduxForm({
    form: 'forgotPasswordForm',
    enableReinitialize: true,
  }),
)(ConfirmPasswordForm);
