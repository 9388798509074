import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { formValueSelector } from 'redux-form';
import { withStyles } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import { connect, useDispatch } from 'react-redux';
import cn from 'classnames';
import { Tab, Tabs } from '../../../../../components/Tabs';
import Modal from '../../../../../components/CustomModal';
import { updateHotspotCreationTimer, updateHotspotRelocationTimer } from '../../../../../redux/reducers/hotspots/actions';
import infoIcon from '../../../../../assets/icons/infoIcon.svg';
import CustomButton from '../../../../../components/CustomButton';
import FieldWithCopy from '../../../../../components/FieldWithCopy';
import styles from './styles';
import CustomLink from '../../../../../components/CustomLink';

const AssertLocationFee = ({
  classes,
  qrcode,
  isRelocation,
  prevStep,
  nextStep,
}) => {
  const dispatch = useDispatch();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const openModal = useCallback(() => setIsOpenModal(true), []);
  const closeModal = useCallback(() => setIsOpenModal(false), []);

  const [tab, setValue] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const onConfirmNextStep = useCallback(() => {
    closeModal();

    if (isRelocation) {
      dispatch(updateHotspotRelocationTimer({ isStartRelocation: false }));
    } else {
      dispatch(updateHotspotCreationTimer({ isStartCreation: false }));
    }

    nextStep();
  }, [closeModal, dispatch, isRelocation, nextStep]);

  return (
    <div className={classes.content}>
      <div className={classes.formWrapper}>
        {
          !isRelocation && (<div className={classes.stepTitle}><FormattedMessage id="step" /> 3</div>)
        }
        <div className={classes.title}><FormattedMessage id="assertLocationFee" /></div>
        <div className={classes.description}>
          <FormattedMessage id="choosePreferPayment" />
          <CustomLink>
            <ButtonBase
              href={process.env.REACT_APP_HELIUM_TRANSACTION_FEES}
              target="_blank"
              rel="noopener noreferrer"
              disableRipple
            >
              <FormattedMessage id="assertLocationFee" />
            </ButtonBase>
          </CustomLink>
        </div>

        <div className={classes.tabsWrapper}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            variant="fullWidth"
            className={classes.tabs}
          >
            <Tab label={<FormattedMessage id="helliumApp" />} />
            <Tab label={<FormattedMessage id="helliumCliTool" />} />
          </Tabs>

          {tab === 0 && (
            <div className={classes.tab}>
              <div className={classes.tabContentWrapper}>
                <div className={classes.tabHeader}>
                  <div className={classes.burnWallet}>
                    <span><FormattedMessage id="burn" /></span>
                    <span>{qrcode?.amount} HNT</span>
                  </div>
                </div>
                <div className={classes.tabContent}>
                  <div className={classes.qrCode}>
                    { qrcode && <QRCode value={JSON.stringify(qrcode)} bgColor="#F5F7F9" size={180} /> }
                  </div>
                </div>
              </div>
            </div>
          )}
          {tab === 1 && (
            <div className={classes.tab}>
              <div className={classes.tabContentWrapper}>
                <div className={classes.tabHeader}>
                  <div className={classes.burnWallet}>
                    <span><FormattedMessage id="burn" /></span>
                    <span>{qrcode?.amount} HNT</span>
                  </div>
                </div>
                <div className={classes.tabContent}>
                  <div className={classes.fieldWithCopy}>
                    <FieldWithCopy valueForCopy={qrcode?.address}>
                      <div>
                        <div className={cn(classes.value, classes.text)}>
                          {qrcode?.address}
                        </div>
                        <div className={cn(classes.label, classes.text)}>
                          <FormattedMessage id="addressToBurn" />
                        </div>
                      </div>
                    </FieldWithCopy>
                  </div>
                  <div className={classes.fieldWithCopy}>
                    <FieldWithCopy valueForCopy={qrcode?.memo}>
                      <div>
                        <div className={cn(classes.value, classes.text)}>
                          {qrcode?.memo}
                        </div>
                        <div className={cn(classes.label, classes.text)}>
                          <FormattedMessage id="includeMemo" />
                        </div>
                      </div>
                    </FieldWithCopy>
                  </div>
                  <div className={classes.fieldWithCopy}>
                    <FieldWithCopy valueForCopy={qrcode?.amount}>
                      <div>
                        <div className={cn(classes.value, classes.text)}>
                          {qrcode?.amount}
                        </div>
                        <div className={cn(classes.label, classes.text)}>
                          <FormattedMessage id="amount" />
                        </div>
                      </div>
                    </FieldWithCopy>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={classes.description}>
          <FormattedMessage id="successfullyPaidInfo" />
        </div>

        <Modal
          isOpen={isOpenModal}
          titleId="oneMoreThing"
          submitteText="confirmPayment"
          cancelText="backToPayment"
          contentClass={classes.modalContent}
          onSubmite={onConfirmNextStep}
          handleClose={closeModal}
        >
          <div className={classes.tip}>
            <img className={classes.icon} src={infoIcon} alt="info" />
            <span>
              <FormattedMessage id="payConfirmeInfo" />
            </span>
          </div>
        </Modal>

        <div className={classes.buttonBlock}>
          <ButtonBase
            className={classes.buttonPrev}
            disableRipple
            onClick={prevStep}
          >
            <FormattedMessage id="prevStep" />
          </ButtonBase>
          <CustomButton
            type="button"
            variant="contained"
            color="secondary"
            onClick={openModal}
          >
            <FormattedMessage id="nextStep" />
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

AssertLocationFee.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  qrcode: PropTypes.objectOf(PropTypes.string).isRequired,
  isRelocation: PropTypes.bool,
};

AssertLocationFee.defaultProps = {
  classes: {},
  isRelocation: false,
};

const locationFormQrCodeSelector = formValueSelector('hotspotAddLocationForm');
const relocationFormQrCodeSelector = formValueSelector('hotspotRelocationForm');
export default compose(
  withStyles(styles),
  connect((state, props) => ({
    qrcode: props.isRelocation ? relocationFormQrCodeSelector(state, 'qrcode') : locationFormQrCodeSelector(state, 'qrcode'),
  }), null),
)(AssertLocationFee);
