import { createSelector } from 'reselect';
import { initialRequestState } from './reducer';

const getRequestStatus = createSelector(
  (state, requestName) => (
    state.requestStatus.get(requestName) || initialRequestState
  ),
  (status) => status.toJS(),
);

export default getRequestStatus;
