import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetRequest } from '../redux/reducers/requestStatus/actions';

const useResetRequestStatus = (requestName) => {
  const dispatch = useDispatch();

  const handleResetRequest = useCallback(
    () => dispatch(resetRequest(requestName)),
    [dispatch, requestName],
  );

  useEffect(() => () => handleResetRequest(), [handleResetRequest]);
};

export default useResetRequestStatus;
