export default function checkPasswordStrength(password) {
  let strength = 0;
  const matchLvl = {};
  if (password.length >= 8) {
    strength += 1;
    matchLvl.length = true;
  }
  if (password.match(/[a-zA-Z]+/)) {
    strength += 1;
    matchLvl.letter = true;
  }
  if (password.match(/[0-9]+/)) {
    strength += 1;
    matchLvl.number = true;
  }
  if (password.match(/[\^$*.{}\\)?\-"!@#%&/,><':;|_~`]+/)) {
    strength += 1;
    matchLvl.symbol = true;
  }
  return { strength, matchLvl };
}
