import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';

import { ReactComponent as EditIcon } from '../../../../../../assets/icons/edit.svg';
import gatewayDetailsPropTypes from './propTypes';
import GatewayNameForm from '../../components/GatewayNameForm';
import useUpdateGateway from './useUpdateGateway';

function GatewayNameInfo({
  classes,
  gatewayDetails,
}) {
  const {
    isEdit,
    setEdit,
    onSubmit,
  } = useUpdateGateway(gatewayDetails.sn);

  return (
    <div className={classes.root}>
      {
        isEdit
          ? (
            <GatewayNameForm
              form="GATEWAY_NAME_FORM"
              initialValues={{ name: gatewayDetails.name }}
              onSubmit={onSubmit}
              handleEditState={setEdit}
            />
          )
          : (
            <>
              {gatewayDetails.name}

              <ButtonBase
                className={classes.editIcon}
                onClick={() => setEdit(true)}
              >
                <EditIcon />
              </ButtonBase>
            </>
          )
      }
    </div>
  );
}

GatewayNameInfo.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  gatewayDetails: gatewayDetailsPropTypes.isRequired,
};

export default compose(withStyles(() => ({
  root: {
    height: '48px',
    display: 'flex',
    alignItems: 'center',
  },
  editIcon: {
    marginLeft: 15,
  },
})))(GatewayNameInfo);
