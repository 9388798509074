import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import Duration from 'luxon/src/duration';
import ReactMapGL, { Marker } from 'react-map-gl';

import MarkerIcon from '../../../../../../components/Marker';
import GatewayInfoField from '../../components/GatewayInfoField';
import gatewayDetailsPropTypes from './propTypes';

function GatewayLocationInfo({
  classes,
  gatewayDetails,
}) {
  return (
    <div>
      <div className={classes.root}>

        <div>
          <GatewayInfoField
            label="uptime"
            value={
              Duration
                .fromObject({ minutes: gatewayDetails.uptimeInMinutes })
                .toFormat("d'd' h'h' m'm'")
            }
          />
          <GatewayInfoField label="latitude" value={gatewayDetails.latitude} />
        </div>

        <div>
          <GatewayInfoField
            label="firmware"
            value={gatewayDetails.reportedFw}
            withUpdate={gatewayDetails.isUpdateAvailable}

          />
          <GatewayInfoField label="longitude" value={gatewayDetails.longitude} />
        </div>
      </div>

      {
        (gatewayDetails.latitude && gatewayDetails.longitude)
          ? (
            <div className={classes.mapWrap}>
              <ReactMapGL
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                {...{
                  width: '100%',
                  height: 200,
                  latitude: gatewayDetails.latitude,
                  longitude: gatewayDetails.longitude,
                  zoom: 12,
                }}
              >
                <Marker
                  offsetTop={-40}
                  offsetLeft={-10}
                  latitude={gatewayDetails.latitude}
                  longitude={gatewayDetails.longitude}
                >
                  <MarkerIcon />
                </Marker>
              </ReactMapGL>
            </div>
          )
          : null
      }
    </div>
  );
}

GatewayLocationInfo.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  gatewayDetails: gatewayDetailsPropTypes.isRequired,
};

export default compose(withStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',

    '& > div': {
      width: '49%',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    '& > div > div:not(:last-child)': {
      marginBottom: 30,
    },

    [theme.breakpoints.down('sm')]: {
      display: 'block',

      '& > div > div': {
        marginBottom: 30,
      },
    },
  },
  mapWrap: {
    marginTop: 30,
  },
})))(GatewayLocationInfo);
