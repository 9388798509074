import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';

import GatewayInfoField from '../../components/GatewayInfoField';
import gatewayDetailsPropTypes from './propTypes';

function GatewayAddressInfo({
  classes,
  gatewayDetails,
}) {
  return (
    <div>
      <div className={classes.root}>
        <div>
          <GatewayInfoField withCopy label="uri" value={gatewayDetails.LNSURI} />
          <GatewayInfoField withCopy label="accessKey" value={gatewayDetails.LNSPort} />
        </div>

        <div>
          <GatewayInfoField withCopy label="port" value={gatewayDetails.accessKey} />
        </div>
      </div>

      <GatewayInfoField
        withCopy
        withCollapse
        label="certificate"
        value={gatewayDetails.certificate}
      />
    </div>
  );
}

GatewayAddressInfo.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  gatewayDetails: gatewayDetailsPropTypes.isRequired,
};

export default compose(withStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',

    '& > div': {
      width: '49%',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    '& > div > div': {
      marginBottom: 30,
    },

    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
})))(GatewayAddressInfo);
