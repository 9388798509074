import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core';

function TableBodyCustom({
  classes,
  bodyConfig,
  entities,
  selectedIds,
  handleSelectId,
}) {
  return entities.map((entity) => (
    <TableRow
      key={entity.id}
      hover
      className={classes.tableRow}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={selectedIds.includes(entity.id)}
          onClick={() => handleSelectId(entity.id)}
        />
      </TableCell>
      {
        bodyConfig.map((config) => (
          <TableCell key={`${entity.id}_${config.id}`} align="left">
            {
              config.mutation
                ? config.mutation(entity[config.id], entity)
                : entity[config.id]
            }
          </TableCell>
        ))
      }
    </TableRow>
  ));
}

TableBodyCustom.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
  )).isRequired,
  bodyConfig: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.func,
      ]),
    ),
  ).isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSelectId: PropTypes.func.isRequired,
};

const styles = () => ({
  tableRow: {
    '&:hover': {
      backgroundColor: 'rgba(245, 247, 249, 0.5) !important',
    },
  },
});

export default withStyles(styles)(TableBodyCustom);
