import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';

function NavigationTab({
  classes,
  location: { pathname },
  history: { push },
  linkRedirect,
  isActive,
  label,
}) {
  return (
    <ButtonBase onClick={() => push(linkRedirect)}>
      <div
        className={
          cn(
            classes.navigationTab,
            { [classes.isActive]: isActive(pathname) },
          )
        }
      >
        {label}
      </div>
    </ButtonBase>
  );
}

NavigationTab.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  linkRedirect: PropTypes.string.isRequired,
  isActive: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default NavigationTab;
