import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import AddHeliumWalletForm from '../HotspotsForms/AddHeliumWallet';
import NewHotspotForm from '../HotspotsForms/NewHotspot';
import { getUserProfile } from '../../../../redux/reducers/userProfile/selectors';
import styles from './styles';

const HotspotsCreate = ({ classes }) => {
  // TODO: add check if we have user profile (mb some loading state) -
  // for prevent display add helium wallet(when profile is loading or refresh page)
  const userProfile = useSelector(getUserProfile);

  return (
    <div className={classes.wrap}>
      { userProfile?.heliumWalletAddress ? <NewHotspotForm /> : <AddHeliumWalletForm /> }
    </div>
  );
};

HotspotsCreate.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

HotspotsCreate.defaultProps = {
  classes: {},
};

export default withStyles(styles)(HotspotsCreate);
