import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import UnAuthorizeLayout from '../../layouts/UnAuthorizeLayout';
import ROUTES from '../../contants/routes';
import StyledToast from '../../components/Notification/StyledToast';
import { getIsLoggedInState } from '../../redux/reducers/auth/selectors';

function UnauthorizedRoute({
  image, mainText, subText, ...props
}) {
  const isLoggedIn = useSelector(getIsLoggedInState);

  if (isLoggedIn) return <Redirect to={ROUTES.HOTSPOTS.createPath({ path: '' })} />;

  return (
    <UnAuthorizeLayout image={image} mainText={mainText} subText={subText}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Route {...props} />
      <StyledToast />
    </UnAuthorizeLayout>
  );
}

UnauthorizedRoute.propTypes = {
  image: PropTypes.string,
  subText: PropTypes.string,
  mainText: PropTypes.string,
};

UnauthorizedRoute.defaultProps = {
  image: '',
  subText: '',
  mainText: '',
};

export default UnauthorizedRoute;
