import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Field } from 'redux-form';
import cn from 'classnames';

import TextFormField from '../../../../../../components/FormElements/TextField';
import {
  isRequired,
  isValidGatewayName,
  isValidGatewayNameLength,
} from '../../../../../../utils/validations';
import { ReactComponent as SaveIcon } from '../../../../../../assets/icons/checkBlue.svg';
import closeIcon from '../../../../../../assets/icons/closeSmall.svg';

function GatewayNameForm({
  classes,
  handleEditState,
  handleSubmit,
  submitting,
  valid,
  pristine,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.wrap}>
        <div>
          <Field
            name="name"
            component={TextFormField}
            wrapClassName={classes.field}
            validate={[isRequired, isValidGatewayName, isValidGatewayNameLength]}
            disabled={submitting}
          />
        </div>

        <div className={cn(classes.buttonBlock, { [classes.error]: !valid && pristine })}>
          <ButtonBase
            type="submit"
            className={classes.editButton}
            disableRipple
            disabled={!valid || pristine || submitting}
          >
            {submitting ? (
              <CircularProgress
                className={classes.loader}
                size={24}
              />
            ) : <SaveIcon />}
          </ButtonBase>
          <ButtonBase
            className={classes.close}
            onClick={() => handleEditState(false)}
            disableRipple
          >
            <img src={closeIcon} alt="edit" />
          </ButtonBase>
        </div>
      </div>
    </form>
  );
}

GatewayNameForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleEditState: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

export default GatewayNameForm;
