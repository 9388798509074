import { Map, fromJS } from 'immutable';

import * as actions from './actions';

const initialState = Map();

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.GET_ORGANIZATION_BY_SUB_DOMAIN_SUCCESS:
    case actions.UPDATE_ORGANIZATION_SUCCESS:
    case actions.GET_ORGANIZATION_SUCCESS: {
      return fromJS(payload);
    }
    default:
      return state;
  }
};
