import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router';

import View from './View';
import styles from './styles';

export default compose(
  withRouter,

  withStyles(styles),
)(View);
