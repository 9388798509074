export default function styles(theme) {
  return {
    link: {
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',

      '& > svg': {
        marginLeft: 10,
        fill: 'currentColor',
      },
    },
    buttonInfo: {
      background: 'none',
      border: `1px solid ${theme.custom.textColor.blue}`,

      '& > .MuiButton-label > $link': {
        color: theme.custom.textColor.blue,
      },

      '&:hover': {
        background: theme.custom.textColor.blue,
        border: `1px solid ${theme.custom.textColor.blue}`,
        boxShadow: `0px 0px 1px 1px ${theme.custom.textColor.blue}`,

        '& > .MuiButton-label > $link': {
          color: theme.custom.textColor.primary,
        },
      },
    },
    avatarWrap: {
      marginBottom: '20px',
    },
    multiInfoField: {
      width: '100%',
      maxWidth: '422px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    countryValue: {
      display: 'flex',
      alignItems: 'center',

      '& > img': {
        marginRight: '12px',
      },
    },
  };
}
