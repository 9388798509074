import { API_REQUEST } from '../api/actions';
import { API_ENDPOINTS } from '../../../contants/api';

export const GET_GATEWAY_BY_ID_START = 'GET_GATEWAY_BY_ID_START';
export const GET_GATEWAY_BY_ID_ERROR = 'GET_GATEWAY_BY_ID_ERROR';
export const GET_GATEWAY_BY_ID_SUCCESS = 'GET_GATEWAY_BY_ID_SUCCESS';

export const GET_GATEWAY_BY_ORG_START = 'GET_GATEWAY_BY_ORG_START';
export const GET_GATEWAY_BY_ORG_ERROR = 'GET_GATEWAY_BY_ORG_ERROR';
export const GET_GATEWAY_BY_ORG_SUCCESS = 'GET_GATEWAY_BY_ORG_SUCCESS';

export const ADD_GATEWAY_START = 'ADD_GATEWAY_START';
export const ADD_GATEWAY__ERROR = 'ADD_GATEWAY__ERROR';
export const ADD_GATEWAY__SUCCESS = 'ADD_GATEWAY__SUCCESS';

export const RENAME_GATEWAY_START = 'RENAME_GATEWAY_START';
export const RENAME_GATEWAY__ERROR = 'RENAME_GATEWAY__ERROR';
export const RENAME_GATEWAY__SUCCESS = 'RENAME_GATEWAY__SUCCESS';

export const UNASSIGN_GATEWAY_START = 'UNASSIGN_GATEWAY_START';
export const UNASSIGN_GATEWAY__ERROR = 'UNASSIGN_GATEWAY__ERROR';
export const UNASSIGN_GATEWAY__SUCCESS = 'UNASSIGN_GATEWAY__SUCCESS';

export const GET_SSH_CREDENTIALS_START = 'GET_SSH_CREDENTIALS_START';
export const GET_SSH_CREDENTIALS_ERROR = 'GET_SSH_CREDENTIALS_ERROR';
export const GET_SSH_CREDENTIALS_SUCCESS = 'GET_SSH_CREDENTIALS_SUCCESS';

export const CLEAR_GATEWAY_DATA = 'CLEAR_GATEWAY_DATA';

export const getSshCredentials = (payload) => {
  const { organizationId, locationId, id } = payload;

  return {
    type: API_REQUEST,
    payload: {
      url: `${API_ENDPOINTS.ORGANIZATIONS._}/${organizationId}${API_ENDPOINTS.LOCATIONS._}/${locationId}${API_ENDPOINTS.GATEWAYS._}/${id}${API_ENDPOINTS.GATEWAYS.ssh}`,
      method: 'get',
    },
    dispatch: {
      START: GET_SSH_CREDENTIALS_START,
      SUCCESS: GET_SSH_CREDENTIALS_SUCCESS,
      ERROR: GET_SSH_CREDENTIALS_ERROR,
    },
  };
};

export const clearGatewayData = (payload) => ({
  type: CLEAR_GATEWAY_DATA,
  payload,
});

export const unassignGateway = (payload) => {
  const { organizationId, locationId, id } = payload;

  return {
    type: API_REQUEST,
    payload: {
      url: `${API_ENDPOINTS.ORGANIZATIONS._}/${organizationId}${API_ENDPOINTS.LOCATIONS._}/${locationId}${API_ENDPOINTS.GATEWAYS._}/${id}${API_ENDPOINTS.GATEWAYS.unassign}`,
      method: 'post',
    },
    dispatch: {
      START: UNASSIGN_GATEWAY_START,
      SUCCESS: UNASSIGN_GATEWAY__SUCCESS,
      ERROR: UNASSIGN_GATEWAY__ERROR,
    },
  };
};

export const renameGateway = (payload) => {
  const {
    organizationId, locationId, id, data,
  } = payload;

  return {
    type: API_REQUEST,
    payload: {
      url: `${API_ENDPOINTS.ORGANIZATIONS._}/${organizationId}${API_ENDPOINTS.LOCATIONS._}/${locationId}${API_ENDPOINTS.GATEWAYS._}/${id}`,
      method: 'put',
      data,
    },
    dispatch: {
      START: RENAME_GATEWAY_START,
      SUCCESS: RENAME_GATEWAY__SUCCESS,
      ERROR: RENAME_GATEWAY__ERROR,
    },
  };
};

export const getGatewayById = (payload) => {
  const { organizationId, locationId, id } = payload;

  return {
    type: API_REQUEST,
    payload: {
      url: `${API_ENDPOINTS.ORGANIZATIONS._}/${organizationId}${API_ENDPOINTS.LOCATIONS._}/${locationId}${API_ENDPOINTS.GATEWAYS._}/${id}`,
      method: 'get',
    },
    dispatch: {
      START: GET_GATEWAY_BY_ID_START,
      SUCCESS: GET_GATEWAY_BY_ID_SUCCESS,
      ERROR: GET_GATEWAY_BY_ID_ERROR,
    },
  };
};

export const getGatewayByOrg = (payload) => {
  const { organizationId, id } = payload;

  return {
    type: API_REQUEST,
    payload: {
      url: `${API_ENDPOINTS.ORGANIZATIONS._}/${organizationId}${API_ENDPOINTS.GATEWAYS._}/${id}`,
      method: 'get',
    },
    dispatch: {
      START: GET_GATEWAY_BY_ORG_START,
      SUCCESS: GET_GATEWAY_BY_ORG_SUCCESS,
      ERROR: GET_GATEWAY_BY_ORG_ERROR,
    },
  };
};

export const addGateway = (payload) => {
  const { organizationId, locationId, data } = payload;

  return {
    type: API_REQUEST,
    payload: {
      url: `${API_ENDPOINTS.ORGANIZATIONS._}/${organizationId}${API_ENDPOINTS.LOCATIONS._}/${locationId}${API_ENDPOINTS.GATEWAYS._}`,
      method: 'post',
      data,
    },
    dispatch: {
      START: ADD_GATEWAY_START,
      SUCCESS: ADD_GATEWAY__SUCCESS,
      ERROR: ADD_GATEWAY__ERROR,
    },
  };
};
