import { createMuiTheme } from '@material-ui/core';

const customTheme = {
  palette: {
    primary: {
      main: '#5D16A6',
      dark: 'rgba(93, 22, 166, 0.5)',
      light: 'rgba(93, 22, 166, 0.2)',
      info: 'rgba(22, 105, 229, 0.1)',
    },
    secondary: {
      main: '#F7B801',
      dark: '#bf8900',
      light: '#ffea4d',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  custom: {
    error: '#F13456',
    errorLight: 'rgba(241, 52, 86, 0.1)',
    success: 'rgba(58, 214, 117, 0.1)',
    textColor: {
      primary: '#FFFFFF',
      secondary: '#289F5A',
      gray: '#555555',
      grayLight: 'rgba(85, 85, 85, 0.4)',
      black: '#000000',
      light: 'rgba(255, 255, 255, 0.5)',
      blue: '#1669E5',
      green: '#289F5A',
    },
    inputBorderColor: '#DEDEDE',
    tooltipColor: 'rgba(0, 0, 0, 0.85)',
    whiteLight: 'rgba(255, 255, 255, 0.2)',
    orange: '#F19800',
    backgroundColor: '#F5F7F9',
    linkColor: 'rgba(93, 22, 166, 0.7)',
    toast: {
      success: '#289F5A',
      info: '#1669E5',
    },
  },
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: customTheme.palette.primary.main,
      dark: customTheme.palette.primary.dark,
      light: customTheme.palette.primary.light,
      info: customTheme.palette.primary.info,
    },
    secondary: {
      main: customTheme.palette.secondary.main,
      light: customTheme.palette.secondary.light,
      dark: customTheme.palette.secondary.dark,
    },
  },
  breakpoints: customTheme.breakpoints,
  custom: {
    error: customTheme.custom.error,
    errorLight: customTheme.custom.errorLight,
    success: customTheme.custom.success,
    orange: customTheme.custom.orange,
    textColor: customTheme.custom.textColor,
    inputBorderColor: customTheme.custom.inputBorderColor,
    tooltipColor: customTheme.custom.tooltipColor,
    backgroundColor: customTheme.custom.backgroundColor,
    whiteLight: customTheme.custom.whiteLight,
    linkColor: customTheme.custom.linkColor,
    toast: customTheme.custom.toast,
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        padding: '16px 24px',
        fontSize: 12,
        color: '#000',
      },
    },
  },
});

export default theme;
