export default function styles() {
  return {
    wrap: {
      width: '100%',
      height: 'calc(100vh - 310px)',
      minHeight: '400px',
      fontFamily: 'Inter',
      '@media (max-width: 1023px)': {
        height: '100%',
      },
    },
    navigations: {
      position: 'absolute',
      top: 200,
      right: 10,
      padding: '10px',
    },
  };
}
