export default function styles(theme) {
  return {
    wrap: {
      textAlign: 'center',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: '5% auto',
    },
    image: {
      marginBottom: '50px',
    },
    text: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.custom.textColor.gray,
      opacity: 0.8,
    },
    titleLink: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette.primary.main,
      textDecoration: 'none',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      borderBottomStyle: 'dotted',
      marginBottom: '1px',
      transition: 'all .2s',
      '&:hover': {
        color: theme.custom.linkColor,
        borderBottom: `2px dotted ${theme.custom.linkColor}`,
      },
    },
  };
}
