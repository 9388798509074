import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { checkToken } from '../redux/reducers/auth/actions';
import { getApiVersion } from '../redux/reducers/api/actions';

const mapDispatchToProps = (dispatch) => ({
  handleCheckToken: (payload) => dispatch(checkToken(payload)),
  handleGetApiVersion: (payload) => dispatch(getApiVersion(payload)),
});

export default function RootElement(WrappedComponent) {
  return compose(
    connect(null, mapDispatchToProps),

    withRouter,

    lifecycle({
      componentDidMount() {
        const {
          handleGetApiVersion,
          handleCheckToken,
        } = this.props;

        handleGetApiVersion();
        handleCheckToken();
      },
    }),
  )(WrappedComponent);
}
