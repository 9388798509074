import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import cn from 'classnames';

import styles from './styles';

const PasswordStrengthBar = ({
  classes,
  strength,
}) => (
  <div className={classes.wrap}>
    <div
      className={cn(classes.rect,
        {
          [classes.low]: strength === 1,
          [classes.medium]: strength >= 2,
          [classes.high]: strength >= 4,
        })}
    >
        &nbsp;
    </div>
    <div
      className={cn(classes.rect,
        {
          [classes.medium]: strength >= 2,
          [classes.high]: strength >= 4,
        })}
    >
        &nbsp;
    </div>
    <div
      className={cn(classes.rect,
        {
          [classes.medium]: strength === 3,
          [classes.high]: strength >= 4,
        })}
    >
        &nbsp;
    </div>
    <div className={cn(classes.rect, { [classes.high]: strength >= 4 })}>&nbsp;</div>
    <div className={cn(classes.rect, { [classes.high]: strength >= 4 })}>&nbsp;</div>
  </div>
);

PasswordStrengthBar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  strength: PropTypes.number,
};

PasswordStrengthBar.defaultProps = {
  classes: {},
  strength: 0,
};

export default withStyles(styles)(PasswordStrengthBar);
