import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import Loader from './index';

function LoaderLayout({ classes }) {
  return (
    <div className={classes.root}>
      <Loader />
    </div>
  );
}

LoaderLayout.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(() => ({
  root: { height: '100%' },
}))(LoaderLayout);
