import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

function CustomTableCell({ classes, children }) {
  return (
    <div className={classes.root}>{children}</div>
  );
}

CustomTableCell.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 12px',
    maxWidth: '150px',
    background: theme.custom.backgroundColor,
    borderRadius: '16px',
    fontSize: '12px',
    letterSpacing: '2px',
    textTransform: 'uppercase',
  },
}))(CustomTableCell);
