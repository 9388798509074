import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';

import EmptyPageState from '../../../../components/EmptyPageState';
import Section from '../../../../components/Section';
import CustomButton from '../../../../components/CustomButton';
import Loader from '../../../../components/Loader';
import ROUTES from '../../../../contants/routes';
import useMobileScreenDetect from '../../../../hooks/useMobileScreenDetect';
import entitiesFallback from '../../../../contants/entities';
import GatewayTable from './containers/GatewayTable';
import useFetchGateways from './useFetchGateways';
import styles from './styles';

const Hotspots = ({ classes }) => {
  const {
    reFetchGateways,
    isFirstLoading,
    loading,
    entities,
    itemCount,
    page,
    handleChangePage,
    perPage,
    handleChangePerPage,
    selectedIds,
    handleSelectId,
    handleSelectAllIds,
  } = useFetchGateways();

  const { isMobile } = useMobileScreenDetect();

  const { push } = useHistory();

  if (isFirstLoading && loading) {
    return <Loader />;
  }

  return (
    <Section
      title={<FormattedMessage id="hotspots" />}
      actionElement={(
        <CustomButton
          additionalClass={classes.button}
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => push(ROUTES.HOTSPOTS.createPath({ path: 'add' }))}
        >
          <FormattedMessage id="addHotspot" />
        </CustomButton>
      )}
    >
      {
        entities.length
          ? (
            <GatewayTable
              isMobile={isMobile}
              reFetchGateways={reFetchGateways}
              loading={loading}
              entities={entities}
              itemCount={itemCount}
              page={page}
              handleChangePage={handleChangePage}
              perPage={perPage}
              handleChangePerPage={handleChangePerPage}
              selectedIds={selectedIds}
              handleSelectId={handleSelectId}
              handleSelectAllIds={handleSelectAllIds}
            />
          )
          : (
            <div className={classes.emptyStateWrap}>
              <EmptyPageState
                image={entitiesFallback.hotspots.emptyStateImage}
                emptyStateText="emptyStateSecondText"
                emptyStateLink="emptyStateSecondLink"
                addTitle="emptyStateSecondAdd"
                entity={entitiesFallback.hotspots.name}
                handleAddNew={() => push(ROUTES.HOTSPOTS.createPath({ path: 'add' }))}
                withoutRedirect
              />
            </div>
          )
      }
    </Section>
  );
};

Hotspots.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

Hotspots.defaultProps = {
  classes: {},
};

export default compose(
  withStyles(styles),
)(Hotspots);
