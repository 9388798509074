import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';

import FieldTooltip from '../FieldTooltip';

import styles from './styles';
import tooltipIcon from '../../../assets/icons/tooltip.svg';

const FieldWrap = ({
  classes,
  children,
  label,
  touched,
  error,
  description,
  tooltipId,
}) => (
  <div>
    <div className={classes.labelWrap}>
      <span
        className={cn(classes.label, {
          [classes.error]: error && touched,
        })}
      >
        {label}
      </span>
      {tooltipId && (
      <div>
        <FieldTooltip tooltipId={tooltipId}>
          <img src={tooltipIcon} alt="icon" />
        </FieldTooltip>
      </div>
      )}
    </div>

    {children}


    {!(error && touched) && !!description ? (
      <FormHelperText
        className={classes.label}
      >
        {description}
      </FormHelperText>
    ) : null}

    {error && touched && (
    <FormHelperText
      className={cn(classes.label, {
        [classes.error]: error && touched,
      })}
      error
    >
      {error}
    </FormHelperText>
    )}
  </div>
);

FieldWrap.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  description: PropTypes.node,
  error: PropTypes.string,
  touched: PropTypes.bool,
  children: PropTypes.node.isRequired,
  label: PropTypes.node,
  tooltipId: PropTypes.string,
};

FieldWrap.defaultProps = {
  classes: null,
  touched: false,
  description: '',
  error: '',
  label: null,
  tooltipId: '',
};

export default withStyles(styles)(FieldWrap);
