import PhoneNumber from 'awesome-phonenumber';
import isEmpty from 'lodash/isEmpty';

export const isRequired = (value) => {
  if (typeof value === 'object') {
    return !isEmpty(value) ? undefined : 'This field cannot be empty.';
  }
  return (value ? undefined : 'This field cannot be empty.');
};

export const isRequiredHeliumAddress = (value) => (
  value
    ? undefined
    : 'This field is required. Please enter your Helium Wallet Address.'
);

export const isDisconnect = (value) => {
  if (!value) {
    return undefined;
  }

  return /DISCONNECT/gm.test(value) ? undefined : "Please type 'DISCONNECT'";
};

export const isDelete = (value) => {
  if (!value) {
    return undefined;
  }

  return /DELETE/gm.test(value) ? undefined : "Please type 'DELETE'";
};

export const isRelocate = (value) => {
  if (!value) {
    return undefined;
  }

  return /RELOCATE/gm.test(value) ? undefined : "Please type 'RELOCATE'";
};

export const isMatchPasswords = (value, allValues) => (value !== allValues.password ? "Passwords don't match" : undefined);

export const isMinMaxLength = (minLength, maxLength) => (value) => {
  if (!value) return undefined;

  if (value.length < minLength) {
    return `This field should be at least ${minLength} characters long.`;
  }
  if (value.length > maxLength) {
    return `This field should not be longer than ${maxLength}.`;
  }
  return undefined;
};

export const isMinMaxNumberValue = (minValue, maxValue) => (value) => {
  if (!value) return undefined;

  if (!/^[0-9]*$/i.test(value)) {
    return 'Invalid characters. Please check the input.';
  }

  if (value < minValue) {
    return `This value must be ${minValue} or more.`;
  }
  if (value > maxValue) {
    return `This value cannot be more than ${maxValue}.`;
  }
  return undefined;
};

export const isValidEmail = (value) => {
  if (!value) return undefined;

  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
    ? 'Invalid characters. Please check the input.'
    : undefined;
};

export const isValidName = (value) => {
  if (!value) return undefined;

  return !/^[a-zA-Z'.\-\s]{4,50}$/i.test(value)
    ? 'Invalid characters. Please check the input.'
    : undefined;
};

export const isValidGatewayName = (value) => {
  if (!value) return undefined;

  return !/^[a-zA-Z0-9'.\-\s]{4,50}$/i.test(value)
    ? 'Invalid characters. Please check the input.'
    : undefined;
};

export const isValidSubdomain = (value) => {
  if (!value) return undefined;

  return !/^[A-Za-z0-9*-]{4,50}$/i.test(value)
    ? 'Invalid characters. Please check the input.'
    : undefined;
};

export const isValidRAKID = (value) => {
  if (!value) return undefined;

  return !/^[\p{Alphabetic}'.\-\s]{1,50}$/iu.test(value)
    ? 'Invalid characters. Please check the input.'
    : undefined;
};

export const isValidSerialNumber = (value) => {
  if (!value) return undefined;

  if (value.length !== 16) {
    return 'This field should be 16 characters long.';
  }

  return !/^[a-zA-Z0-9]{16}$/i.test(value)
    ? 'Invalid characters. Please check the input.'
    : undefined;
};

export const isValidPhone = (value) => {
  if (typeof value === 'object') return undefined;

  if (!value) return undefined;

  return !/^[+]?[0-9]{10,13}$/i.test(value)
    ? 'Please enter a valid phone number.'
    : undefined;
};

export const isRequiredPhone = (value, ...data) => {
  const fieldName = data[2];
  if (fieldName === 'country') return undefined;

  return value ? undefined : 'This field cannot be empty.';
};

export const isValidPhoneByCountry = (value) => {
  if (typeof value === 'object') return undefined;

  if (!value) return undefined;

  const pn = new PhoneNumber(value);

  return !pn.isValid()
    ? 'Invalid characters. Please check the input.'
    : undefined;
};

export const isValidPassword = (value) => {
  if (!value) return undefined;

  return !/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\^$*.{}\\)?\-"!@#%&/,><':;|_~`])\S{8,127}$/i.test(
    value,
  )
    ? 'Invalid characters. Please check the input.'
    : undefined;
};

export const isValidZipCode = (value) => {
  if (!value) return undefined;

  return !/^[0-9]{4,6}$/i.test(value)
    ? 'Invalid characters. Please check the input.'
    : undefined;
};

export const isValidServerUrl = (value) => {
  if (!value) return undefined;

  return !/^(https|http|ws|wss)?:\/\/[-a-zA-Z0-9_.-~!*'();:@&=+$,?#/%]{1,256}$/i.test(value)
    ? 'Invalid characters. Please check the input.'
    : undefined;
};

export const isValidServerAddress = (value) => {
  if (!value) return undefined;

  return !/^(?=.*[^.]$)((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]|[a-zA-Z0-9-]{1,63}?)\.?){4}$/i.test(value)
    ? 'Please provide a valid hostname or IP address.'
    : undefined;
};

export const isValidText = (value) => {
  if (!value) return undefined;

  return !/^[a-zA-z0-9]*$/i.test(value)
    ? 'Invalid characters. Please check the input.'
    : undefined;
};

export const isValidAuthFieldLength = isMinMaxLength(1, 255);

export const isValidClientIdLength = isMinMaxLength(1, 255);

export const isValidServerUrlLength = isMinMaxLength(1, 255);

export const isValidServerAddressLength = isMinMaxLength(1, 253);

export const isValidNameLength = isMinMaxLength(1, 50);

export const isValidOrgNameLength = isMinMaxLength(4, 50);

export const isValidGatewayNameLength = isMinMaxLength(4, 50);

export const isValidSubdomainLength = isMinMaxLength(4, 50);

export const isValidAddressLength = isMinMaxLength(1, 255);

export const isValidCityStateLength = isMinMaxLength(1, 64);

export const isValidZipCodeLength = isMinMaxLength(2, 10);

export const isValidServerPort = isMinMaxNumberValue(1, 6535);

export const isValidStatisticInterval = isMinMaxNumberValue(5, 1000);

export const isValidPushTimeOut = isMinMaxNumberValue(50, 500);

export const isValidKeepalive = isMinMaxNumberValue(5, 300);

export const isValidLoRaKeepalive = isMinMaxNumberValue(10, 300);

export const isValidAutoRestart = isMinMaxNumberValue(5, 60);

export const isValidLocationsFieldLength = isMinMaxLength(1, 2000);

export const isValidSupportMessageFieldLength = isMinMaxLength(10, 1000);
