import { createSelector } from 'reselect';

export const getHotspotsState = (state) => state.hotspots;

export const getHotspotsCreationTimer = createSelector(
  [getHotspotsState],
  (hotspots) => hotspots.get('isStartCreation', false),
);

export const getHotspotsRelocationTimer = createSelector(
  [getHotspotsState],
  (hotspots) => hotspots.get('isStartRelocation', false),
);
