import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core';

import ROUTES from '../../contants/routes';
import RegistrationForm from './containers/RegistrationForm';
import getRequestStatus from '../../redux/reducers/requestStatus/selectors';
import FormInfoBlock from '../../components/FormInfoBlock';
import CustomLink from '../../components/CustomLink';
import styles from './styles';

const Registration = ({ classes }) => {
  const signUpStatus = useSelector((state) => getRequestStatus(state, 'authSignUp'));

  const getErrorMessage = () => {
    const { errorData: error = {} } = signUpStatus;

    if (!error) return null;

    const { errorCode = '', message = '' } = error;

    return errorCode === 'UsernameExistsException'
      ? (
        <div className={classes.errorWrap}>
          <FormInfoBlock
            error={{
              errorCode,
              message: (
                <span>
                  <FormattedMessage id="registrationPageCustomError1" />
                  <CustomLink>
                    <NavLink to={ROUTES.LOGIN.INDEX}>
                      <FormattedMessage id="registrationPageCustomError2" />
                    </NavLink>
                  </CustomLink>
                  <FormattedMessage id="registrationPageCustomError3" />
                  <CustomLink>
                    <NavLink to={ROUTES.FORGOT_PASSWORD.INDEX}>
                      <FormattedMessage id="registrationPageCustomError4" />
                    </NavLink>
                  </CustomLink>
                </span>
              ),
            }}
          />
        </div>
      )
      : (
        <div className={classes.errorWrap}>
          <FormInfoBlock error={{ errorCode, message }} />
        </div>
      );
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.titleWrap}>
        <div className={classes.title}><FormattedMessage id="registrationTitle" /></div>
        <div>
          <span className={classes.subTitle}><FormattedMessage id="haveAccount" /></span>
          <CustomLink>
            <NavLink to={ROUTES.LOGIN.INDEX}>
              <FormattedMessage id="signIn" />
            </NavLink>
          </CustomLink>
        </div>
        {getErrorMessage()}
      </div>
      <RegistrationForm loading={signUpStatus.loading} />
    </div>
  );
};

Registration.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

Registration.defaultProps = {
  classes: {},
};

export default withStyles(styles)(Registration);
