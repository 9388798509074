import React from 'react';
import { branch, compose, renderComponent } from 'recompose';
import { withRouter } from 'react-router';

import HotspotsList from '../HotspotsList';
import HotspotsCreate from '../HotspotsCreate';
import HotspotsRelocate from '../HotspotsForms/RelocateHotspot';

export default compose(
  withRouter,

  branch(
    ({ path }) => path && path === 'add',
    renderComponent(() => (
      <HotspotsCreate />
    )),
  ),

  branch(
    ({ path, sn }) => path && sn && path === 'relocate',
    renderComponent(() => (
      <HotspotsRelocate />
    )),
  ),
)(HotspotsList);
