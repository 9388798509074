import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import ButtonBase from '@material-ui/core/ButtonBase';
import CustomButton from '../CustomButton';
import { contactSupport } from '../../redux/reducers/support/actions';
import getRequestStatus from '../../redux/reducers/requestStatus/selectors';
import { getIsLoggedInState } from '../../redux/reducers/auth/selectors';
import { getUserProfile } from '../../redux/reducers/userProfile/selectors';
import { showNotification } from '../../redux/reducers/notifications/actions';
import { resetRequest } from '../../redux/reducers/requestStatus/actions';
import notificationsTypes from '../../contants/notificationsTypes';
import TextFormField from '../FormElements/TextField';
import FormInfoBlock from '../FormInfoBlock';
import { isRequired, isValidEmail } from '../../utils/validations';
import infoIcon from '../../assets/icons/infoIcon.svg';
import closeIcon from '../../assets/icons/close.svg';
import styles from './styles';

const CustomerHelpModal = ({
  isOpen,
  handleModalState,
  handleSubmit,
  classes,
  reset,
  pristine,
  valid,
  isLoggedIn,
}) => {
  const dispatch = useDispatch();

  const showToast = useCallback((type, message) => dispatch(showNotification({
    type,
    message,
  })), [dispatch]);

  const requestStatus = useSelector((state) => getRequestStatus(state, 'contactSupport'));

  const handleContactSupport = useCallback(
    (data) => dispatch(contactSupport({ data })), [dispatch],
  );

  const handleResetRequest = useCallback(
    () => dispatch(resetRequest('contactSupport')),
    [dispatch],
  );

  const handleClose = useCallback(() => {
    if (!requestStatus?.loading) {
      reset();
      handleModalState(false);
      handleResetRequest();
    }
  }, [handleModalState, handleResetRequest, requestStatus, reset]);

  const handleSubmitForm = useCallback(async (data) => {
    await handleContactSupport(data).promise;
    handleClose();
    showToast(notificationsTypes.success, <FormattedMessage id="messageSend" />);
  }, [handleClose, handleContactSupport, showToast]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      disableBackdropClick={requestStatus?.loading}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.modalWrap}>
          <div className={classes.header}>
            <div><FormattedMessage id="contactSupport" /></div>
            <ButtonBase className={classes.close} onClick={handleClose}>
              <img src={closeIcon} alt="icon" />
            </ButtonBase>
          </div>
          <div className={classes.modalContent}>
            <form className={classes.modalForm} onSubmit={handleSubmit(handleSubmitForm)}>
              <div className={classes.formContent}>
                <div className={classes.tip}>
                  <img className={classes.icon} src={infoIcon} alt="info" />
                  <FormattedMessage id="supportExplaine" />
                </div>

                {requestStatus?.error && (
                  <div className={classes.errorWrap}>
                    <FormInfoBlock error={requestStatus?.errorData} />
                  </div>
                )}

                <div className={classes.fieldWrap}>
                  <Field
                    name="name"
                    component={TextFormField}
                    validate={[isRequired]}
                    disabled={isLoggedIn}
                    label={<FormattedMessage id="name" />}
                  />
                </div>

                <div className={classes.fieldWrap}>
                  <Field
                    name="email"
                    component={TextFormField}
                    validate={[isRequired, isValidEmail]}
                    disabled={isLoggedIn}
                    label={<FormattedMessage id="email" />}
                  />
                </div>

                <div className={classes.fieldWrap}>
                  <Field
                    name="message"
                    component={TextFormField}
                    validate={[isRequired]}
                    multiline
                    inputProps={{ rows: 4 }}
                    label={<FormattedMessage id="message" />}
                  />
                </div>
              </div>
              <div className={classes.footer}>
                <div className={classes.buttonBlock}>
                  <CustomButton
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={requestStatus?.loading}
                    disabled={!valid || pristine || requestStatus?.loading}
                  >
                    <FormattedMessage id="send" />
                  </CustomButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

CustomerHelpModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  handleModalState: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  isOpen: PropTypes.bool,
  valid: PropTypes.bool,
  reset: PropTypes.func,
  isLoggedIn: PropTypes.bool.isRequired,
};

CustomerHelpModal.defaultProps = {
  classes: {},
  handleSubmit: () => {},
  pristine: false,
  isOpen: false,
  reset: () => {},
  valid: false,
};

export default compose(
  withStyles(styles),
  connect((state) => ({
    initialValues: {
      name: getUserProfile(state)?.name,
      email: getUserProfile(state)?.email,
      message: '',
    },
    isLoggedIn: getIsLoggedInState(state),
  }), null),
  reduxForm({
    form: 'contactSupportForm',
    enableReinitialize: true,
  }),
)(CustomerHelpModal);
