import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Input from '@material-ui/core/InputBase';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { FormattedMessage } from 'react-intl';

import showIcon from '../../../assets/icons/show.svg';
import hideIcon from '../../../assets/icons/hide.svg';
import PasswordTooltip from './PasswordTooltip';
import PasswordStrengthBar from './PasswordStrengthBar';
import checkPasswordStrength from './utils';

import styles from '../styles/inputStyles';
import FieldWrap from '../FieldWrap';

const PasswordField = ({
  classes,
  input,
  meta: { touched, error },
  inputProps,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    strength: 0,
    matchLvl: {},
  });

  useEffect(() => {
    const { value } = input;
    const data = checkPasswordStrength(value);
    setPasswordStrength(data);
  }, [input]);

  const handleSetShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <FieldWrap
        label={<FormattedMessage id="password" />}
        touched={touched}
        error={error}
      >
        <PasswordTooltip
          matchLvl={passwordStrength.matchLvl}
          showTooltip={showTooltip && !!input.value}
        >
          <div
            className={classes.inputWrap}
            onFocus={() => setShowTooltip(true)}
            onBlur={() => setShowTooltip(false)}
          >
            <FormControl className={classes.input}>
              <Input
                {...input}
                {...inputProps}
                type={showPassword ? 'text' : 'password'}
                className={classes.input}
                classes={{
                  input: classes.input,
                }}
                endAdornment={(
                  <InputAdornment position="end">
                    <ButtonBase onClick={handleSetShowPassword}>
                      <img src={showPassword ? hideIcon : showIcon} alt="icon" />
                    </ButtonBase>
                  </InputAdornment>
                )}
                autoComplete="new-password"
              />
            </FormControl>
            <PasswordStrengthBar strength={passwordStrength.strength} />
          </div>
        </PasswordTooltip>
      </FieldWrap>
    </div>
  );
};

PasswordField.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  inputProps: PropTypes.shape({}),
  input: PropTypes.shape({
    value: PropTypes.string,
  }),
};

PasswordField.defaultProps = {
  classes: {},
  input: null,
  inputProps: null,
  meta: null,
};

export default withStyles(styles)(PasswordField);
