import React from 'react';
import PropTypes from 'prop-types';

function InfoField({ classes, label, value }) {
  return (
    <div className={classes.infoField}>
      <div className={classes.label}>{label}</div>
      <div className={classes.value}>{value}</div>
    </div>
  );
}

InfoField.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

InfoField.defaultProps = {
  value: 'N/A',
};

export default InfoField;
