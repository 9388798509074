import customTheme from '../../config/materialTheme';

export default function styles(theme) {
  return {
    root: {
      width: '90%',
      '@media (max-width: 1023px)': {
        width: '100%',
      },
      '& .Toastify__toast': {
        color: customTheme.custom.textColor.primary || theme.custom.textColor.primary,
        boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.06)',
        borderRadius: '4px',
        height: '60px',
        '@media (max-width: 1023px)': {
          height: 'auto',
        },
      },
      '& .Toastify__close-button': {
        opacity: 1,
        alignSelf: 'center',
        marginRight: '7px',
        '@media (max-width: 1023px)': {
          alignSelf: 'baseline',
          marginTop: '5px',
        },
      },
      '& .Toastify__progress-bar': {
        opacity: '0.4 !important',
      },
      '& .Toastify__toast--error': {
        background: customTheme.custom.error || theme.custom.error,
      },
      '& .Toastify__toast--success': {
        background: customTheme.custom.toast.success || theme.custom.toast.success,
      },
      '& .Toastify__toast--info': {
        background: customTheme.custom.toast.info || theme.palette.primary.info,
      },
    },
    title: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
      color: customTheme.custom.textColor.primary || theme.custom.textColor.primary,
      textTransform: 'capitalize',
    },
    subTitle: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      color: customTheme.custom.textColor.primary || theme.custom.textColor.primary,
    },
    wrap: {
      display: 'flex',
      marginLeft: '20px',
      alignItems: 'center',
      '@media (max-width: 1023px)': {
        alignItems: 'unset',
        flexDirection: 'column',
      },
    },
    header: {
      display: 'flex',
      marginBottom: '5px',
    },
    content: {
      marginLeft: '28px',
    },
    icon: {
      marginRight: '11px',
    },
  };
}
