import React from 'react';
import PropTypes from 'prop-types';

import NavigationTab from '../NavigationTab';
import navigationTabs from './constants';

function NavigationTabs({ classes }) {
  return (
    <div className={classes.navigationTabs}>
      {
        navigationTabs.map(
          (tabConfig) => (
            <div key={tabConfig.key}>
              <NavigationTab {...tabConfig} />
            </div>
          ),
        )
      }
    </div>
  );
}

NavigationTabs.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default NavigationTabs;
