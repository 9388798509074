import React from 'react';
import { withStyles } from '@material-ui/core';

import useGatewayTable from './hooks';
import gatewayTablePropTypes from './propTypes';
import TableCustomLayout from '../../../../../../components/TableCustom/TableCustomLayout';
import GatewayListHeader from './ListHeader';
import GatewayCard from './GatewayCard';
import TableFooterCustom from '../../../../../../components/TableCustom/TableFooterCustom';

function GatewayList({
  classes,
  reFetchGateways,
  loading,
  entities,
  itemCount,
  page,
  handleChangePage,
  perPage,
  handleChangePerPage,
  selectedIds,
  handleSelectId,
  handleSelectAllIds,
}) {
  const {
    handleDeleteGateways,
    isOpen,
    toggleModalState,
  } = useGatewayTable(selectedIds, reFetchGateways);

  return (
    <TableCustomLayout loading={loading}>
      <div className={classes.root}>
        <GatewayListHeader
          selectedIds={selectedIds}
          toggleModalState={toggleModalState}
          isOpen={isOpen}
          entities={entities}
          handleDeleteGateways={handleDeleteGateways}
          handleSelectAllIds={handleSelectAllIds}
        />

        {
          entities.map((entity) => (
            <div key={entity.id}>
              <GatewayCard
                gateway={entity}
                reFetchGateways={reFetchGateways}
                handleSelectId={handleSelectId}
                selectedIds={selectedIds}
              />
            </div>
          ))
        }

        <TableFooterCustom
          paginationConfig={{
            itemCount,
            page,
            perPage,
            handleChangePage,
            handleChangePerPage,
          }}
        />
      </div>
    </TableCustomLayout>
  );
}

GatewayList.propTypes = gatewayTablePropTypes;

export default withStyles((theme) => ({
  root: {
    backgroundColor: theme.custom.textColor.primary,
    margin: '0 -20px',
  },
}))(GatewayList);
