import ROUTES from '../../../contants/routes';

const navigationTabs = [
  {
    key: 'hotspots',
    label: 'Hotspots',
    linkRedirect: ROUTES.HOTSPOTS.DEFAULT_PATH,
    isActive: (pathname) => {
      const newRegexp = new RegExp(ROUTES.HOTSPOTS.DEFAULT_PATH, 'gim');

      return newRegexp.test(pathname);
    },
  },
];

export default navigationTabs;
