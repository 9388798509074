import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import styles from './styles';
import CustomModal from '../CustomModal';
import heliumAddressGuide1 from '../../assets/images/heliumAddressGuide1.svg';
import heliumAddressGuide2 from '../../assets/images/heliumAddressGuide2.svg';

const WalletAddressHelpModal = ({
  classes,
  isOpen,
  handleModalState,
}) => {
  const handleClose = useCallback(() => {
    handleModalState(false);
  }, [handleModalState]);

  return (
    <>
      <CustomModal
        handleClose={handleClose}
        isOpen={isOpen}
        cancelText="close"
        titleId="howToFindWallet"
        contentClass={classes.modalContent}
        onlyClose
      >
        <div className={classes.content}>
          <div className={classes.step}>
            <div className={classes.stepTitle}>
              <FormattedMessage id="step" />
              <span className={classes.stepNumber}>1</span>
            </div>
            <div className={classes.stepDescription}>
              <FormattedMessage id="openHeliumApp" />
            </div>
            <img src={heliumAddressGuide1} alt="step" className={classes.stepImage} />
          </div>
          <div className={classes.step}>
            <div className={classes.stepTitle}>
              <FormattedMessage id="step" />
              <span className={classes.stepNumber}>2</span>
            </div>
            <div className={classes.stepDescription}>
              <FormattedMessage id="copyOrShareAddress" />
            </div>
            <img src={heliumAddressGuide2} alt="step" className={classes.stepImage} />
          </div>
        </div>
      </CustomModal>
    </>
  );
};

WalletAddressHelpModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  handleModalState: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

WalletAddressHelpModal.defaultProps = {
  classes: {},
  isOpen: false,
};

export default compose(
  withStyles(styles),
)(WalletAddressHelpModal);
