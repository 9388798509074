export default function styles(theme) {
  return {
    navigationTab: {
      color: theme.custom.textColor.primary,
      fontSize: 14,
      position: 'relative',
    },
    isActive: {
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: -35,
        left: 0,
        right: 0,
        borderTop: `4px solid ${theme.custom.textColor.primary}`,

        [theme.breakpoints.down('sm')]: {
          bottom: -20,
        },
      },
    },
  };
}
