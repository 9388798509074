import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as OpenInViewIcon } from '../../assets/icons/openInView.svg';
import CustomModal from '../CustomModal';
import styles from './styles';

const HotspotHelpModal = ({
  classes,
  isOpen,
  handleModalState,
}) => {
  const handleClose = useCallback(() => {
    handleModalState(false);
  }, [handleModalState]);

  return (
    <>
      <CustomModal
        handleClose={handleClose}
        isOpen={isOpen}
        cancelText="close"
        titleId="hotspotHelpModalTitle"
        contentClass={classes.modalContent}
        onlyClose
      >
        <div className={classes.content}>
          <div className={classes.hintWrap}>
            <div className={classes.hintText}>
              <FormattedMessage id="hotspotHelpIPText" />
            </div>

            <div className={classes.ipLink}>
              <a
                className={classes.link}
                href={process.env.REACT_APP_RAK_IP_ADDRESS}
                target="_blank"
                rel="noopener noreferrer"
              >
                192.168.230.1<OpenInViewIcon />
              </a>
            </div>
          </div>

          <div className={classes.divider}>
            <Divider />
          </div>

          <div className={classes.hintWrap}>
            <div className={classes.hintText}>
              <FormattedMessage id="hotspotHelpDocumentationText" />
            </div>

            <a
              className={classes.buttonDocumentation}
              href={process.env.REACT_APP_RAK_DOCUMENTATION}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="hotspotHelpDocumentationButton" /><OpenInViewIcon />
            </a>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

HotspotHelpModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  handleModalState: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

HotspotHelpModal.defaultProps = {
  classes: {},
  isOpen: false,
};

export default compose(
  withStyles(styles),
)(HotspotHelpModal);
