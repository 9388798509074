export default function styles(theme) {
  return {
    wrap: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
    },
    rect: {
      height: '2px',
      backgroundColor: theme.custom.tooltipColor,
      opacity: 0.1,
      width: '19%',
    },
    low: {
      backgroundColor: theme.custom.error,
      opacity: 1,
    },
    medium: {
      backgroundColor: theme.custom.orange,
      opacity: 1,
    },
    high: {
      backgroundColor: theme.custom.textColor.secondary,
      opacity: 1,
    },
  };
}
