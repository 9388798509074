import { API_REQUEST } from '../api/actions';
import { API_ENDPOINTS } from '../../../contants/api';

export const GET_USER_INFO_START = 'GET_USER_INFO_START';
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';

export const UPDATE_USER_INFO_START = 'UPDATE_USER_INFO_START';
export const UPDATE_USER_INFO_ERROR = 'UPDATE_USER_INFO_ERROR';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';

export const DISCONNECT_ACCOUNT_START = 'DISCONNECT_ACCOUNT_START';
export const DISCONNECT_ACCOUNT_ERROR = 'DISCONNECT_ACCOUNT_ERROR';
export const DISCONNECT_ACCOUNT_SUCCESS = 'DISCONNECT_ACCOUNT_SUCCESS';

export const getUserInfo = () => ({
  type: API_REQUEST,
  payload: {
    url: API_ENDPOINTS.USER_PROFILE.USER_INFO,
    method: 'get',
  },
  dispatch: {
    START: GET_USER_INFO_START,
    SUCCESS: GET_USER_INFO_SUCCESS,
    ERROR: GET_USER_INFO_ERROR,
  },
});

export const updateUserInfo = (payload) => {
  const { data } = payload;

  return {
    type: API_REQUEST,
    payload: {
      url: `${API_ENDPOINTS.USER_PROFILE.USER_INFO}`,
      method: 'put',
      data,
    },
    dispatch: {
      START: UPDATE_USER_INFO_START,
      SUCCESS: UPDATE_USER_INFO_SUCCESS,
      ERROR: UPDATE_USER_INFO_ERROR,
    },
  };
};

export const disconnectAccount = () => ({
  type: API_REQUEST,
  payload: {
    url: API_ENDPOINTS.USER_PROFILE.DISCONNECT_ACCOUNT,
    method: 'post',
  },
  dispatch: {
    START: DISCONNECT_ACCOUNT_START,
    SUCCESS: DISCONNECT_ACCOUNT_ERROR,
    ERROR: DISCONNECT_ACCOUNT_SUCCESS,
  },
});
