export default function styles(theme) {
  return {
    wrap: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: '85px 0',
      '@media (max-width: 1023px)': {
        margin: '40px 0 85px 0',
      },
    },
    titleWrap: {
      marginBottom: '44px',
      width: '100%',
    },
    errorWrap: {
      marginTop: '20px',
    },
    title: {
      fontWeight: '600',
      fontSize: '29px',
      lineHeight: '36px',
      letterSpacing: '-0.01em',
      marginBottom: '20px',
    },
    infoText: {
      display: 'flex',
      alignItems: 'center',
      color: theme.custom.textColor.gray,
      opacity: 0.8,
      fontSize: '14px',
      lineHeight: '20px',
    },
    helpLabel: {
      marginRight: '4px',
    },
  };
}
