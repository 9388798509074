import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import history from '../utils/history';
import rootSaga from '../redux/sagas';
import reducers from '../redux/reducers';

const routingMiddleware = routerMiddleware(history);

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(sagaMiddleware, routingMiddleware)),
);

sagaMiddleware.run(rootSaga);

export const runSaga = (saga, ...args) => sagaMiddleware.run(saga, ...args);

export default store;
