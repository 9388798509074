import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import TextFormField from '../../../../components/FormElements/TextField';
import ShowPasswordField from '../../../../components/FormElements/ShowPasswordField';
import CustomButton from '../../../../components/CustomButton';
import { isRequired, isValidEmail } from '../../../../utils/validations';
import styles from './styles';
import { signIn } from '../../../../redux/reducers/auth/actions';
import ROUTES from '../../../../contants/routes';
import CustomLink from '../../../../components/CustomLink';

const LoginForm = ({
  classes, handleSubmit, valid, pristine, loading,
}) => {
  const dispatch = useDispatch();
  const handleSignIn = (data) => dispatch(signIn(data));

  return (
    <div className={classes.wrap}>
      <form onSubmit={handleSubmit(handleSignIn)}>
        <div className={classes.fieldWrap}>
          <Field
            name="email"
            component={TextFormField}
            validate={[isRequired, isValidEmail]}
            label={<FormattedMessage id="rakID" />}
          />
        </div>

        <div className={classes.fieldWrap}>
          <Field
            name="password"
            component={ShowPasswordField}
            validate={[isRequired]}
            label={<FormattedMessage id="password" />}
          />
        </div>

        <div className={classes.buttonBlock}>
          <CustomButton
            type="submit"
            variant="contained"
            color="primary"
            loading={loading}
            additionalClass={classes.button}
            disabled={!valid || pristine || loading}
          >
            <FormattedMessage id="signIn" />
          </CustomButton>

          <CustomLink>
            <NavLink to={ROUTES.FORGOT_PASSWORD.INDEX}>
              <FormattedMessage id="forgotPassword" />
            </NavLink>
          </CustomLink>
        </div>
      </form>
    </div>
  );
};

LoginForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  loading: PropTypes.bool,
  valid: PropTypes.bool,
};

LoginForm.defaultProps = {
  handleSubmit: () => {},
  pristine: false,
  loading: false,
  valid: false,
  classes: {},
};

export default compose(
  withStyles(styles),

  reduxForm({
    form: 'loginForm',
    enableReinitialize: true,
  }),
)(LoginForm);
