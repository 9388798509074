import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MaterialTabs from '@material-ui/core/Tabs';
import MaterialTab from '@material-ui/core/Tab';

const Tabs = withStyles((theme) => ({
  root: {
    backgroundColor: theme.custom.backgroundColor,
    borderRadius: '8px 8px 0 0',
  },
  indicator: {
    top: 0,
    backgroundColor: theme.palette.primary.main,
  },
}))(MaterialTabs);

const Tab = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: 'normal',
    maxWidth: 'none',
    textTransform: 'none',
    backgroundColor: '#EAEAEA',
    fontFamily: 'Inter',
    '&:hover': {
      color: theme.palette.primary.dark,
      opacity: 1,
    },
    '&$selected': {
      letterSpacing: 'normal',
      fontWeight: '600',
      color: theme.palette.primary.main,
      backgroundColor: theme.custom.backgroundColor,
    },
    '&:focus': {
      color: theme.palette.primary.dark,
    },
  },
  selected: {},
}))((props) => <MaterialTab disableRipple {...props} />);

export { Tabs, Tab };
