import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useSelector } from 'react-redux';
import ForgotPasswordForm from './containers/ForgotPasswordForm';
import styles from './styles';
import getRequestStatus from '../../redux/reducers/requestStatus/selectors';
import FormInfoBlock from '../../components/FormInfoBlock';
import useResetRequestStatus from '../../hooks/useResetRequestStatus';
import CustomerHelpModal from '../../components/CustomerHelpModal';
import CustomLink from '../../components/CustomLink';

const ForgotPassword = ({ classes }) => {
  const [isOpenSupportModal, setIsOpenSupportModal] = useState(false);
  const handleOpen = useCallback(() => {
    setIsOpenSupportModal(true);
  }, []);

  const forgotPasswordStatus = useSelector((state) => getRequestStatus(state, 'authForgotPassword'));

  useResetRequestStatus('authForgotPassword');

  return (
    <div className={classes.wrap}>
      <div className={classes.titleWrap}>
        <div className={classes.title}>
          <FormattedMessage id="forgotPassword" />
        </div>

        <div className={classes.infoWrap}>
          <div className={classes.infoText}>
            <FormattedMessage id="forgotPasswordInfo" />
          </div>

        </div>

        {forgotPasswordStatus.error && (
          <div className={classes.errorWrap}>
            <FormInfoBlock error={forgotPasswordStatus?.errorData} />

            {forgotPasswordStatus?.errorData?.errorCode === 'UserAccountIsBlocked' && (
              <>
                <br />
                <div className={classes.infoText}>
                  <div className={classes.helpLabel}>
                    <FormattedMessage id="needHelp" />
                  </div>

                  <CustomLink>
                    <ButtonBase
                      onClick={handleOpen}
                      disableRipple
                    >
                      <FormattedMessage id="contactSupport" />
                    </ButtonBase>
                  </CustomLink>

                  <CustomerHelpModal
                    isOpen={isOpenSupportModal}
                    handleModalState={setIsOpenSupportModal}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <ForgotPasswordForm loading={forgotPasswordStatus?.loading} />
    </div>
  );
};

ForgotPassword.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

ForgotPassword.defaultProps = {
  classes: {},
};

export default withStyles(styles)(ForgotPassword);
