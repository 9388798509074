import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { runSaga } from '../../../../../store';
import { sendApiRequest } from '../../../../../redux/sagas/apiRequests';
import { getGatewayBySN } from '../../../../../redux/reducers/gateways/actions';
import { getGatewayDetails } from '../../../../../redux/reducers/gateways/selectors';

function useFetchGateway(sn) {
  const [loading, setLoading] = useState(true);
  const gatewayDetails = useSelector(getGatewayDetails);

  useEffect(() => {
    const fetchTask = runSaga(
      sendApiRequest,
      getGatewayBySN({ sn }),
    );

    runFetch();

    return () => {
      fetchTask.cancel();
    };

    async function runFetch() {
      setLoading(true);

      await fetchTask.toPromise();

      if (fetchTask.isCancelled()) {
        return;
      }

      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => ({
    loading,
    gatewayDetails,
  }), [gatewayDetails, loading]);
}

export default useFetchGateway;
