export default function styles(theme) {
  return {
    timer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '25px',
      backgroundColor: theme.custom.backgroundColor,
      borderRadius: '35px',
      padding: '8px 16px',
      [theme.breakpoints.down('md')]: {
        marginTop: '15px',
        marginLeft: '0',
      },
    },
    time: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      marginLeft: '10px',
    },
  };
}
