import React from 'react';

import FormBlock from '../../../../components/FormElements/FormBlock';
import HeliumWalletForm from '../../components/HeliumWalletForm';
import useHeliumWalletForm from './hooks';

function HeliumWalletFormContainer() {
  const {
    initialValues,
    onSubmit,
    isOpenFindWalletAddressModal,
    toggleOpenWalletAddressModal,
  } = useHeliumWalletForm();

  return (
    <FormBlock label="heliumWallet">
      <HeliumWalletForm
        form="HELIUM_WALLET_ADDRESS"
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        isOpenFindWalletAddressModal={isOpenFindWalletAddressModal}
        toggleOpenWalletAddressModal={toggleOpenWalletAddressModal}
      />
    </FormBlock>
  );
}

export default HeliumWalletFormContainer;
