import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core';

function TableHeadCustom({
  classes,
  bulkActionElement,
  headerConfig,
  entities,
  selectedIds,
  handleSelectAllIds,
}) {
  return (
    <>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={
              (entities.length === selectedIds.length)
              || Boolean(selectedIds.length)
            }
            onClick={handleSelectAllIds}
            indeterminate={
              Boolean(selectedIds.length)
              && (entities.length !== selectedIds.length)
            }
          />
        </TableCell>
        <>
          {
            headerConfig.map((title) => (
              <TableCell key={JSON.stringify(title)} align="left">{title}</TableCell>
            ))
          }
          <TableCell align="left" />
        </>
      </TableRow>
      {
        selectedIds.length
          ? (
            <div className={classes.bulkActionsWrap}>
              {bulkActionElement}
            </div>
          ) : null
      }
    </>
  );
}

TableHeadCustom.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  entities: PropTypes.arrayOf(PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
  )).isRequired,
  headerConfig: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
      PropTypes.func,
    ]),
  ).isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSelectAllIds: PropTypes.func.isRequired,
  bulkActionElement: PropTypes.node.isRequired,
};

export default withStyles(((theme) => ({
  bulkActionsWrap: {
    position: 'absolute',
    marginTop: '-55px',
    marginLeft: '60px',
    width: 'calc(100% - 60px)',
    backgroundColor: theme.custom.textColor.primary,
  },
})))(TableHeadCustom);
