import { compose } from 'recompose';

export function sortGateways(entities) {
  const order = ['Disconnected', 'Pending', 'Connected'];

  return [...entities.sort((a, b) => order.indexOf(a.status) - order.indexOf(b.status))];
}

export function slicedArrayByPerPage(page, perPage) {
  return function sliced(entities) {
    return entities
      .slice((page - 1) * perPage, (page - 1) * perPage + perPage);
  };
}

export default function gatewaysAdapter({
  entities,
  page,
  perPage,
}) {
  return compose(
    slicedArrayByPerPage(page, perPage),
    sortGateways,
  )(entities);
}
