import { useEffect, useMemo, useState } from 'react';

import { runSaga } from '../../store';
import { sendApiRequest } from '../../redux/sagas/apiRequests';
import { getUserInfo } from '../../redux/reducers/userProfile/actions';

function useFetchProfileData() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTask = runSaga(
      sendApiRequest,
      getUserInfo(),
    );

    runFetch();

    return () => {
      fetchTask.cancel();
    };

    async function runFetch() {
      setLoading(true);

      await fetchTask.toPromise();

      if (fetchTask.isCancelled()) {
        return;
      }

      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => ({ loading }), [loading]);
}

export default useFetchProfileData;
