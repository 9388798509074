export default function styles() {
  return {
    wrap: {
      width: '100%',
    },
    buttonBlock: {
      marginTop: '40px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    button: {
      height: '60px',
      paddingLeft: '60px',
      paddingRight: '60px',
      minWidth: '181px',
    },
    fieldWrap: {
      marginBottom: '24px',
    },
  };
}
