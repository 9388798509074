export default function styles(theme) {
  return {
    content: {
      display: 'flex',
      justifyContent: 'center',
    },
    formWrapper: {
      marginTop: '40px',
      backgroundColor: '#ffffff',
      width: '410px',
      '@media (max-width: 1023px)': {
        width: '100%',
        marginTop: '10px',
      },
    },
    stepTitle: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      color: theme.custom.textColor.gray,
      fontSize: '11px',
      lineHeight: '16px',
      letterSpacing: '2px',
      opacity: 0.5,
      marginBottom: '10px',
      textTransform: 'uppercase',
    },
    title: {
      display: 'flex',
      position: 'relative',
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '-0.01em',
      marginBottom: '20px',
      '@media (max-width: 1023px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
      alignItems: 'center',
    },
    titleIcon: {
      position: 'absolute',
      top: 0,
      right: '105%',
      '@media (max-width: 1023px)': {
        position: 'relative',
        marginLeft: '20px',
        right: 0,
        top: 0,
      },
    },
    description: {
      color: theme.custom.textColor.gray,
      fontSize: '14px',
      lineHeight: '20px',
      marginBottom: '30px',
    },
    footerDescription: {
      opacity: 0.8,
      marginTop: '60px',
      borderLeft: '2px solid rgba(0, 0, 0, 0.3)',
      paddingLeft: '20px',
    },
    buttonBlock: {
      display: 'flex',
      margin: '40px 0',
      paddingRight: '2px',
      justifyContent: 'space-between',
    },

  };
}
