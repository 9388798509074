import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import RightSideContent from './components/RightSideContent';
import { getApiVersion } from '../../redux/reducers/api/selectors';

const UnAuthorizeLayout = ({
  children,
  classes,
  image,
  mainText,
  subText,
}) => {
  const apiVersion = useSelector(getApiVersion);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={classes.wrap}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.image}>
            <Logo />
          </div>
        </div>
        {children}
        <div className={classes.footer}>
          <div>
            <FormattedMessage id="copyright" values={{ version: apiVersion }} />
          </div>
        </div>
      </div>
      <RightSideContent
        image={image}
        mainText={mainText}
        subText={subText}
      />
    </div>
  );
};

UnAuthorizeLayout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
  image: PropTypes.string,
  subText: PropTypes.string,
  mainText: PropTypes.string,
};

UnAuthorizeLayout.defaultProps = {
  classes: {},
  image: '',
  subText: '',
  mainText: '',
};

export default UnAuthorizeLayout;
