import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import TableCustomLayout from './TableCustomLayout';
import TableHeadCustom from './TableHeadCustom';
import TableBodyCustom from './TableBodyCustom';
import TableFooterCustom from './TableFooterCustom';

import styles from './styles';

function TableCustom({
  classes,
  loading,
  entities,
  headerConfig,
  bodyConfig,
  paginationConfig,
  selectedIds,
  handleSelectId,
  handleSelectAllIds,
  handleDeleteEntities,
  bulkActionElement,
}) {
  return (
    <TableCustomLayout loading={loading}>
      <TableContainer className={classes.table}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableHeadCustom
              headerConfig={headerConfig}
              entities={entities}
              selectedIds={selectedIds}
              handleSelectAllIds={handleSelectAllIds}
              handleDeleteEntities={handleDeleteEntities}
              bulkActionElement={bulkActionElement}
            />
          </TableHead>
          <TableBody className={classes.tableBody}>
            <TableBodyCustom
              bodyConfig={bodyConfig}
              entities={entities}
              selectedIds={selectedIds}
              handleSelectId={handleSelectId}
            />
          </TableBody>
        </Table>

        <TableFooterCustom paginationConfig={paginationConfig} />
      </TableContainer>
    </TableCustomLayout>
  );
}

TableCustom.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  loading: PropTypes.bool.isRequired,
  entities: PropTypes.arrayOf(PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
  )).isRequired,
  headerConfig: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
      PropTypes.func,
    ]),
  ).isRequired,
  bodyConfig: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.func,
      ]),
    ),
  ).isRequired,
  paginationConfig: PropTypes.shape({
    itemCount: PropTypes.number,
    page: PropTypes.number,
    perPage: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleChangePerPage: PropTypes.func,
  }).isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSelectId: PropTypes.func.isRequired,
  handleSelectAllIds: PropTypes.func.isRequired,
  handleDeleteEntities: PropTypes.func.isRequired,
  bulkActionElement: PropTypes.node.isRequired,
};

TableCustom.defaultProps = {
  classes: {},
};

export default withStyles(styles)(TableCustom);
