import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import ROUTES from '../../contants/routes';
import LoginForm from './containers/LoginForm';
import { confirmEmail } from '../../redux/reducers/auth/actions';
import parseQuery from '../../utils/parseQuery';
import { setItem } from '../../utils/localStorageHelper';
import FormInfoBlock from '../../components/FormInfoBlock';
import styles from './styles';
import useGetRequestsStatuses from './hooks/useRequestStatuses';
import CustomerHelpModal from '../../components/CustomerHelpModal';
import CustomLink from '../../components/CustomLink';

const Login = ({ classes, location: { search } }) => {
  const dispatch = useDispatch();

  const {
    confirmEmailStatus,
    signInStatus,
    confirmPasswordStatus,
    logoutStatus,
  } = useGetRequestsStatuses();

  const [isOpenSupportModal, setIsOpenSupportModal] = useState(false);
  const handleOpen = useCallback(() => {
    setIsOpenSupportModal(true);
  }, []);


  const handleConfirmEmail = useCallback(
    (data) => dispatch(confirmEmail(data)),
    [dispatch],
  );

  useEffect(() => {
    const searchParams = parseQuery(search);

    const { email } = searchParams;
    const { code } = searchParams;

    if (email && code) {
      setItem('userEmail', email);
      handleConfirmEmail({ email, code });
    }
  }, [search, handleConfirmEmail]);

  const getMessage = () => {
    if (signInStatus.error) {
      return (
        <div className={classes.errorWrap}>
          <FormInfoBlock error={signInStatus?.errorData} />

          {signInStatus?.errorData?.errorCode === 'UserAccountIsBlocked' && (
            <>
              <br />
              <div>
                <span className={classes.subTitle}>
                  <FormattedMessage id="needHelp" />
                </span>
                <CustomLink>
                  <ButtonBase onClick={handleOpen} disableRipple>
                    <FormattedMessage id="contactSupport" />
                  </ButtonBase>
                </CustomLink>

                <CustomerHelpModal
                  isOpen={isOpenSupportModal}
                  handleModalState={setIsOpenSupportModal}
                />
              </div>
            </>
          )}
        </div>
      );
    }
    if (logoutStatus.connectedError) {
      return (
        <div className={classes.errorWrap}>
          <FormInfoBlock type="error" error={{ message: <FormattedMessage id="connectedError" /> }} />
        </div>
      );
    }
    if (logoutStatus.expired) {
      return (
        <div className={classes.errorWrap}>
          <FormInfoBlock type="info" error={{ message: <FormattedMessage id="sessionExpired" /> }} />
        </div>
      );
    }
    if (logoutStatus.success) {
      return (
        <div className={classes.errorWrap}>
          <FormInfoBlock type="info" error={{ message: <FormattedMessage id="successLogout" /> }} />
        </div>
      );
    }
    if (confirmPasswordStatus.success) {
      return (
        <div className={classes.errorWrap}>
          <FormInfoBlock type="info" error={{ message: <FormattedMessage id="successResetPassword" /> }} />
        </div>
      );
    }
    return null;
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.titleWrap}>
        <div className={classes.title}>
          <FormattedMessage id="loginTitle" />
        </div>
        <div>
          <span className={classes.subTitle}>
            <FormattedMessage id="loginNewUser" />
          </span>

          <CustomLink>
            <NavLink to={ROUTES.REGISTRATION.INDEX}>
              <FormattedMessage id="registrationTitle" />
            </NavLink>
          </CustomLink>

          {getMessage()}
        </div>
        {confirmEmailStatus.success && (
          <div className={cn(classes.subTitle, classes.confirm)}>
            <FormattedMessage id="emailConfirm" />
          </div>
        )}
      </div>
      <LoginForm loading={signInStatus.loading} />
    </div>
  );
};

Login.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

Login.defaultProps = {
  classes: {},
  location: {},
};

export default withStyles(styles)(Login);
