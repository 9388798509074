import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getUserProfile } from '../../../../redux/reducers/userProfile/selectors';
import { runSaga } from '../../../../store';
import { sendApiRequest } from '../../../../redux/sagas/apiRequests';
import { updateUserInfo } from '../../../../redux/reducers/userProfile/actions';
import { showNotification } from '../../../../redux/reducers/notifications/actions';

export default function useHeliumWalletForm() {
  const dispatch = useDispatch();
  const [isOpenFindWalletAddressModal, setIsOpenFindWalletAddressModal] = useState(false);

  const toggleOpenWalletAddressModal = useCallback(() => {
    setIsOpenFindWalletAddressModal(!isOpenFindWalletAddressModal);
  }, [isOpenFindWalletAddressModal]);

  const {
    heliumWalletAddress,
  } = useSelector(getUserProfile);

  const onSubmit = useCallback(async (values) => {
    const { ok } = await runSaga(
      sendApiRequest,
      updateUserInfo({ data: { ...values } }),
    ).toPromise();

    if (ok) {
      dispatch(showNotification({
        type: 'success',
        message: <FormattedMessage id="heliumWalletUpdateSuccess" />,
      }));
    }
  }, [dispatch]);

  return useMemo(() => ({
    initialValues: { heliumWalletAddress },
    onSubmit,
    isOpenFindWalletAddressModal,
    toggleOpenWalletAddressModal,
  }), [
    heliumWalletAddress,
    isOpenFindWalletAddressModal,
    onSubmit,
    toggleOpenWalletAddressModal,
  ]);
}
