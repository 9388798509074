import { call, takeEvery } from 'redux-saga/effects';
import React from 'react';
import { toast } from 'react-toastify';

import * as notificationsActions from '../reducers/notifications/actions';
import Notification from '../../components/Notification';

export function* handleShowNotification(action) {
  const data = action.payload;
  yield call(toast[data.type], <Notification data={data} />);
}

export function* watchNotification() {
  yield takeEvery(
    notificationsActions.SHOW_NOTIFICATION,
    handleShowNotification,
  );
}
