export default function styles() {
  return {
    emptyStateWrap: {
      marginTop: '150px',
    },
    button: {
      width: '150px',
      height: '40px',
    },
  };
}
