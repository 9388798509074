import { API_REQUEST } from '../api/actions';
import { API_ENDPOINTS } from '../../../contants/api';

export const ADD_BASIC_HOTSPOT_DETAILS_START = 'ADD_BASIC_HOTSPOT_DETAILS_START';
export const ADD_BASIC_HOTSPOT_DETAILS_ERROR = 'ADD_BASIC_HOTSPOT_DETAILS_ERROR';
export const ADD_BASIC_HOTSPOT_DETAILS_SUCCESS = 'ADD_BASIC_HOTSPOT_DETAILS_SUCCESS';

export const ADD_HOTSPOT_LOCATION_START = 'ADD_HOTSPOT_LOCATION_START';
export const ADD_HOTSPOT_LOCATION_ERROR = 'ADD_HOTSPOT_LOCATION_ERROR';
export const ADD_HOTSPOT_LOCATION_SUCCESS = 'ADD_HOTSPOT_LOCATION_SUCCESS';

export const RELOCATE_HOTSPOT_START = 'RELOCATE_HOTSPOT_START';
export const RELOCATE_HOTSPOT_ERROR = 'RELOCATE_HOTSPOT_ERROR';
export const RELOCATE_HOTSPOT_SUCCESS = 'RELOCATE_HOTSPOT_SUCCESS';

export const UPDATE_HOTSPOT_CREATION_TIMER = 'UPDATE_HOTSPOT_CREATION_TIMER';
export const UPDATE_HOTSPOT_RELOCATION_TIMER = 'UPDATE_HOTSPOT_RELOCATION_TIMER';

export const addBasicHotspotDetails = (payload) => {
  const { data } = payload;

  return {
    type: API_REQUEST,
    payload: {
      url: `${API_ENDPOINTS.HOTSPOTS._}`,
      method: 'post',
      data,
    },
    dispatch: {
      START: ADD_BASIC_HOTSPOT_DETAILS_START,
      SUCCESS: ADD_BASIC_HOTSPOT_DETAILS_SUCCESS,
      ERROR: ADD_BASIC_HOTSPOT_DETAILS_ERROR,
    },
    meta: {
      skipToast: true,
    },
  };
};

export const addHotspotLocation = (payload) => {
  const { data } = payload;

  return {
    type: API_REQUEST,
    payload: {
      url: `${API_ENDPOINTS.HOTSPOTS.LOCATION}`,
      method: 'post',
      data,
    },
    dispatch: {
      START: ADD_HOTSPOT_LOCATION_START,
      SUCCESS: ADD_HOTSPOT_LOCATION_SUCCESS,
      ERROR: ADD_HOTSPOT_LOCATION_ERROR,
    },
    meta: {
      skipToast: true,
    },
  };
};

export const relocateHotspot = (payload) => {
  const { data } = payload;

  return {
    type: API_REQUEST,
    payload: {
      url: `${API_ENDPOINTS.HOTSPOTS.RELOCATE}`,
      method: 'post',
      data,
    },
    dispatch: {
      START: RELOCATE_HOTSPOT_START,
      SUCCESS: RELOCATE_HOTSPOT_SUCCESS,
      ERROR: RELOCATE_HOTSPOT_ERROR,
    },
    meta: {
      skipToast: true,
    },
  };
};

export const updateHotspotCreationTimer = (payload) => ({
  type: UPDATE_HOTSPOT_CREATION_TIMER,
  payload,
});

export const updateHotspotRelocationTimer = (payload) => ({
  type: UPDATE_HOTSPOT_RELOCATION_TIMER,
  payload,
});
