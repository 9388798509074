export default function styles(theme) {
  return {
    wrap: {
      width: '100%',
      minHeight: '100vh',
      display: 'grid',
      gridTemplateColumns: '622px auto',
      backgroundColor: theme.custom.textColor.primary,
      '@media (max-width: 1023px)': {
        display: 'block',
      },
    },
    container: {
      padding: '0 13%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '@media (max-width: 1023px)': {
        padding: '0 5%',
        minHeight: '100vh',
      },
    },
    header: {
      marginTop: '60px',
      '@media (max-width: 1023px)': {
        marginTop: '20px',
      },
    },
    image: {
      width: '87px',
      height: '27px',
    },
    footer: {
      marginBottom: '60px',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '11px',
      lineHeight: '16px',
      color: theme.custom.textColor.gray,
      opacity: '0.6',
      '@media (max-width: 1023px)': {
        marginBottom: '20px',
        width: '230px',
      },
    },
  };
}
