import { API_ENDPOINTS } from '../../../contants/api';

export const API_REQUEST = 'API_REQUEST';
export const API_REQUEST_ERROR = 'API_REQUEST_ERROR';

export const GET_API_VERSION_START = 'GET_API_VERSION__START';
export const GET_API_VERSION__ERROR = 'GET_API_VERSION__ERROR';
export const GET_API_VERSION__SUCCESS = 'GET_API_VERSION__SUCCESS';

export const apiRequestError = (payload, meta) => ({
  type: API_REQUEST_ERROR,
  payload,
  meta,
});

export const getApiVersion = () => ({
  type: API_REQUEST,
  payload: {
    url: API_ENDPOINTS.API.VERSION,
    method: 'get',
  },
  dispatch: {
    START: GET_API_VERSION_START,
    SUCCESS: GET_API_VERSION__SUCCESS,
    ERROR: GET_API_VERSION__ERROR,
  },
  meta: {
    skipAuthHeader: true,
    skipToast: true,
  },
});
