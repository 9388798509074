export default function styles(theme) {
  return {
    modalContent: {
      width: '600px',
      paddingBottom: '10px',
      '@media (max-width:1023px)': {
        width: '100%',
      },
    },
    content: {
      display: 'flex',
      '@media (max-width:1023px)': {
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
    },
    step: {
      flexGrow: 1,
      paddingRight: '30px',
      marginBottom: '30px',
    },
    stepTitle: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '2px',
      color: theme.custom.textColor.gray,
      opacity: 0.8,
      marginBottom: '5px',
      textTransform: 'uppercase',
    },
    stepNumber: {
      paddingLeft: '5px',
    },
    stepDescription: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.custom.textColor.gray,
      marginBottom: '25px',
    },
    stepImage: {
      height: '278px',
      width: '162px',
    },
  };
}
