import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';

const Loader = ({ classes, ...props }) => (
  <div className={classes.root}>
    <CircularProgress {...props} />
  </div>
);

Loader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

Loader.defaultProps = {
  classes: null,
};

export default withStyles(styles)(Loader);
