export default function styles(theme) {
  return {
    content: {
      display: 'flex',
      justifyContent: 'center',
    },
    formWrapper: {
      marginTop: '40px',
      backgroundColor: '#ffffff',
      width: '410px',
      '@media (max-width: 1023px)': {
        width: '100%',
        marginTop: '10px',
      },
    },
    title: {
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '-0.01em',
      marginBottom: '20px',
      '@media (max-width: 1023px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    description: {
      display: 'flex',
      alignItems: 'center',
      color: theme.custom.textColor.gray,
      opacity: 0.8,
      fontSize: '14px',
      lineHeight: '20px',
      marginBottom: '40px',
    },
    errorWrap: {
      marginBottom: '25px',
    },
    buttonBlock: {
      margin: '60px 0',
      '@media (max-width: 1023px)': {
        margin: '30px 0',
        flexDirection: 'column',
      },
    },
    buttonCancel: {
      color: theme.palette.primary.main,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '48px',
      marginLeft: '52px',
      '@media (max-width: 1023px)': {
        marginTop: '20px',
        marginLeft: '0',
      },
    },
    notHaveAccount: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px 20px',
      borderRadius: '8px',
      background: theme.custom.backgroundColor,
      marginTop: '60px',
      marginBottom: '60px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.custom.textColor.gray,
    },
  };
}
