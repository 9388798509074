import React from 'react';

export default function withResponsiveLayout({
  factoryFunction = () => false,
  ResponsiveView = () => <div>Provide Alternative View</div>,
}) {
  return function wrappedComponent(DesktopView) {
    return function componentProps(props) {
      return (
        factoryFunction(props)
          ? (<ResponsiveView {...props} />)
          : (<DesktopView {...props} />)
      );
    };
  };
}
