import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import ButtonBase from '@material-ui/core/ButtonBase';
import { ReactComponent as ForumIcon } from '../../assets/icons/forum.svg';
import { ReactComponent as SupportIcon } from '../../assets/icons/support.svg';
import { getApiVersion } from '../../redux/reducers/api/selectors';
import TermsPolicyModal from '../TermsPolicyModal';
import CustomerHelpModal from '../CustomerHelpModal';
import styles from './styles';

const Footer = ({ classes }) => {
  const apiVersion = useSelector(getApiVersion);

  const [isOpenSupportModal, setIsOpenSupportModal] = useState(false);
  const handleOpen = useCallback(() => {
    setIsOpenSupportModal(true);
  }, []);

  return (
    <div className={classes.footer}>
      <div className={classes.copyright}>
        <FormattedMessage id="copyright" values={{ version: apiVersion }} />
      </div>
      <div className={classes.termsWrap}>
        <div className={classes.icon}><ForumIcon /></div>
        <ButtonBase
          href={process.env.REACT_APP_RAK_FORUM_URL}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.footerLink}
          disableRipple
        >
          <FormattedMessage id="forum" />
        </ButtonBase>

        <div className={classes.separator}>·</div>
        <div className={classes.icon}><SupportIcon /></div>
        <ButtonBase
          onClick={handleOpen}
          className={classes.footerLink}
          disableRipple
        >
          <FormattedMessage id="support" />
        </ButtonBase>

        <div className={classes.separator}>·</div>
        <TermsPolicyModal isFooter title="privacyTitle" footerTitle="privacy" />

        <div className={classes.separator}>·</div>
        <TermsPolicyModal isFooter />
      </div>

      <CustomerHelpModal
        isOpen={isOpenSupportModal}
        handleModalState={setIsOpenSupportModal}
      />
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

Footer.defaultProps = {
  classes: {},
};

export default withStyles(styles)(Footer);
