export default function styles(theme) {
  return {
    paper: {
      width: 630,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    divider: {
      margin: '32px 0',
    },
    drawerWrap: {
      padding: '60px 40px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      [theme.breakpoints.down('sm')]: {
        padding: '30px 20px',
      },
    },
    drawerHeader: {},
    headerTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: 24,
      fontWeight: 600,
      letterSpacing: -1,
    },
    headerStatus: {
      marginTop: '15px',
    },
    drawerContent: {
      flexGrow: 1,
      flexShrink: 1,
      alignItems: 'stretch',
    },
    drawerFooter: {},
    buttonRelocate: {
      marginRight: 30,
      background: 'none',
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,

      '&:hover': {
        color: theme.custom.textColor.primary,
        background: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: `0px 0px 1px 1px ${theme.palette.primary.main}`,
      },

      '&:disabled': {
        background: 'none',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        opacity: 0.5,
      },
    },
    buttonDelete: {
      fontSize: 14,
      color: theme.custom.error,
    },
  };
}
