import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import HotspotsView from './containers/HotspotsView';

const Hotspots = ({ classes }) => {
  const { sn, path } = useParams();

  return (
    <div className={classes.wrap}>
      <HotspotsView
        sn={sn}
        path={path}
      />
    </div>
  );
};

Hotspots.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

Hotspots.defaultProps = {
  classes: {},
};

export default Hotspots;
