import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ButtonBase from '@material-ui/core/ButtonBase';
import { Field } from 'redux-form';

import TextFormField from '../../../../components/FormElements/TextField';
import { isRequiredHeliumAddress } from '../../../../utils/validations';
import CustomButton from '../../../../components/CustomButton';
import WalletAddressHelpModal from '../../../../components/WalletAddressHelpModal';
import CustomLink from '../../../../components/CustomLink';

function HeliumWalletForm({
  classes,
  handleSubmit,
  submitting,
  valid,
  pristine,
  isOpenFindWalletAddressModal,
  toggleOpenWalletAddressModal,
}) {
  return (
    <form className={classes.heliumWalletForm} onSubmit={handleSubmit}>
      <Field
        name="heliumWalletAddress"
        component={TextFormField}
        validate={[isRequiredHeliumAddress]}
        label={<FormattedMessage id="address" />}
        description={(
          <>
            <FormattedMessage id="cantFindWalletAddress" />
            <CustomLink>
              <ButtonBase
                onClick={toggleOpenWalletAddressModal}
                disableRipple
              >
                <FormattedMessage id="here" />
              </ButtonBase>
            </CustomLink>
          </>
        )}
        disabled={submitting}
      />

      <CustomButton
        type="submit"
        additionalClass={classes.submitButton}
        variant="outlined"
        color="primary"
        disabled={!valid || pristine}
        loading={submitting}
      >
        Save new wallet address
      </CustomButton>

      <div className={classes.notHaveAccount}>
        <FormattedMessage id="notHaveAccount" />
        <CustomLink>
          <ButtonBase
            href={process.env.REACT_APP_HELIUM_APP_URL}
            target="_blank"
            rel="noopener noreferrer"
            disableRipple
          >
            <FormattedMessage id="downloadApp" />
          </ButtonBase>
        </CustomLink>
      </div>

      <WalletAddressHelpModal
        isOpen={isOpenFindWalletAddressModal}
        handleModalState={toggleOpenWalletAddressModal}
      />
    </form>
  );
}

HeliumWalletForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  isOpenFindWalletAddressModal: PropTypes.bool.isRequired,
  toggleOpenWalletAddressModal: PropTypes.func.isRequired,
};

export default HeliumWalletForm;
