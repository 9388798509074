import { all } from 'redux-saga/effects';

import { watchApiRequests } from './apiRequests';
import { watchAuth } from './authSaga';
import { watchOrganization } from './organizationSaga';
import { watchOrganizations } from './organizationsSaga';
import { watchLocation } from './locationSaga';
import { watchNotification } from './notificationsSaga';

export default function* rootSaga() {
  yield all([
    watchOrganizations(),
    watchOrganization(),
    watchNotification(),
    watchApiRequests(),
    watchLocation(),
    watchAuth(),
  ]);
}
