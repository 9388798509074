export default function styles(theme) {
  return {
    tooltip: {
      width: '266px',
      // height: '85px',
      background: theme.custom.textColor.primary,
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
      '&$open': {
        opacity: 0.5,
      },
    },
    arrow: {
      color: theme.custom.textColor.primary,
    },
    tooltipContent: {
      color: theme.custom.textColor.gray,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: '16px',
    },
    tooltipIcon: {
      marginRight: '8px',
    },
    tooltipText: {
      margin: '5px 0',
    },
  };
}
