import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/InputBase';
import cn from 'classnames';

import FieldWrap from '../FieldWrap';

import styles from '../styles/inputStyles';

const TextFormField = ({
  classes,
  input,
  label,
  inputProps,
  disabled,
  meta: { touched, error },
  description,
  tooltipId,
  multiline,
  wrapClassName,
}) => (
  <div>
    <FieldWrap
      label={label}
      touched={touched}
      error={error}
      description={description}
      tooltipId={tooltipId}
    >
      <div
        className={cn(classes.inputWrap,
          {
            [classes.disabled]: disabled,
            [classes.multiline]: multiline,
            [wrapClassName]: wrapClassName,
          })}
      >
        <FormControl className={classes.input}>
          <Input
            {...input}
            {...inputProps}
            type="text"
            className={classes.input}
            disabled={disabled}
            multiline={multiline}
            classes={{
              input: classes.input,
            }}
            autoComplete="new-password"
          />
        </FormControl>
      </div>
    </FieldWrap>
  </div>
);

TextFormField.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  input: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  tooltipId: PropTypes.string,
  description: PropTypes.node,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  wrapClassName: PropTypes.string,
};

TextFormField.defaultProps = {
  classes: null,
  input: null,
  inputProps: null,
  meta: null,
  disabled: false,
  multiline: false,
  tooltipId: '',
  description: '',
  label: '',
  placeholder: '',
  wrapClassName: '',
};

export default withStyles(styles)(TextFormField);
