export default function styles(theme) {
  return {
    wrap: {
      border: `1px solid ${theme.custom.textColor.light}`,
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      opacity: '0.8',
      background: theme.custom.textColor.primary,
      borderRadius: '50%',
      width: '32px',
      height: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    name: {
      color: theme.palette.primary.main,
    },
  };
}
