import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core';

export default withStyles({
  root: {
    padding: '16px 24px',
  },
})((props) => (
  <MenuItem {...props} />
));
