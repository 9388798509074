export default function styles(theme) {
  return {
    wrap: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    field: {
      borderRadius: '16px 0 0 16px',
    },
    buttonBlock: {
      display: 'flex',
      width: '100px',
      alignItems: 'center',
      justifyContent: 'center',
      height: '46px',
      marginTop: '6px',
    },
    editButton: {
      border: `1px solid ${theme.custom.inputBorderColor}`,
      width: '50px',
      height: '100%',
      cursor: 'pointer',
      '&:hover': {
        border: '1px solid rgba(0, 0, 0, 0.4)',
      },
      '&:disabled': {
        '& > svg': {
          opacity: 0.4,
        },
      },
    },
    close: {
      border: `1px solid ${theme.custom.inputBorderColor}`,
      width: '50px',
      height: '100%',
      borderRadius: '0 16px 16px 0',
      '&:hover': {
        border: '1px solid rgba(0, 0, 0, 0.4)',
      },
    },
    error: {
      marginTop: '0px',
      marginBottom: '15px',
    },
  };
}
