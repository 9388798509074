import { API_REQUEST } from '../api/actions';
import { API_ENDPOINTS } from '../../../contants/api';

export const GET_GATEWAY_BY_SN_START = 'GET_GATEWAY_BY_SN_START';
export const GET_GATEWAY_BY_SN_SUCCESS = 'GET_GATEWAY_BY_SN_SUCCESS';
export const GET_GATEWAY_BY_SN_ERROR = 'GET_GATEWAY_BY_SN_ERROR';

export const GET_GATEWAYS_START = 'GET_GATEWAYS_START';
export const GET_GATEWAYS_SUCCESS = 'GET_GATEWAYS_SUCCESS';
export const GET_GATEWAYS_ERROR = 'GET_GATEWAYS_ERROR';

export const UPDATE_GATEWAYS_START = 'UPDATE_GATEWAYS_START';
export const UPDATE_GATEWAYS_SUCCESS = 'UPDATE_GATEWAYS_SUCCESS';
export const UPDATE_GATEWAYS_ERROR = 'UPDATE_GATEWAYS_ERROR';

export const DELETE_GATEWAYS_START = 'DELETE_GATEWAYS_START';
export const DELETE_GATEWAYS_SUCCESS = 'DELETE_GATEWAYS_SUCCESS';
export const DELETE_GATEWAYS_ERROR = 'DELETE_GATEWAYS_ERROR';

export const getGatewayBySN = ({ sn }) => ({
  type: API_REQUEST,
  payload: {
    url: `${API_ENDPOINTS.GATEWAYS._}/${sn}`,
    method: 'get',
  },
  dispatch: {
    START: GET_GATEWAY_BY_SN_START,
    SUCCESS: GET_GATEWAY_BY_SN_SUCCESS,
    ERROR: GET_GATEWAY_BY_SN_ERROR,
  },
});

export const getGateways = () => ({
  type: API_REQUEST,
  payload: {
    url: API_ENDPOINTS.GATEWAYS._,
    method: 'get',
  },
  dispatch: {
    START: GET_GATEWAYS_START,
    SUCCESS: GET_GATEWAYS_SUCCESS,
    ERROR: GET_GATEWAYS_ERROR,
  },
});

export const updateGateways = ({ sn, data }) => ({
  type: API_REQUEST,
  payload: {
    url: `${API_ENDPOINTS.GATEWAYS._}/${sn}`,
    method: 'put',
    data,
  },
  dispatch: {
    START: UPDATE_GATEWAYS_START,
    SUCCESS: UPDATE_GATEWAYS_SUCCESS,
    ERROR: UPDATE_GATEWAYS_ERROR,
  },
});

export const deleteGateways = ({ data }) => ({
  type: API_REQUEST,
  payload: {
    url: API_ENDPOINTS.GATEWAYS._,
    method: 'delete',
    data,
  },
  dispatch: {
    START: DELETE_GATEWAYS_START,
    SUCCESS: DELETE_GATEWAYS_SUCCESS,
    ERROR: DELETE_GATEWAYS_ERROR,
  },
  meta: {
    data,
  },
});
