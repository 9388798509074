import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import cn from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';

const CustomButton = ({
  classes,
  children,
  additionalClass,
  loading,
  ...props
}) => (
  <Button
    className={cn(classes.button, additionalClass)}
    disableRipple
    disableTouchRipple
    {...props}
  >
    {loading ? (
      <CircularProgress
        className={classes.loader}
        size={24}
      />
    ) : children}
  </Button>
);

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  additionalClass: PropTypes.string,
  loading: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
};

CustomButton.defaultProps = {
  classes: {},
  loading: false,
  additionalClass: '',
};

export default withStyles(styles)(CustomButton);
