import { API_REQUEST } from '../api/actions';
import { API_ENDPOINTS } from '../../../contants/api';

export const GET_LOCATIONS_START = 'GET_LOCATIONS_START';
export const GET_LOCATIONS_ERROR = 'GET_LOCATIONS_ERROR';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';

export const CREATE_LOCATION_START = 'CREATE_LOCATION_START';
export const CREATE_LOCATION_ERROR = 'CREATE_LOCATION_ERROR';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';

export const getLocations = (id) => ({
  type: API_REQUEST,
  payload: {
    url: `${API_ENDPOINTS.ORGANIZATIONS._}/${id}${API_ENDPOINTS.LOCATIONS._}`,
    method: 'get',
  },
  dispatch: {
    START: GET_LOCATIONS_START,
    SUCCESS: GET_LOCATIONS_SUCCESS,
    ERROR: GET_LOCATIONS_ERROR,
  },
});

export const createLocation = (payload) => {
  const { orgId, data } = payload;
  return {
    type: API_REQUEST,
    payload: {
      url: `${API_ENDPOINTS.ORGANIZATIONS._}/${orgId}${API_ENDPOINTS.LOCATIONS._}`,
      method: 'post',
      data,
    },
    dispatch: {
      START: CREATE_LOCATION_START,
      SUCCESS: CREATE_LOCATION_SUCCESS,
      ERROR: CREATE_LOCATION_ERROR,
    },
  };
};
