import Cookies from 'universal-cookie';

const { REACT_APP_API_PREFIX } = process.env;

const cookie = new Cookies();

const setCookie = ({ key, value }) => {
  cookie.set(key, value, { path: '/', domain: REACT_APP_API_PREFIX });
};

const getCookie = (key) => cookie.get(key);

const removeCookie = (key) => cookie.remove(key, { path: '/', domain: REACT_APP_API_PREFIX });

export { setCookie, getCookie, removeCookie };
