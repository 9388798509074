export default function styles(theme) {
  return {
    section: {},
    sectionHeader: {
      marginBottom: 35,

      [theme.breakpoints.down('sm')]: {
        margin: '-24px -20px 0px -24px',
        padding: '24px 20px',
      },
    },
    mainTitleWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 15,
    },
    title: {
      fontWeight: 600,
      fontSize: 30,

      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
    subtitle: {
      fontWeight: 400,
      fontSize: 14,
      color: theme.custom.textColor.grayLight,
    },
    dividerWrap: {
      margin: '35px -64px 35px 0',

      [theme.breakpoints.down('sm')]: {
        margin: '0 0 20px 0',
      },
    },
    sectionContent: {
      padding: '60px 0',
    },
  };
}
