import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { destroy } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import cn from 'classnames';
import useStepper from '../../../../../components/Stepper/hook';
import ROUTES from '../../../../../contants/routes';
import styles from './styles';
import Section from '../../../../../components/Section';
import FindLocationForm from '../FindLocation';
import { updateHotspotRelocationTimer } from '../../../../../redux/reducers/hotspots/actions';
import { getHotspotsRelocationTimer } from '../../../../../redux/reducers/hotspots/selectors';
import CountdownTimer from '../../../../../components/CountdownTimer';
import Loader from '../../../../../components/Loader';
import infoIcon from '../../../../../assets/icons/infoIcon.svg';
import Modal from '../../../../../components/CustomModal';
import AssertLocationFee from '../AssertLocationFee';
import FinishHotspotAdding from '../FinishHotspotSetting';
import useFetchGateway from './useFetchGateway';

const ENV_TIMER = Number(process.env.REACT_APP_HOTSPOT_TIMER);

const RelocateHotspot = ({ classes, startStep }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { sn } = useParams();
  const {
    loading,
    gatewayDetails,
  } = useFetchGateway(sn);

  const isGatewayAvailable = useMemo(() => !loading && gatewayDetails && gatewayDetails.status !== 'Pending', [gatewayDetails, loading]);
  const isGatewayNotAvailable = useMemo(() => (!loading && !gatewayDetails) || (!loading && gatewayDetails && gatewayDetails.status === 'Pending'), [gatewayDetails, loading]);

  const { activeStep, nextStep, prevStep } = useStepper(startStep);

  const isStartHotspotsRelocation = useSelector(getHotspotsRelocationTimer);

  useEffect(() => {
    if (!loading && gatewayDetails) {
      dispatch(updateHotspotRelocationTimer({ isStartRelocation: true }));
    }
  }, [dispatch, gatewayDetails, loading]);

  const [isOpenExpiredModal, setIsOpenExpiredModal] = useState(false);
  const openExpiredModal = useCallback(() => setIsOpenExpiredModal(true), []);
  const closeExpiredModal = useCallback(() => setIsOpenExpiredModal(false), []);

  const onTimerExpired = useCallback(() => {
    dispatch(updateHotspotRelocationTimer({ isStartRelocation: false }));
    openExpiredModal();
  }, [dispatch, openExpiredModal]);

  const onUnderstoodTimerInfo = useCallback(() => {
    closeExpiredModal();
    push(ROUTES.HOTSPOTS.DEFAULT_PATH);
  }, [closeExpiredModal, push]);

  useEffect(() => {
    let timerId;

    if (isStartHotspotsRelocation) {
      timerId = setTimeout(() => {
        onTimerExpired();
      }, ENV_TIMER);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [isStartHotspotsRelocation, onTimerExpired]);

  useEffect(() => () => {
    // reset all forms
    dispatch(destroy('hotspotRelocationForm'));

    // reset timer
    dispatch(updateHotspotRelocationTimer({ isStartRelocation: false }));
  }, [dispatch]);

  return (
    <Section
      title={(
        <div className={cn(classes.titleWrapper, {
          [classes.titleWrapperIsLoading]: loading,
        })}
        >
          <FormattedMessage id="relocateTitle" />
          {
            activeStep > 1 && activeStep < 4 && isStartHotspotsRelocation
              && (
                <CountdownTimer
                  timer={ENV_TIMER}
                  interval={1000}
                  timerOn={isStartHotspotsRelocation}
                />
              )
          }
        </div>
      )}
      withDivider
    >
      {
        loading && <Loader />
      }

      { isGatewayAvailable && (
        <div className={classes.content}>
          { activeStep === 2
              && <FindLocationForm form="hotspotRelocationForm" isRelocation hotspot={gatewayDetails} nextStep={nextStep} />}
          {activeStep === 3
              && <AssertLocationFee isRelocation nextStep={nextStep} prevStep={prevStep} />}
          { activeStep === 4 && <FinishHotspotAdding isRelocation /> }
        </div>
      )}

      {
        isGatewayNotAvailable
          && <Redirect to={ROUTES.HOTSPOTS.DEFAULT_PATH} />
      }

      <Modal
        isOpen={isOpenExpiredModal}
        titleId="timesUp"
        submitteText="understand"
        showClose={false}
        contentClass={classes.modalContent}
        onSubmite={onUnderstoodTimerInfo}
        handleClose={closeExpiredModal}
        modalProps={{
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        }}
      >
        <div className={classes.tip}>
          <img className={classes.icon} src={infoIcon} alt="info" />
          <FormattedMessage id="relocateSessionRunOutTime" />
        </div>
      </Modal>
    </Section>
  );
};

RelocateHotspot.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  startStep: PropTypes.number,
};

RelocateHotspot.defaultProps = {
  classes: {},
  startStep: 2,
};

export default withStyles(styles)(RelocateHotspot);
