export default function styles(theme) {
  return {
    labelWrap: {
      marginBottom: '5px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    label: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      color: theme.custom.textColor.gray,
      opacity: '0.8',
      '@media (max-width: 1023px)': {
        fontSize: '11px',
      },
    },
    error: {
      color: `${theme.custom.error}!important`,
    },
    fieldError: {
      border: `1px solid ${theme.custom.error}`,
      borderRadius: '4px',
    },
  };
}
