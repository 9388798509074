import { withStyles } from '@material-ui/core';
import React from 'react';

const colorTheme = {
  Connected: (theme) => theme.custom.textColor.secondary,
  Disconnected: (theme) => theme.custom.error,
  Pending: (theme) => theme.palette.secondary.main,
  Default: (theme) => theme.custom.textColor.primary,
};

const GatewayStatusIndicator = withStyles((theme) => ({
  statusIndicator: {
    color: ({ status = 'Default' }) => colorTheme[status](theme),
    border: ({ status = 'Default' }) => `1px solid ${colorTheme[status](theme)}`,
    padding: '4px 10px',
    fontSize: '9px',
    borderRadius: '16px',
    display: 'block',
    width: 'fit-content',
    textTransform: 'uppercase',
    letterSpacing: 2,
  },
}))(({ classes, status }) => (
  <span className={classes.statusIndicator}>
    {status}
  </span>
));

export default GatewayStatusIndicator;
