export default function styles() {
  return {
    infoField: {
      marginBottom: 20,
    },
    label: {
      fontSize: 12,
      color: '#555555',
      marginBottom: 10,
    },
    value: {
      fontSize: 14,
    },
  };
}
