import { Map, fromJS } from 'immutable';

import * as actions from './actions';
import { CREATE_LOCATION_SUCCESS } from '../locations/actions';
import { ADD_GATEWAY__SUCCESS, UNASSIGN_GATEWAY__SUCCESS } from '../gateway/actions';

export const initialState = Map({});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UNASSIGN_GATEWAY__SUCCESS: {
      const gateways = state.get('gateways');
      const updateGateways = gateways.filter((item) => item.get('id') !== payload?.id);
      return state.set('gateways', updateGateways);
    }
    case ADD_GATEWAY__SUCCESS: {
      const gateways = state.get('gateways');
      const updateGateways = gateways.set(0, payload);
      return state.set('gateways', updateGateways);
    }
    case actions.GET_LOCATION_SUCCESS:
    case actions.UPDATE_LOCATION_SUCCESS:
    case CREATE_LOCATION_SUCCESS: {
      return fromJS(payload);
    }
    case actions.CLEAR_LOCATION_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
