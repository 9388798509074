import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { getData } from 'country-list';
import ReactCountryFlag from 'react-country-flag';
import PhoneNumber from 'awesome-phonenumber';

import { ReactComponent as OpenInViewIcon } from '../../../../assets/icons/openInView.svg';
import FormBlock from '../../../../components/FormElements/FormBlock';
import CustomButton from '../../../../components/CustomButton';
import { getUserProfile } from '../../../../redux/reducers/userProfile/selectors';
import InfoField from '../../../../components/InfoField';
import Avatar from '../../../../components/Avatar';
import { getCookie } from '../../../../utils/cookies';
import { convertTimeZone, parseTimeZoneJson } from './utils';

function RAKInformation({ classes }) {
  const {
    country,
    phoneNumber,
    profilePhoto,
    dateFormat,
    email,
    name,
    zoneinfo,
  } = useSelector(getUserProfile);

  const getRAKIDLink = () => {
    const baseURL = process.env.REACT_APP_RAK_WIRELESS_URL;
    const rakIdRefToken = getCookie('rakIdRefToken');

    return `${baseURL}force-login?refreshToken=${rakIdRefToken}`;
  };

  return (
    <FormBlock
      label="RAKInfoLabel"
      subLabel="RAKInfoSubLabel"
    >
      {
        profilePhoto && (
          <div className={classes.avatarWrap}>
            <Avatar profilePhoto={profilePhoto} size="large" />
          </div>
        )
      }

      <InfoField
        label={<FormattedMessage id="RAKInfoName" />}
        value={name}
      />

      <InfoField
        label={<FormattedMessage id="RAKInfoEmail" />}
        value={email}
      />

      <div className={classes.multiInfoField}>
        {
          country && (
            <InfoField
              label={<FormattedMessage id="RAKInfoCountry" />}
              value={(
                <div className={classes.countryValue}>
                  <ReactCountryFlag countryCode={country} svg />

                  <span>
                    {getData().find(({ code }) => code === country).name}
                  </span>
                </div>
              )}
            />
          )
        }

        {
          phoneNumber && (
            <InfoField
              label={<FormattedMessage id="RAKInfoPhoneNumber" />}
              value={(() => {
                const pn = new PhoneNumber(phoneNumber, country);

                return pn.getNumber('international');
              })()}
            />
          )
        }
      </div>

      <InfoField
        label={<FormattedMessage id="RAKInfoTimeZone" />}
        value={convertTimeZone(parseTimeZoneJson(zoneinfo))}
      />

      <InfoField
        label={<FormattedMessage id="RAKInfoDateFormat" />}
        value={dateFormat}
      />

      <CustomButton
        additionalClass={classes.buttonInfo}
        variant="outlined"
        color="primary"
      >
        <a
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer"
          href={getRAKIDLink()}
        >
          <FormattedMessage id="RAKInfoEditButton" />
          <OpenInViewIcon />
        </a>
      </CustomButton>
    </FormBlock>
  );
}

RAKInformation.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

RAKInformation.defaultProps = {
  classes: {},
};

export default RAKInformation;
