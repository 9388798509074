import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as NoticeIcon } from '../../assets/icons/errorCircle.svg';
import { isDisconnect, isRequired } from '../../utils/validations';
import CustomModal from '../CustomModal';
import TextFormField from '../FormElements/TextField';
import styles from './styles';

function DisconnectAccountModal({
  classes,
  isOpen,
  handleModalState,
  handleSubmit,
  submitting,
  hasGateways,
  valid,
  pristine,
}) {
  return (
    <>
      <CustomModal
        isOpen={isOpen}
        handleClose={handleModalState}
        contentClass={classes.modalContent}
        titleId="disconnectAccountModalTitle"
        submitteText="disconnectAccountModalBtn"
        cancelText="close"
        isSubmiting={submitting}
        onSubmite={handleSubmit}
        disableSubmitte={!valid || pristine || submitting}
      >
        <div className={classes.noticeWrap}>
          <div className={classes.noticeIcon}>
            <NoticeIcon />
          </div>

          <div className={classes.noticeText}>
            <div><FormattedMessage id="disconnectAccountModalNotice1" /></div>

            {
              hasGateways && (
                <>
                  <br />
                  <div><FormattedMessage id="disconnectAccountModalNotice2" /></div>
                </>
              )
            }
          </div>
        </div>

        <div className={classes.formWrap}>
          <form onSubmit={handleSubmit}>
            <Field
              wrapClassName={classes.fieldWrap}
              name="disconnect"
              component={TextFormField}
              validate={[isRequired, isDisconnect]}
              label={<FormattedMessage id="disconnectAccountModalFieldLabel" />}
              disabled={submitting}
            />
          </form>
        </div>
      </CustomModal>
    </>
  );
}

DisconnectAccountModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  handleModalState: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  hasGateways: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

DisconnectAccountModal.defaultProps = {
  classes: {},
};

export default compose(
  reduxForm({}),
  withStyles(styles),
)(DisconnectAccountModal);
