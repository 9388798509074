import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Tooltip from '@material-ui/core/Tooltip';
import copyIcon from '../../assets/icons/copyIcon.svg';
import checkIcon from '../../assets/icons/check.svg';
import styles from './styles';

const FieldWithCopy = ({
  valueForCopy,
  children,
  classes,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const iconRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [iconRef]);

  const tooltipContent = (
    <div
      className={classes.tooltipContent}
    >
      <img className={classes.check} src={checkIcon} alt="icon" />
      <FormattedMessage id="copied" />
    </div>
  );

  const handleCopyValue = () => setShowTooltip(true);

  return (
    <div ref={iconRef}>
      <CopyToClipboard
        text={valueForCopy}
        onCopy={handleCopyValue}
      >
        <div className={classes.wrap}>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            title={tooltipContent}
            open={showTooltip}
            placement="top"
            arrow
          >
            <div className={classes.copy}><img src={copyIcon} alt="icon" /></div>
          </Tooltip>
          {children}
        </div>
      </CopyToClipboard>
    </div>
  );
};

FieldWithCopy.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  valueForCopy: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

FieldWithCopy.defaultProps = {
  classes: {},
};

export default withStyles(styles)(FieldWithCopy);
