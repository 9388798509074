export default function styles(theme) {
  return {
    wrap: {
      display: 'flex',
      cursor: 'pointer',
      width: 'fit-content',
    },
    copy: {
      marginRight: '8px',
      marginTop: '2px',
    },
    tooltip: {
      width: '62px',
      height: '100%',
      background: theme.custom.tooltipColor,
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
      opacity: 0.5,
      textAlign: 'center',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      padding: '10px',
      '&$open': {
        opacity: 0.5,
      },
    },
    arrow: {
      color: theme.custom.tooltipColor,
      height: '2em',
    },
    tooltipContent: {
      color: theme.custom.textColor.primary,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
  };
}
