export default function styles(theme) {
  return {
    button: {
      borderRadius: '36px',
      textTransform: 'none',
      color: theme.custom.textColor.primary,
      backgroundColor: theme.palette.primary.main,
      boxShadow: 'none',
      height: '50px',
      minWidth: '165px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0px 0px 4px 4px ${theme.palette.primary.light}`,
        transition: 'box-shadow 0.3s ease-in-out',
      },
      '&:active': {
        boxShadow: `0px 0px 4px 4px ${theme.palette.primary.dark}`,
      },
      '&:disabled': {
        background: theme.palette.primary.main,
        opacity: '0.4',
        borderRadius: '36px',
        color: theme.custom.textColor.primary,
      },
    },
    loader: {
      color: theme.custom.textColor.primary,
    },
  };
}
