import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import Checkbox from '@material-ui/core/Checkbox';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import omit from 'lodash/omit';

import TextFormField from '../../../../components/FormElements/TextField';
import PasswordField from '../../../../components/FormElements/PasswordField';
import ShowPasswordField from '../../../../components/FormElements/ShowPasswordField';
import CustomButton from '../../../../components/CustomButton';
import {
  isMatchPasswords,
  isRequired,
  isValidEmail,
  isValidNameLength,
  isValidPassword,
  isValidRAKID,
} from '../../../../utils/validations';
import { signUp } from '../../../../redux/reducers/auth/actions';
import styles from './styles';
import TermsPolicyModal from '../../../../components/TermsPolicyModal';
import { ReactComponent as CheckboxIcon } from '../../../../assets/icons/checkboxIcon.svg';

const RegistrationForm = ({
  classes,
  handleSubmit,
  valid,
  pristine,
  loading,
}) => {
  const selector = formValueSelector('registrationForm');
  const dispatch = useDispatch();
  const password = useSelector((state) => selector(state, 'password'));
  const signUpRequest = (data) => dispatch(signUp(data));

  const [isVerify, setIsVerify] = useState(false);
  const [isAgreeTerms, setIsAgreeTerms] = useState(false);

  const handleVerify = () => {
    setIsVerify(true);
  };

  const handleSignUp = (values) => {
    const filterVal = omit(values, 'confirmPassword');
    const data = {
      ...filterVal,
    };

    signUpRequest(data);
  };

  const handleAgreeTerms = ({ target: { checked } }) => {
    setIsAgreeTerms(checked);
  };

  return (
    <div className={classes.wrap}>
      <form onSubmit={handleSubmit(handleSignUp)}>
        <div className={classes.fieldWrap}>
          <Field
            name="name"
            component={TextFormField}
            validate={[isValidNameLength, isValidRAKID, isRequired]}
            label={<FormattedMessage id="name" />}
          />
        </div>

        <div className={classes.fieldWrap}>
          <Field
            name="email"
            component={TextFormField}
            validate={[isValidEmail, isRequired]}
            label={<FormattedMessage id="email" />}
            tooltipId="emailFieldTooltip"
          />
        </div>

        <div className={classes.fieldWrap}>
          <Field
            name="password"
            component={PasswordField}
            validate={[isRequired, isValidPassword]}
          />
        </div>

        <div className={classes.fieldWrap}>
          <Field
            name="confirmPassword"
            label={<FormattedMessage id="confirmPassword" />}
            component={ShowPasswordField}
            validate={[isMatchPasswords]}
            password={password}
            withMatch
          />
        </div>

        <div className={classes.fieldWrap}>
          <div className={classes.termsWrap}>
            <Checkbox
              defaultChecked
              color="primary"
              checked={isAgreeTerms}
              onChange={handleAgreeTerms}
              className={classes.checkBox}
              icon={<span className={classes.icon} />}
              checkedIcon={<CheckboxIcon />}
            />
            <div className={classes.termsLabel}>
              <FormattedMessage id="iAgreeWith" />
            </div>
            <TermsPolicyModal />
          </div>
        </div>

        <div className={classes.captcha}>
          <HCaptcha
            sitekey="f2675017-0a0e-4733-9a54-a2dbd1895bae"
            onVerify={handleVerify}
          />
        </div>

        <div className={classes.buttonBlock}>
          <CustomButton
            type="submit"
            variant="contained"
            color="secondary"
            loading={loading}
            additionalClass={classes.button}
            disabled={!valid || pristine || !isVerify || loading || !isAgreeTerms}
          >
            <FormattedMessage id="signUp" />
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

RegistrationForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  valid: PropTypes.bool,
  loading: PropTypes.bool,
};

RegistrationForm.defaultProps = {
  pristine: false,
  loading: false,
  valid: false,
  classes: {},
};

export default compose(
  withStyles(styles),

  reduxForm({
    form: 'registrationForm',
    enableReinitialize: true,
  }),
)(RegistrationForm);
