import { put, takeEvery } from 'redux-saga/effects';
import React from 'react';

import { FormattedMessage } from 'react-intl';
import * as locationActions from '../reducers/location/actions';
import { showNotification } from '../reducers/notifications/actions';
import notificationsTypes from '../../contants/notificationsTypes';

export function* handleNotifyUpdateLocation() {
  yield put(
    showNotification({
      type: notificationsTypes.success,
      message: <FormattedMessage id="locationSuccessUpdated" />,
    }),
  );
}

export function* watchLocation() {
  yield takeEvery(locationActions.NOTIFY_UPDATE_LOCATION, handleNotifyUpdateLocation);
}
