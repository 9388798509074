export default function styles(theme) {
  return {
    buttonWarning: {
      marginBottom: 16,
      background: 'none',
      color: theme.custom.error,
      border: `1px solid ${theme.custom.error}`,

      '&:hover': {
        color: theme.custom.textColor.primary,
        background: theme.custom.error,
        border: `1px solid ${theme.custom.error}`,
        boxShadow: `0px 0px 1px 1px ${theme.custom.error}`,
      },
    },
    hint: {
      color: theme.custom.textColor.gray,
      fontSize: '14px',
      opacity: 0.6,
    },
  };
}
