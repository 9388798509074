import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import { get } from 'lodash';

import * as authActions from '../reducers/auth/actions';
import * as apiActions from '../reducers/api/actions';
import history from '../../utils/history';
import { getCookie, removeCookie, setCookie } from '../../utils/cookies';
import { setItem } from '../../utils/localStorageHelper';
import ROUTES from '../../contants/routes';
import { getIsLoggedInState } from '../reducers/auth/selectors';
import { checkProductConnect, setLogoutStatus } from '../reducers/auth/actions';

const redirectToEmailCheck = () => {
  history.replace(ROUTES.EMAIL_VERIFICATION.INDEX);
};

const redirectToExpireEmailCheck = (type) => {
  history.push(ROUTES.EMAIL_VERIFICATION.createPath({ type }));
};

const redirectToLogin = () => {
  history.replace(ROUTES.LOGIN.INDEX);
};

const redirectToTokenStatusPage = (type) => {
  history.push(ROUTES.TOKEN_STATUS.createPath({ type }));
};

export function* handleSuccessSignUp(action) {
  const email = get(action, ['payload', 'email'], null);
  setItem('userEmail', email);
  yield call(redirectToEmailCheck);
}

function* removeAuthCookies() {
  yield call(removeCookie, 'refreshToken');
  yield call(removeCookie, 'accessToken');
  yield call(removeCookie, 'tokenExpireTime');
  yield call(removeCookie, 'rakIdRefToken');
  yield call(removeCookie, 'rakIdAccessToken');
}

export function* setTokens(action) {
  yield call(removeAuthCookies);
  const refToken = get(action, ['payload', 'wisHm', 'refreshToken'], null);
  const accessToken = get(action, ['payload', 'wisHm', 'accessToken'], null);
  const rakIdRefToken = get(action, ['payload', 'rakId', 'refreshToken'], null);
  const rakIdAccessToken = get(action, ['payload', 'rakId', 'accessToken'], null);
  const expTime = `${new Date().getHours() + 1}${new Date().getMinutes()}`;

  yield call(setCookie, { key: 'refreshToken', value: refToken });
  yield call(setCookie, { key: 'accessToken', value: accessToken });
  yield call(setCookie, { key: 'tokenExpireTime', value: expTime });
  yield call(setCookie, { key: 'rakIdRefToken', value: rakIdRefToken });
  yield call(setCookie, { key: 'rakIdAccessToken', value: rakIdAccessToken });
}

export function* handleCheckConnectProduct() {
  yield put(checkProductConnect());
}

export function* refreshToken() {
  const wisHmRefToken = yield call(getCookie, 'refreshToken');
  const rakIdRefToken = yield call(getCookie, 'rakIdRefToken');

  yield put(authActions.refreshToken({
    refreshToken: wisHmRefToken,
    refreshRakIdToken: rakIdRefToken,
  }));
}

export function* checkToken() {
  const refToken = getCookie('refreshToken');
  const isLoggedIn = yield select(getIsLoggedInState);

  if (refToken) {
    yield call(refreshToken);
  } else if (!isLoggedIn) {
    yield put(authActions.resetGlobalLoading());
  } else {
    yield put(authActions.logOut());
  }
}

export function* handleUnauthorizedError(action) {
  const responseStatus = get(action, [
    'payload',
    'response',
    'data',
    'statusCode',
  ]);
  if (responseStatus === 401) {
    yield call(checkToken, action);
  }
}

export function* handleUpdateToken(action) {
  yield call(removeCookie, 'accessToken');
  yield call(removeCookie, 'rakIdAccessToken');
  yield call(removeCookie, 'tokenExpireTime');

  const accessToken = get(action, ['payload', 'wisHm', 'accessToken'], null);
  const rakIdAccessToken = get(action, ['payload', 'rakId', 'accessToken'], null);
  const expTime = `${new Date().getHours() + 1}${new Date().getMinutes()}`;

  yield call(setCookie, { key: 'accessToken', value: accessToken });
  yield call(setCookie, { key: 'rakIdAccessToken', value: rakIdAccessToken });
  yield call(setCookie, { key: 'tokenExpireTime', value: expTime });
}

export function* handleExpireEmail(action) {
  const responseCode = get(action, ['payload', 'code'], null);
  const confirmCode = 'UserNotConfirmedException';
  const expireCode = 'ExpiredCodeException';

  if (responseCode && responseCode === confirmCode) {
    yield call(redirectToEmailCheck);
  }

  if (responseCode && responseCode === expireCode) {
    yield call(redirectToExpireEmailCheck, 'expired');
  }
}

export function* handleForgotPasswordError(action) {
  const responseCode = get(action, ['payload', 'code'], null);
  if (responseCode && responseCode === 'InvalidParameterException') {
    yield call(redirectToExpireEmailCheck, 'unverified');
  }
}

export function* handleConfirmPasswordError(action) {
  const responseCode = get(action, ['payload', 'code'], null);
  if (responseCode && responseCode === 'ExpiredCodeException') {
    yield call(redirectToTokenStatusPage, 'expired');
  }
  if (responseCode && responseCode === 'CodeMismatchException') {
    yield call(redirectToTokenStatusPage, 'invalid');
  }
}

export function* handleCheckConnectProductError() {
  yield call(removeAuthCookies);
  history.replace('/');
  yield put(setLogoutStatus({ connectedError: true }));
}

export function* handleLogout() {
  yield call(removeAuthCookies);
  history.replace('/');
}

export function* handleRedirectToLogin() {
  yield call(redirectToLogin);
}

export function* watchAuth() {
  yield takeEvery(apiActions.API_REQUEST_ERROR, handleUnauthorizedError);
  yield takeEvery(authActions.AUTH_FORGOT_PASSWORD_ERROR, handleForgotPasswordError);
  yield takeEvery(authActions.AUTH_CONFIRM_PASSWORD_ERROR, handleConfirmPasswordError);
  yield takeEvery(authActions.AUTH_CONFIRM_PASSWORD_SUCCESS, handleRedirectToLogin);
  yield takeEvery(authActions.AUTH_CONFIRM_EMAIL_ERROR, handleExpireEmail);
  yield takeEvery(authActions.AUTH_SIGN_UP_SUCCESS, handleSuccessSignUp);
  yield takeEvery(authActions.AUTH_SIGN_IN_SUCCESS, handleCheckConnectProduct);
  yield takeEvery(authActions.AUTH_SIGN_IN_SUCCESS, setTokens);
  yield takeEvery(authActions.AUTH_SIGN_IN_ERROR, handleExpireEmail);
  yield takeEvery(authActions.REFRESH_TOKEN_SUCCESS, handleUpdateToken);
  yield takeEvery(authActions.REFRESH_TOKEN_SUCCESS, handleCheckConnectProduct);
  yield takeEvery(authActions.AUTH_CHECK_PRODUCT_CONNECT_ERROR, handleCheckConnectProductError);
  yield takeEvery(authActions.CHECK_TOKEN, checkToken);
  yield takeEvery(authActions.REFRESH_TOKEN_ERROR, handleLogout);
  yield takeEvery(authActions.LOG_OUT, handleLogout);
}
