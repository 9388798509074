import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import unmatchIcon from '../../../../assets/icons/unmatch.svg';
import matchIcon from '../../../../assets/icons/match.svg';

const PasswordTooltip = ({
  classes,
  matchLvl,
  children,
  showTooltip,
}) => {
  const tooltipContent = (
    <div className={classes.tooltipContent}>
      <div className={classes.tooltipText}>
        <img className={classes.tooltipIcon} src={matchLvl.length ? matchIcon : unmatchIcon} alt="icon" />
        <FormattedMessage id="passwordLength" />
      </div>
      <div className={classes.tooltipText}>
        <img className={classes.tooltipIcon} src={matchLvl.symbol ? matchIcon : unmatchIcon} alt="icon" />
        <FormattedMessage id="passwordSymbol" />
      </div>
      <div className={classes.tooltipText}>
        <img className={classes.tooltipIcon} src={matchLvl.number ? matchIcon : unmatchIcon} alt="icon" />
        <FormattedMessage id="passwordNumber" />
      </div>
      <div className={classes.tooltipText}>
        <img className={classes.tooltipIcon} src={matchLvl.letter ? matchIcon : unmatchIcon} alt="icon" />
        <FormattedMessage id="passwordLetter" />
      </div>
    </div>
  );

  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
      title={tooltipContent}
      placement={window?.innerWidth <= 1023 ? 'bottom-start' : 'right-start'}
      open={showTooltip}
      arrow
    >
      {children}
    </Tooltip>
  );
};

PasswordTooltip.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  matchLvl: PropTypes.objectOf(PropTypes.bool),
  children: PropTypes.node.isRequired,
  showTooltip: PropTypes.bool,
};

PasswordTooltip.defaultProps = {
  classes: {},
  matchLvl: {},
  showTooltip: false,
};

export default withStyles(styles)(PasswordTooltip);
