export default function styles(theme) {
  return {
    input: {
      width: '100%',
      '-webkit-box-shadow': 'inset 0 0 0 30px #fff',
      color: 'rgba(0,0,0,.8) !important',
      fontFamily: 'Inter',
      fontSize: '14px',
      lineHeight: '20px',
    },
    inputWrap: {
      width: '100%',
      height: '48px',
      background: 'transparent',
      border: ({ meta: { error = false, touched = false } = {} } = {}) => (
        error && touched
          ? `1px solid ${theme.custom.error}`
          : `1px solid ${theme.custom.inputBorderColor}`
      ),
      transition: 'all .2s',
      boxSizing: 'border-box',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      padding: '16px',
      position: 'relative',
      '&:hover': {
        border: ({ meta: { error = false, touched = false } = {} } = {}) => (
          error && touched
            ? `1px solid ${theme.custom.error}`
            : '1px solid rgba(0, 0, 0, 0.4)'
        ),
      },
    },
    multiline: {
      minHeight: '100px',
      height: 'auto',
      padding: '10px',
      '& .MuiInputBase-multiline': {
        padding: 0,
      },
    },
    disabled: {
      color: theme.custom.textColor.gray,
      opacity: 0.4,
      pointerEvents: 'none',
    },
  };
}
