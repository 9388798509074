import { useEffect, useState } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

import { runSaga } from '../../../../store';
import { sendApiRequest } from '../../../../redux/sagas/apiRequests';
import { getGateways } from '../../../../redux/reducers/gateways/actions';
import useTable from '../../../../components/TableCustom/useTable';
import { getGatewaysEntitiesList } from '../../../../redux/reducers/gateways/selectors';
import gatewaysAdapter from './utils';

function useFetchGateways() {
  const [loading, setLoading] = useState(true);
  const [isFirstLoading, setFirstLoading] = useState(true);
  const rawEntities = useSelector(getGatewaysEntitiesList);

  const {
    entities,
    page,
    handleChangePage,
    perPage,
    handleChangePerPage,
    itemCount,
    setItemCount,
    selectedIds,
    handleSelectId,
    handleSelectAllIds,
    handleClearSelectedIds,
  } = useTable(
    (
      pageState,
      perPageState,
    ) => gatewaysAdapter({
      entities: rawEntities,
      page: pageState,
      perPage: perPageState,
    }),
  );

  async function fetchGateways() {
    const fetchTask = runSaga(
      sendApiRequest,
      getGateways(),
    );

    setLoading(true);

    const {
      ok,
      result,
    } = await fetchTask.toPromise();

    if (ok) {
      const count = get(result, 'payload.count', 0);
      setItemCount(count);
    }

    if (fetchTask.isCancelled()) {
      return;
    }

    setLoading(false);
    setFirstLoading(false);
  }

  useEffect(() => {
    fetchGateways();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isFirstLoading,
    reFetchGateways: (deletedEntities) => {
      handleClearSelectedIds();
      setItemCount(itemCount - deletedEntities.length);
      if (deletedEntities.length === entities.length && page !== 1) {
        handleChangePage(page - 1);
      }
    },
    loading,
    entities,
    itemCount,
    page,
    handleChangePage,
    perPage,
    handleChangePerPage,
    selectedIds,
    handleSelectId,
    handleSelectAllIds,
  };
}

export default useFetchGateways;
