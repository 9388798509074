import { createSelector } from 'reselect';

export const getGatewaysState = (state) => state.gateways;

export const getGatewayDetails = createSelector(
  [getGatewaysState],
  (gateways) => gateways
    .get('gatewayDetails')
    .toJS(),
);

export const getGatewaysEntitiesList = createSelector(
  [getGatewaysState],
  (gateways) => gateways
    .get('entities')
    .toList()
    .toJS(),
);

export const getGatewaysIdsList = createSelector(
  [getGatewaysState],
  (gateways) => gateways
    .get('ids')
    .toJS(),
);

export const getGatewaysCount = createSelector(
  [getGatewaysState],
  (gateways) => gateways.get('count'),
);

export const getLocationGatewaysList = createSelector(
  [getGatewaysState],
  (gateways) => gateways.get('locationGateways').toJS(),
);

export const getOrganizationsGatewaysList = createSelector(
  [getGatewaysState],
  (gateways) => gateways.get('organizationsGateways').toJS(),
);
