export default function styles(theme) {
  return {
    titleLink: {
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette.primary.main,
      textDecoration: 'none',
      borderBottom: `2px dotted ${theme.palette.primary.main}`,
      transition: 'all .2s',
      '&:hover': {
        color: theme.custom.linkColor,
        borderBottom: `2px dotted ${theme.custom.linkColor}`,
      },
    },
    content: {
      fontSize: '16px',
      lineHeight: '24px',
      width: '840px',
      color: theme.custom.textColor.gray,
      '@media (max-width:1023px)': {
        width: 'auto',
      },
    },
    footerLink: {
      fontSize: '10px',
      lineHeight: '16px',
      color: theme.custom.textColor.gray,
      borderBottom: `1.5px dotted ${theme.custom.textColor.gray}`,
      opacity: '0.7',
      '&:hover': {
        opacity: 1,
        color: theme.custom.textColor.gray,
        borderBottom: `1.5px dotted ${theme.custom.textColor.gray}`,
      },
    },
  };
}
