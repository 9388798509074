import { Map, fromJS, set } from 'immutable';

import * as actions from './actions';
import { CLEAR_LOCATION_DATA } from '../location/actions';

export const initialState = Map({});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.CLEAR_GATEWAY_DATA:
    case CLEAR_LOCATION_DATA: {
      return initialState;
    }
    case actions.GET_GATEWAY_BY_ORG_SUCCESS:
    case actions.GET_GATEWAY_BY_ID_SUCCESS: {
      return fromJS(payload);
    }
    case actions.RENAME_GATEWAY__SUCCESS: {
      return set(state, 'name', payload?.name);
    }
    default:
      return state;
  }
};
