export default function styles(theme) {
  return {
    wrap: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      marginRight: '5px',
      borderRadius: '5px',
      padding: '16px 20px',
      display: 'flex',
      flexDirection: 'column',
    },
    error: {
      background: theme.custom.errorLight,
      color: theme.custom.error,
    },
    info: {
      background: theme.palette.primary.info,
      color: theme.custom.textColor.blue,
    },
  };
}
