import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';

import styles from './styles';
import toastDuration from './constants';

const StyledToast = ({ classes }) => (
  <ToastContainer
    className={classes.root}
    autoClose={toastDuration}
    position="bottom-center"
    draggable={false}
  />
);

StyledToast.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

StyledToast.defaultProps = {
  classes: {},
};

export default withStyles(styles)(StyledToast);
