export default function styles(theme) {
  return {
    footer: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '10px',
      lineHeight: '16px',
      color: theme.custom.textColor.gray,
      opacity: '0.6',
      justifyContent: 'space-between',
      '@media (max-width: 1023px)': {
        flexDirection: 'column-reverse',
        alignItems: 'baseline',
      },
    },
    footerLink: {
      fontSize: '10px',
      lineHeight: '16px',
      color: theme.custom.textColor.gray,
      borderBottom: `1.5px dotted ${theme.custom.textColor.gray}`,
      opacity: '0.7',
      '&:hover': {
        opacity: 1,
        cursor: 'pointer',
        color: theme.custom.textColor.gray,
        borderBottom: `1.5px dotted ${theme.custom.textColor.gray}`,
      },
    },
    footerLinkIcon: {
      display: 'flex',
      textDecoration: 'none',
      color: 'currentColor',
    },
    termsWrap: {
      display: 'flex',
      alignItems: 'center',
      '@media (max-width: 1023px)': {
        marginBottom: '13px',
      },
    },
    copyright: {
      '@media (max-width: 1023px)': {
        width: '230px',
      },
    },
    separator: {
      margin: '0 8px',
    },
    icon: {
      marginRight: '8px',
    },
  };
}
