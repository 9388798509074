export default function styles(theme) {
  return {
    wrap: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      margin: '100px 0 110px 0',
      '@media (max-width: 1023px)': {
        margin: '40px 0 130px 0',
      },
    },
    titleWrap: {
      marginBottom: '44px',
      width: '100%',
    },
    errorWrap: {
      marginTop: '40px',
    },
    title: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '29px',
      lineHeight: '36px',
      letterSpacing: '-0.01em',
      marginBottom: '10px',
    },
    subTitle: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.custom.textColor.gray,
      opacity: '0.8',
      marginRight: '5px',
    },
    titleLink: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette.primary.main,
      textDecoration: 'none',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      borderBottomStyle: 'dotted',
      transition: 'all .2s',
      '&:hover': {
        color: theme.custom.linkColor,
        borderBottom: `2px dotted ${theme.custom.linkColor}`,
      },
    },
  };
}
