import { createSelector } from 'reselect';

export const getIsLoggedInState = createSelector(
  (state) => state.auth.get('isLoggedIn'),
  (isLoggedIn) => isLoggedIn,
);

export const getIsLoading = createSelector(
  (state) => state.auth.get('isLoading'),
  (isLoading) => isLoading,
);

export const getLogoutStatus = createSelector(
  (state) => state.auth.get('logoutStatus'),
  (logoutStatus) => logoutStatus,
);

export const getIsConnectedProduct = createSelector(
  (state) => state.auth.get('isConnectedProduct'),
  (isConnectedProduct) => isConnectedProduct,
);
