import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as NoticeIcon } from '../../assets/icons/errorCircle.svg';
import { isRelocate, isRequired } from '../../utils/validations';
import CustomModal from '../CustomModal';
import TextFormField from '../FormElements/TextField';
import styles from './styles';

function ConfirmRelocateGatewayModal({
  classes,
  isOpen,
  toggleModalState,
  handleSubmit,
  submitting,
  valid,
  pristine,
}) {
  return (
    <CustomModal
      isOpen={isOpen}
      handleClose={toggleModalState}
      contentClass={classes.modalContent}
      titleId="gatewayConfirmRelocateModalTitle"
      submitteText="gatewayConfirmRelocateModalButton"
      cancelText="close"
      isSubmiting={submitting}
      disableSubmitte={!valid || pristine || submitting}
      onSubmite={handleSubmit}
    >
      <div className={classes.noticeWrap}>
        <div className={classes.noticeIcon}>
          <NoticeIcon />
        </div>

        <div className={classes.noticeText}>
          <FormattedMessage id="gatewayConfirmRelocateModalNotice" />
        </div>
      </div>

      <div className={classes.formWrap}>
        <form onSubmit={handleSubmit}>
          <Field
            wrapClassName={classes.fieldWrap}
            name="relocate"
            component={TextFormField}
            validate={[isRequired, isRelocate]}
            label={<FormattedMessage id="gatewayConfirmRelocateModalFieldLabel" />}
            disabled={submitting}
          />
        </form>
      </div>
    </CustomModal>
  );
}

ConfirmRelocateGatewayModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModalState: PropTypes.func.isRequired,
};

export default compose(
  reduxForm({}),
  withStyles(styles),
)(ConfirmRelocateGatewayModal);
