import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';

import GatewayInfoField from '../../components/GatewayInfoField';
import gatewayDetailsPropTypes from './propTypes';

function GatewayBaseInfo({
  classes,
  gatewayDetails,
}) {
  return (
    <div className={classes.root}>
      <div>
        <GatewayInfoField withCopy isUpperCase label="serialNumber" value={gatewayDetails.sn} />
        <GatewayInfoField label="frequencyBand" value={`${gatewayDetails.freqBand} MHz`} />
        <GatewayInfoField withCopy label="model" value={gatewayDetails.model} />
      </div>

      <div>
        <GatewayInfoField withCopy isUpperCase label="eui" value={gatewayDetails.eui} />
        <GatewayInfoField withCopy label="macAddress" value={gatewayDetails.mac} />
      </div>
    </div>
  );
}

GatewayBaseInfo.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  gatewayDetails: gatewayDetailsPropTypes.isRequired,
};

export default compose(withStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',

    '& > div': {
      width: '49%',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    '& > div > div:not(:last-child)': {
      marginBottom: 30,
    },

    [theme.breakpoints.down('sm')]: {
      display: 'block',

      '& > div > div': {
        marginBottom: 30,
      },
    },
  },
})))(GatewayBaseInfo);
