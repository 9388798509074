import PropTypes from 'prop-types';

export const gatewayPropTypes = PropTypes.shape({
  status: PropTypes.string,
  name: PropTypes.string,
  sn: PropTypes.string,
  eui: PropTypes.string,
});

export const gatewayListPropTypes = PropTypes.arrayOf(gatewayPropTypes);

const gatewayTablePropTypes = {
  loading: PropTypes.bool.isRequired,
  entities: gatewayListPropTypes,
  itemCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  perPage: PropTypes.number.isRequired,
  handleChangePerPage: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSelectId: PropTypes.func.isRequired,
  handleSelectAllIds: PropTypes.func.isRequired,
  reFetchGateways: PropTypes.func.isRequired,
};

export default gatewayTablePropTypes;
