import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import CustomButton from '../../../../../../components/CustomButton';
import DeleteGatewayModal from '../../../../../../components/DeleteGatewayModal';

function BulkActions({
  classes,
  isOpen,
  toggleModalState,
  deleteGatewayCount,
  handleDeleteGateways,
}) {
  return (
    <>
      <CustomButton
        onClick={toggleModalState}
        additionalClass={classes.deleteButton}
        type="submit"
        variant="outlined"
        color="primary"
      >
        <FormattedMessage id="gatewayDeleteBtn" />
      </CustomButton>

      {
        isOpen && (
          <DeleteGatewayModal
            form="DELETE_GATEWAY_FORM"
            onSubmit={handleDeleteGateways}
            isOpen={isOpen}
            toggleModalState={toggleModalState}
            deleteGatewayCount={deleteGatewayCount}
          />
        )
      }
    </>
  );
}

BulkActions.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleDeleteGateways: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModalState: PropTypes.func.isRequired,
  deleteGatewayCount: PropTypes.number.isRequired,
};

export default withStyles((theme) => ({
  deleteButton: {
    minWidth: 95,
    height: 40,
    margin: ({ isMobile = false }) => (isMobile ? 0 : '7px 0'),
    background: ({ isDeleteProcess }) => (isDeleteProcess
      ? theme.palette.primary.main
      : 'none'),
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,

    '&:hover': {
      color: theme.custom.textColor.primary,
      background: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `0px 0px 1px 1px ${theme.palette.primary.main}`,
    },
  },
}))(BulkActions);
