import { takeEvery, put, call } from 'redux-saga/effects';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { get } from 'lodash';
import * as organizationsActions from '../reducers/organization/actions';
import { showNotification } from '../reducers/notifications/actions';
import ROUTES from '../../contants/routes';

const { REACT_APP_API_PREFIX } = process.env;

const redirectToCommonDashboard = () => {
  window.location.replace(`https://${REACT_APP_API_PREFIX}${ROUTES.ORGANIZATIONS.DEFAULT_PATH}`);
};

export function* handleSuccessUpdateOrg() {
  yield put(
    showNotification({
      type: 'success',
      message: <FormattedMessage id="orgSuccessUpdated" />,
    }),
  );
}

export function* handleErrorGetOrg(action) {
  const responseCode = get(action, ['payload', 'message'], null);
  const nowAllowedMessage = 'You are not allowed to do anything within this organization';

  if (responseCode && responseCode === nowAllowedMessage) {
    yield call(redirectToCommonDashboard);
  }
}

export function* watchOrganization() {
  yield takeEvery(organizationsActions.UPDATE_ORGANIZATION_SUCCESS, handleSuccessUpdateOrg);
  yield takeEvery(organizationsActions.GET_ORGANIZATION_BY_SUB_DOMAIN_ERROR, handleErrorGetOrg);
}
