export default function styles(theme) {
  return {
    heliumWalletForm: {
      '& > *': {
        marginBottom: 24,
      },
    },
    submitButton: {
      background: ({ submitting }) => (submitting
        ? theme.palette.primary.main
        : 'none'),
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,

      '&:hover': {
        color: theme.custom.textColor.primary,
        background: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: `0px 0px 1px 1px ${theme.palette.primary.main}`,
      },

      '&[disabled]': {
        background: 'none',
        color: theme.palette.primary.main,
      },
    },
    notHaveAccount: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px 20px',
      borderRadius: '8px',
      background: theme.custom.backgroundColor,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.custom.textColor.gray,
    },
  };
}
