import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { FormattedMessage } from 'react-intl';

import cn from 'classnames';

import ButtonBase from '@material-ui/core/ButtonBase';
import closeIcon from '../../assets/icons/close.svg';
import styles from './styles';
import CustomButton from '../CustomButton';

const CustomModal = ({
  classes,
  contentClass,
  children,
  modalTrigger,
  titleId,
  submitteText,
  cancelText,
  disableSubmitte,
  withoutTitle,
  onlyClose,
  showClose,
  isSubmiting,
  handleClose,
  onSubmite,
  isOpen,
  modalProps,
}) => {
  const handleSubmit = (e) => {
    e.stopPropagation();
    onSubmite(e);
  };

  const handlePreventClick = (e) => e.stopPropagation();

  return (
    <div>
      {modalTrigger && (
      <div>
        {modalTrigger}
      </div>
      )}
      {isOpen && (
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        closeAfterTransition
        className={classes.modal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        {...modalProps}
      >
        <Fade in={isOpen}>
          <div
            onClick={handlePreventClick}
            className={classes.wrap}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
          >
            <div className={classes.header}>
              {!withoutTitle && (
              <>
                <div><FormattedMessage id={titleId} /></div>
                {showClose && (
                  <div
                    className={classes.close}
                    onClick={handleClose}
                    onKeyPress={() => {
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    <img src={closeIcon} alt="icon" />
                  </div>
                )}
              </>
              )}
            </div>
            <div className={cn(classes.content, contentClass)}>
              {children}
            </div>
            <div className={classes.footer}>
              <div className={classes.buttonBlock}>
                {onlyClose ? (
                  <CustomButton
                    type="submit"
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                  >
                    <FormattedMessage id={cancelText} />
                  </CustomButton>
                ) : (
                  <>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      color="secondary"
                      onClick={handleSubmit}
                      loading={isSubmiting}
                      disabled={disableSubmitte}
                    >
                      <FormattedMessage id={submitteText} />
                    </CustomButton>

                    {showClose && (
                    <ButtonBase
                      onClick={handleClose}
                      className={classes.buttonCancel}
                      disableRipple
                    >
                      <FormattedMessage id={cancelText} />
                    </ButtonBase>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      )}
    </div>
  );
};

CustomModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  contentClass: PropTypes.string,
  children: PropTypes.node.isRequired,
  disableSubmitte: PropTypes.bool,
  submitteText: PropTypes.string,
  cancelText: PropTypes.string,
  modalTrigger: PropTypes.node,
  handleClose: PropTypes.func,
  isSubmiting: PropTypes.bool,
  withoutTitle: PropTypes.bool,
  onSubmite: PropTypes.func,
  onlyClose: PropTypes.bool,
  showClose: PropTypes.bool,
  titleId: PropTypes.string,
  isOpen: PropTypes.bool,
  modalProps: PropTypes.objectOf(PropTypes.any),
};

CustomModal.defaultProps = {
  titleId: 'advancedFrequencySettings',
  submitteText: 'saveSettings',
  disableSubmitte: false,
  handleClose: () => {},
  cancelText: 'cancel',
  onSubmite: () => {},
  modalTrigger: null,
  withoutTitle: false,
  isSubmiting: false,
  onlyClose: false,
  showClose: true,
  isOpen: false,
  classes: {},
  contentClass: '',
  modalProps: {},
};

export default withStyles(styles)(CustomModal);
