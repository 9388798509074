import React from 'react';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core';

export default withStyles({
  paper: {
    background: '#FFFFFF',
    boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    width: 240,

    '& > ul > li:not(:last-child)': {
      borderBottom: '1px solid lightgray',
    },
  },
  list: {
    padding: 0,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));
