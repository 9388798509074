const formatTime = (time, showMSec = false) => {
  const cutMsec = Math.floor((time % 1000) / 100);
  const sec = time / 1000;
  const cutSec = Math.floor(sec % 60);
  const min = Math.floor((sec % 3600) / 60);

  return showMSec
    ? `${min < 10 ? '0' : ''}${min}:${cutSec < 10 ? '0' : ''}${cutSec}:${cutMsec}`
    : `${min < 10 ? '0' : ''}${min}:${cutSec < 10 ? '0' : ''}${cutSec}`;
};

export default formatTime;
