import { call, takeEvery } from 'redux-saga/effects';

import * as organizationsActions from '../reducers/organizations/actions';
import ROUTES from '../../contants/routes';

const { REACT_APP_API_PREFIX } = process.env;

const redirectToOrganizationSpace = (subdomain) => {
  window.location.replace(`https://${subdomain}.${REACT_APP_API_PREFIX}${ROUTES.LOCATIONS.DEFAULT_PATH}`);
};

export function* handleSuccessCreateOrg(action) {
  const subdomain = action?.payload?.subdomain;
  if (subdomain) {
    yield call(redirectToOrganizationSpace, subdomain);
  }
}

export function* watchOrganizations() {
  yield takeEvery(organizationsActions.CREATE_ORGANIZATION_SUCCESS, handleSuccessCreateOrg);
}
