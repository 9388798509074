export default function styles(theme) {
  return {
    wrap: {
      background: theme.custom.textColor.primary,
      outline: 'none',
      borderRadius: '10px',
      '@media (max-width:1023px)': {
        width: '100%',
        borderRadius: '0',
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
      },
    },
    content: {
      width: '100%',
      padding: '40px',
      maxHeight: '456px',
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative',
      '@media (max-width:1023px)': {
        flexGrow: 2,
        padding: '20px 24px',
        maxHeight: 'none',
        width: 'auto',
      },
    },
    close: {
      cursor: 'pointer',
    },
    footer: {
      background: theme.custom.backgroundColor,
      padding: '32px 40px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '10px',
      '@media (max-width:1023px)': {
        padding: '20px',
      },
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    header: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '28px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      padding: '24px 40px',
      '@media (max-width:1023px)': {
        padding: '16px 20px',
      },
    },
    buttonBlock: {
      display: 'flex',
    },
    buttonCancel: {
      color: theme.palette.primary.main,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '48px',
      marginLeft: '52px',
      '&:disabled': {
        opacity: '0.5',
      },
    },
  };
}
