export function enhanceEntitiesId(entitiesList, key) {
  return entitiesList.map((entity) => ({
    ...entity,
    id: entity[key],
  }));
}

function normalizeEntities({
  entitiesList,
  normalizeKey = 'id',
}) {
  return entitiesList.reduce(
    (res, curr) => ({
      ...res,
      entities: { ...res.entities, [curr[normalizeKey]]: curr },
      ids: [...res.ids, curr[normalizeKey]],
    }),
    {
      entities: {},
      ids: [],
    },
  );
}

export default normalizeEntities;
