import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

import { gatewayStatuses } from '../../GatewayTable/constants';
import CustomTableCell from '../../../components/GatewayCustomTableCell';
import GatewayActionMenu from '../../../components/GatewayActionMenu';
import { gatewayPropTypes } from '../propTypes';

import styles from './styles';

function GatewayCard({
  classes,
  reFetchGateways,
  gateway: {
    id, name, status, eui, sn,
  },
  selectedIds,
  handleSelectId,
}) {
  return (
    <div className={classes.card}>
      <div className={classes.card_header}>
        <div className={classes.card_info}>
          <Checkbox
            color="primary"
            checked={selectedIds.includes(id)}
            onClick={() => handleSelectId(id)}
          />

          <div className={classes.card_status}>
            {gatewayStatuses[status]}
          </div>

          <div>{name}</div>
        </div>

        <GatewayActionMenu
          gatewayId={id}
          gatewayStatus={status}
          reFetchGateways={reFetchGateways}
        />
      </div>
      <div className={classes.card_content}>
        <div>
          <div className={classes.card_infoField}>EUI</div>
          <CustomTableCell>{eui}</CustomTableCell>
        </div>

        <div>
          <div className={classes.card_infoField}>SERIAL NUMBER</div>
          <CustomTableCell>{sn}</CustomTableCell>
        </div>
      </div>
    </div>
  );
}

GatewayCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  gateway: gatewayPropTypes.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  reFetchGateways: PropTypes.func.isRequired,
  handleSelectId: PropTypes.func.isRequired,
};

export default withStyles(styles)(GatewayCard);
