import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { NavLink } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import emptyState from '../../assets/images/empty-state.svg';
import styles from './styles';
import ROUTES from '../../contants/routes';
import CustomLink from '../CustomLink';

const EmptyPageState = ({
  additionalTitle,
  withoutRedirect,
  handleAddNew,
  emptyStateText,
  emptyStateLink,
  entityName,
  addTitle,
  linkKey,
  classes,
  entity,
  image,
}) => (
  <div className={classes.wrap}>
    <div className={classes.image}>
      <img src={image} alt="img" />
    </div>
    <div>
      <div className={classes.text}>
        <FormattedMessage id={emptyStateText} values={{ entity: entityName || entity }} />
      </div>
      <div className={classes.text}>
        <FormattedMessage id={emptyStateLink} />
        {additionalTitle && (<FormattedMessage id={additionalTitle} />)}
        {withoutRedirect ? (
          <CustomLink>
            <ButtonBase
              onClick={handleAddNew}
              disableRipple
            >
              <FormattedMessage id={addTitle} />
            </ButtonBase>
          </CustomLink>
        ) : (
          <CustomLink>
            <NavLink
              to={ROUTES[entity.toUpperCase()].createPath({ [linkKey]: 'add' })}
            >
              <FormattedMessage id={addTitle} />
            </NavLink>
          </CustomLink>
        )}
      </div>
    </div>
  </div>
);

EmptyPageState.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  additionalTitle: PropTypes.string,
  withoutRedirect: PropTypes.bool,
  emptyStateText: PropTypes.string,
  emptyStateLink: PropTypes.string,
  entityName: PropTypes.string,
  handleAddNew: PropTypes.func,
  addTitle: PropTypes.string,
  linkKey: PropTypes.string,
  entity: PropTypes.string,
  image: PropTypes.string,
};

EmptyPageState.defaultProps = {
  addTitle: 'emptyStateAdd',
  emptyStateText: 'emptyStateText',
  emptyStateLink: 'emptyStateLink',
  withoutRedirect: false,
  handleAddNew: () => {},
  additionalTitle: '',
  image: emptyState,
  entityName: '',
  linkKey: '',
  classes: {},
  entity: '',
};

export default withStyles(styles)(EmptyPageState);
