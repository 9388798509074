import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import email from '../../../../assets/images/email.svg';
import dots from '../../../../assets/images/dots-bg.svg';

const RightSideContent = ({
  image,
  classes,
  mainText,
  subText,
}) => (
  <div className={classes.wrap}>
    <div className={classes.container}>
      <div className={classes.imageWrap}>
        <div className={classes.dotsWrap}>
          <img src={dots} alt="img" />
        </div>
        <div className={classes.image}>
          <img className={classes.image} src={image} alt="img" />
        </div>
      </div>
      {mainText && <div className={classes.mainText}><FormattedMessage id={mainText} /></div>}
      {subText && <div className={classes.subText}><FormattedMessage id={subText} /></div>}
    </div>
  </div>
);

RightSideContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  image: PropTypes.node,
  subText: PropTypes.string,
  mainText: PropTypes.string,
};

RightSideContent.defaultProps = {
  classes: {},
  image: email,
  subText: '',
  mainText: '',
};

export default RightSideContent;
