import { API_REQUEST } from '../api/actions';
import { API_ENDPOINTS } from '../../../contants/api';

export const CONTACT_SUPPORT__START = 'CONTACT_SUPPORT__START';
export const CONTACT_SUPPORT__SUCCESS = 'CONTACT_SUPPORT__SUCCESS';
export const CONTACT_SUPPORT__ERROR = 'CONTACT_SUPPORT__ERROR';

export const contactSupport = (payload) => {
  const { data } = payload;

  return {
    type: API_REQUEST,
    payload: {
      url: `${API_ENDPOINTS.SUPPORT.CONTACT}`,
      method: 'post',
      data,
    },
    dispatch: {
      START: CONTACT_SUPPORT__START,
      SUCCESS: CONTACT_SUPPORT__SUCCESS,
      ERROR: CONTACT_SUPPORT__ERROR,
    },
  };
};
