import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';

const colorTheme = {
  Connected: (theme) => theme.custom.textColor.secondary,
  Disconnected: (theme) => theme.custom.error,
  Pending: (theme) => theme.palette.secondary.main,
  Default: (theme) => theme.custom.textColor.primary,
};

const GatewayTooltipIndicator = withStyles((theme) => ({
  tooltip: {
    color: ({ status = 'Default' }) => colorTheme[status](theme),
  },
}))(Tooltip);

export default GatewayTooltipIndicator;
