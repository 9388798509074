import React, { useCallback, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ButtonBase from '@material-ui/core/ButtonBase';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import CustomButton from '../../components/CustomButton';
import FormInfoBlock from '../../components/FormInfoBlock';
import { connectProduct, logOut, setLogoutStatus } from '../../redux/reducers/auth/actions';
import { sendApiRequest } from '../../redux/sagas/apiRequests';
import { runSaga } from '../../store';
import styles from './styles';

function ConnectProduct({ classes, handleSubmit, submitting }) {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const handleLogout = useCallback(() => {
    dispatch(logOut());
    dispatch(setLogoutStatus({ success: true }));
  }, [dispatch]);

  const onSubmit = async () => {
    setError(null);

    const { ok, result } = await runSaga(
      sendApiRequest,
      connectProduct(),
    ).toPromise();

    if (!ok) {
      setError(result.payload);
    }
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.title}>
        <FormattedMessage id="connectProductTitle" />
      </div>

      <div className={classes.text}>
        <FormattedMessage id="connectProductSubtitle" values={{ br: <br /> }} />
      </div>

      {
        error && (
          <FormInfoBlock type="error" error={error} />
        )
      }

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.buttonsBlock}>
          <CustomButton
            type="submit"
            variant="contained"
            color="secondary"
            loading={submitting}
            disabled={submitting}
          >
            <FormattedMessage id="connectProductFormSubmitBtn" />
          </CustomButton>

          <ButtonBase
            onClick={handleLogout}
            className={classes.cancelBtn}
            disableRipple
            disabled={submitting}
          >
            <FormattedMessage id="connectProductFormCancelBtn" />
          </ButtonBase>
        </div>
      </form>
    </div>
  );
}

ConnectProduct.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default compose(
  withStyles(styles),
  reduxForm({ form: 'CONNECT_PRODUCT_FORM' }),
)(ConnectProduct);
