export default function styles(theme) {
  return {
    modalContent: {
      width: '600px',

      [theme.breakpoints.down('sm')]: {
        width: '89%',
      },
    },
    content: {},
    hintWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    hintText: {
      fontSize: 14,
      color: '#555555',
      width: '100%',
      maxWidth: '51%',
      lineHeight: '20px',

      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        marginBottom: 20,
      },
    },
    ipLink: {
      padding: '5px 0',

      '& > a': {
        width: '100%',
        maxWidth: 95,
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        fontSize: 12,
        color: theme.palette.primary.main,
        borderBottom: `1.5px dotted ${theme.palette.primary.main}`,
      },

      '& > a > svg': {
        width: 15,
        height: 15,
        marginLeft: 5,
        fill: 'currentColor',
      },

      '&:hover': {
        '& > a': {
          color: theme.custom.linkColor,
          borderBottom: `1.5px dotted ${theme.custom.linkColor}`,
        },
      },
    },
    buttonDocumentation: {
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      height: 20,
      fontSize: 12,
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 36,
      padding: '5px 15px',
      transition: 'all 0.2s',

      '& > svg': {
        fill: 'currentcolor',
        marginLeft: 5,
      },

      '&:hover': {
        color: theme.custom.textColor.primary,
        background: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: `0px 0px 1px 1px ${theme.palette.primary.main}`,
      },
    },
    divider: {
      margin: '32px 0',
    },
  };
}
