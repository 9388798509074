export default function styles(theme) {
  return {
    wrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    },
    title: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '29px',
      lineHeight: '36px',
      letterSpacing: '-0.01em',
    },
    text: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.custom.textColor.gray,
      opacity: '0.8',
      margin: '40px 0',
    },
    titleLink: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette.primary.main,
      textDecoration: 'none',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      borderBottomStyle: 'dotted',
      transition: 'all .2s',
      '&:hover': {
        color: theme.custom.linkColor,
        borderBottom: `2px dotted ${theme.custom.linkColor}`,
      },
    },
  };
}
