import { useEffect, useState } from 'react';

export default function useAvatar(url) {
  const [avatar, setAvatar] = useState(url || null);

  useEffect(() => setAvatar(url || null), [url]);

  return {
    avatar,
    setAvatar,
  };
}
