export default function styles(theme) {
  return {
    appHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '20px 64px',
      backgroundColor: theme.palette.primary.main,

      [theme.breakpoints.down('sm')]: {
        padding: 20,
        height: 20,
      },
    },
    logoTabs: {
      display: 'flex',
    },
    logo: {
      marginRight: 100,

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  };
}
