import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getRequestStatus from '../../../redux/reducers/requestStatus/selectors';
import { getLogoutStatus } from '../../../redux/reducers/auth/selectors';
import useResetRequestStatus from '../../../hooks/useResetRequestStatus';
import { clearLogoutStatus } from '../../../redux/reducers/auth/actions';

const useGetRequestsStatuses = () => {
  const dispatch = useDispatch();

  const confirmEmailStatus = useSelector((state) => getRequestStatus(state, 'authConfirmEmail'));
  const confirmPasswordStatus = useSelector((state) => getRequestStatus(state, 'authConfirmPassword'));
  const signInStatus = useSelector((state) => getRequestStatus(state, 'authSignIn'));
  const logoutStatus = useSelector(getLogoutStatus);

  useResetRequestStatus('authConfirmEmail');
  useResetRequestStatus('authConfirmPassword');

  const handleClearLogoutStatus = useCallback(
    () => dispatch(clearLogoutStatus()),
    [dispatch],
  );

  useEffect(() => () => handleClearLogoutStatus(), [handleClearLogoutStatus]);

  return useMemo(
    () => ({
      confirmEmailStatus,
      signInStatus,
      confirmPasswordStatus,
      logoutStatus,
    }),
    [confirmPasswordStatus, confirmEmailStatus, logoutStatus, signInStatus],
  );
};

export default useGetRequestsStatuses;
