const en = {
  copyright: '© 2020 RAKwireless Technology Co., Ltd. All Rights Reserved, wisHM v.{version}',
  forum: 'Forum',
  support: 'Support',
  loginMainText:
    'Easily connect your RAK gateway to Helium - the People\'s Network',
  loginSubText:
    'Set up, connect, monitor, analyze and run things - all in one platform.',
  checkEmailTitle: 'Check your e-mail',
  resentEmailBtn: 'Resend confirmation',
  resentEmail: 'Resend e-mail',
  needHelp: 'Need help?',
  contactSupport: 'Contact customer support',
  headline: 'Headline',
  message: 'Message',
  messageSend: 'Your message has been send.',
  messageNotSend: 'Your message has not been sent.',
  send: 'Send',
  supportExplaine: 'Message will be sended to Customer Support. You can expect anwser in short time.',
  confirmResentBtn: 'Confirmation re-sent',
  emailResentBtn: 'Email re-sent',
  resetPasswordText: 'A reset link was sent to your email. If you don\'t see the email within a few minutes, check other places it might be, like your junk, spam, social, or other folders.',
  resentText:
    "We sent you a confirmation link. If you don't see our email within a few minutes, check other places it might be, like your junk, spam, social, or other folders.",
  confirmationText:
    "A confirmation link was sent to your email. If you don't see the email within a few minutes, check other places it might be, like your junk, spam, social, or other folders.",
  registrationTitle: 'Create an account',
  loginTitle: 'Sign in to your account',
  loginNewUser: 'New user?',
  emailConfirm: "Your e-mail address is confirmed. You're all set!",
  rakID: 'RAK ID',
  login: 'Log in',
  signIn: 'Sign in',
  signUp: 'Sign up',
  haveAccount: 'Already have an account?',
  name: 'Name',
  email: 'E-mail',
  countryField: 'Country (optional)',
  phoneField: 'Phone (optional)',
  phone: 'Phone',
  optional: ' (optional)',
  optionalLabel: 'optional',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  emailFieldTooltip:
    'Your e-mail address becomes your RAK ID and is used to sign in to all RAK products and services.',
  passwordLength: 'Should be at least 8 characters long',
  passwordSymbol: 'Has at least one special character',
  passwordNumber: 'Has at least one number',
  passwordLetter: 'Has at least one latin letter',
  hotspots: 'Hotspots',
  addHotspot: 'Add hotspot',
  addHeliumWallet: 'Add Helium Wallet Address',
  newHotspot: 'New hotspot',
  addWallet: 'Add Wallet',
  howToFindWallet: 'How to find wallet address',
  step: 'Step',
  openHeliumApp: 'Open Helium app, go to Account and choose Receive',
  copyOrShareAddress: 'Copy or Share address and paste in WisHM',
  heliumWallet: 'Helium Wallet',
  tellUsWalletAddress: 'Before you add a device, please tell us your helium wallet address. You can change it later in profile.',
  cantFindWalletAddress: 'Can’t find your wallet address? Find out ',
  here: 'here',
  clickHere: 'Click here',
  notHaveAccount: 'Don\'t have a Helium account yet?',
  downloadApp: 'Download the app',
  organizations: 'Organizations',
  organization: 'organization',
  emptyStateText: 'You don’t have any {entity} added yet.',
  emptyStateSecondText: 'You haven’t added any {entity} yet.',
  emptyStateLink: 'Start off by ',
  emptyStateSecondLink: 'You may choose to ',
  emptyStateLocationLink: 'You need to ',
  emptyStateAdd: 'adding one.',
  emptyStateConfigLocation: 'configure location first.',
  emptyStateSecondAdd: 'add one now.',
  emptyStateGateways: 'locations section',
  emptyStateAddTittle: 'going to your ',
  locations: 'Locations',
  gateways: 'Gateways',
  members: 'Members',
  nodes: 'Nodes',
  basicHotspotDetails: 'Basic hotspot details',
  findBestLocation: 'Find best location',
  hideMap: 'Hide Map',
  showMap: 'Show Map',
  adjustLocationPin: 'you can adjust location pin on map',
  selectAccurateLocation: 'Select an accurate location, <bold>less than 100m</bold> of the exact location of the gatewey. A non-accurate location can result in <bold>a lower score</bold> in the blockchain, and your hotspot may lose the ability to challenge or mine tokens.',
  hotspotEui: 'Hotspot EUI',
  hotspotNameOptional: 'Hotspot name (optional)',
  nextStep: 'Next step',
  prevStep: 'Previous step',
  general: 'General',
  payment: 'Payment',
  locationNotFound: 'Hotspot location doesn’t exist. Please try to find correct location.',
  somethingWentWrong: 'Something went wrong, try changing your location',
  configuration: 'Configuration',
  assertLocationFee: 'Assert Hotspot Location Fee',
  choosePreferPayment: 'Choose your preferred payment for the ',
  helliumApp: 'Helium app',
  helliumCliTool: 'Helium Wallet CLI Tool',
  addressToBurn: 'Address to burn HNT to',
  amount: 'Amount',
  includeMemo: 'Include MEMO',
  burn: 'Burn ',
  successfullyPaidInfo: 'Once you have successfully paid, proceed in clicking “Next step”.',
  oneMoreThing: 'One more thing!',
  payConfirmeInfo: 'Once you go to the next step, you won’t be able to recover the QR code (the unique memo). Please make sure you have successfully paid through the Helium app.',
  confirmPayment: 'Confirm payment',
  backToPayment: 'Back to Payment',
  youreAllSet: 'You’re all set!',
  finishDescription: 'Once your payment has been received, your miner will be provisioned. The configuration will be pushed to your hotspot.',
  goToHotspots: 'Go to Hotspot list',
  hotspotAwailabilityDescription: 'While provisioning, your hotspot will appear as ‘pending’. Once all has been verified, the status will change to ‘connected,’ and WisHM will push the Basics Station configuration to your Hotspot.',
  shouldConfigurationFailed: 'Should the configuration fail to be pushed to your hotspot within the next 60 minutes, then please check the detailed view from your gateway for the configuration.',
  relocateTitle: 'Re-locate Hotspot',
  timesUp: 'Time\'s up!',
  sessionRunOutTime: 'Your session has run out of time. If you didn\'t make a payment, or your payment was not successfully received, your hotspot will be removed from your profile within 30 minutes.',
  relocateSessionRunOutTime: 'Your session has run out of time. If you didn\'t make a payment, or your payment was not successfully received, your new location will not be saved and the hotspot will use the old one.',
  understand: 'I understand',
  updatingLocation: 'Updating location',
  updatingDescription: 'The location of your miner will be updated once we have verified your payment.',
  updatingDisclaimer: 'It can take up to 30 minutes before the updated location and status are visible in WisHM',
  locationView: 'View location details',
  locationSetup: 'To add gateways and nodes, first finish the network configuration of your location.',
  configureLocation: 'Configure location',
  settings: 'Settings',
  suspendOrg: 'Suspend organization',
  editDetails: 'Edit details',
  activeSub: 'Subscription Active',
  inactiveSub: 'Subscription Inactive',
  newOrganization: 'New organization',
  newOrganizationSubTitle:
    'Everything starts with an organization. All assets including your devices, equipment and information belong to one.',
  companyName: 'Company name',
  licenceKey: 'Licence key',
  subdomain: 'Subdomain',
  address: 'Address',
  city: 'City',
  state: 'State/Province',
  zipCode: 'ZIP Code',
  companyLicence: 'Company and licence',
  generalInfo: 'General information',
  networkConfiguration: 'Network configuration',
  cancel: 'Cancel',
  createOrganization: 'Create organization',
  newLocation: 'New location',
  newLocationSubTitle: 'A single organization starts with one location. Organizations can also be divided into multiple locations.',
  online: 'Online',
  offline: 'Offline',
  logout: 'Logout',
  country: 'Country',
  locationName: 'Location name',
  locationAddress: 'Location address',
  saveLocation: 'Save location',
  addressAsCompany: 'Copy organization address',
  allLocations: 'All locations',
  generalConfiguration: 'General configuration',
  locationGateways: 'Location gateways',
  connectedGateways: 'Connected gateways',
  model: 'model',
  firmware: 'Firmware',
  unassingGateway: 'Unassign gateway',
  unassingGatewayText: 'At unassigning, the gateway will be removed from the location and its configuration will be reset. The gateway will remain in the organization gateway list as Unassigned. Do you want to unassign this gateway?',
  unassign: 'Unassign',
  workMode: 'Work mode',
  packetForwarder: 'Packet forwarder',
  basicStation: 'Basic station',
  loraNetwork: 'LoRa network server',
  loraGateway: 'LoRa Gateway MQTT Bridge',
  udpProtocol: 'Semtech UDP GWMP Protocol',
  staticInterval: 'Statistic Interval (s)',
  serverAddress: 'Server Address',
  serverUrl: 'Server Url',
  serverPortUp: 'Server Port Up',
  serverPortDown: 'Server Port Down',
  pushTimeout: 'Push Timeout (ms)',
  keepAliveInterval: 'Keepalive Interval (s)',
  autoRestartTreshold: 'Auto-restart Treshold',
  region: 'Region',
  loraWanPublic: 'LoRa WAN Public',
  workModeSection: 'Work mode selection',
  protocolSection: 'Protocol selection',
  protocolParams: 'UDP Protocol Parameters',
  frequencyPlan: 'Location Frequency Plan',
  cupsBoot: 'CUPS-BOOT server',
  cupsServer: 'CUPS Server',
  lnsServer: 'LNS Server',
  serverPort: 'Server Port',
  protocol: 'Protocol',
  authenticationMode: 'Authentication Mode',
  token: 'Token',
  basicServerType: 'Basic Station Server Type',
  basicServerSetup: 'Basic station server setup',
  loraBridgeParameters: 'LoRa Gateway MQTT Bridge Parameters',
  mqttProtocol: 'MQTT Protocol',
  mqttBrokerAddress: 'MQTT Broker Address',
  mqttBrokerPort: 'MQTT Broker Port',
  mqttVersion: 'MQTT Version',
  clientID: 'Client ID',
  qos: 'QoS',
  cleanSession: 'Clean session',
  userAuthentication: 'Enable User Authentication',
  username: 'Username',
  sslTslMode: 'SSL/TLS Mode',
  tlsVersion: 'TLS Version',
  caCertificate: 'CA certificate',
  clientCertificate: 'Client certificate',
  keyPassphrase: 'Key Pass Phrase',
  clientKey: 'Client key',
  uplinkTopic: 'Uplink Topic',
  downlinkTopic: 'Downlink Topic',
  downlinkAcknowledgeTopic: 'Downlink Acknowledge Topic',
  gatewayStatisticTopic: 'Gateway Statistic Topic',
  trustCaCertificate: 'Trust (CA Certificate)',
  advancedFrequencySettings: 'Advanced Frequency settings',
  advancedFrequencySettingsBtn: 'Advanced Frequency Settings >',
  saveSettings: 'Save Settings',
  standardLoRaChannel: 'Standard LoRa Channel',
  multiSFLoRaChannel: 'Multi-SF LoRa Channel',
  frequencySubBand: 'Frequency Sub-Band',
  frequency: 'Frequency (MHz)',
  sf: 'SF',
  add: 'Add',
  bandwidth: 'Bandwidth (KHz)',
  bitRate: 'Bit Rate (Kbps)',
  fskChannel: 'FSK Channel',
  channel: 'Channel',
  saveConfiguration: 'Save configuration',
  skipSetup: 'Skip setup',
  skipNetworkSetup: 'Skip network setup',
  emptyField: 'This field cannot be empty.',
  notLoaded: 'Not Loaded',
  modules: 'Modules',
  packets: 'Packets',
  receivedPackets: 'Received Packets',
  transmittedPackets: 'Transmitted Packets',
  location: 'Location',
  copied: 'Copied!',
  serialNumber: 'Serial Number',
  eui: 'eui',
  frequencyBand: 'Frequency Band',
  macAddress: 'Mac Address',
  reportedCoordinates: 'Reported Coordinates',
  standardLocationCoordinates: 'Standard (Location) Coordinates',
  lte: 'lte',
  lteAvailable: 'LTE Available',
  lteUnavailable: 'LTE Unavailable',
  openSshTerminal: 'Open SSH Terminal',
  sshTerminal: 'SSH Terminal',
  gatewaySerialNumber: 'Gateway serial number',
  gatewayName: 'Gateway name',
  addGateway: 'Add gateway',
  saveAndAdd: 'Save and add another',
  saveAndClose: 'Save and close',
  addGatewaySuccess: 'Gateway added successfully!',
  applyChanges: 'Apply changes',
  apply: 'Apply',
  applySettingsText: 'The settings of all devices assigned to this location will be updated. Are you sure you want to apply the changes?',
  saveChanges: 'Save changes',
  firmwareUpdate: 'Firmware update',
  updateFirmware: 'Update firmware',
  releaseNotes: 'Release notes',
  firmwareVersion: 'Firmware version',
  firmwareDate: 'Firmware date',
  devicesForUpdate: 'Only devices with differing version numbers will be updated.',
  orgSuccessUpdated: 'Organization was successfully updated.',
  locationSuccessCreated: 'Location was successfully created.',
  locationSuccessUpdated: 'Location configuration is updated.',
  noMode: 'No mode',
  retain: 'Retain',
  noData: 'No data',
  unassigned: 'Unassigned',
  unnamed: 'Unnamed',
  desiredFirmware: 'Desired firmware version:',
  reportedFirmware: 'Reported firmware version:',
  locationAddGatewayError: 'Location network configuration is not set up yet. Please provide required location details before adding gateways.',
  successAddedGateway: 'Gateway added successfully!',
  noUpdatesAvailable: 'No updates are available at the moment.',
  gatewaysUpToDate: 'All location gateways are up-to-date, no firmware update is required at the moment.',
  successUpdateFirmware: 'Updates have been successfully sent to all location gateways.',
  errorUpdateFirmware: 'Updates have been sent to the location gateways. Some issues occurred, not all gateways may be updated.',
  gatewayDetails: 'Gateway details >',
  termsSmall: 'terms and conditions.',
  termsAndConditions: 'Terms and Conditions',
  privacyTitle: 'Privacy Policy',
  privacy: 'Privacy Policy',
  iAgreeWith: 'I agree with',
  close: 'Close',
  configLocationNetwork: 'To add gateways, you’ll have to set up network first. Click on the button to start configuring network for this location.',
  copySn: 'Before unassigning, we recommend to copy and store the device serial number.',
  snWasCopied: 'Serial number copied!',
  warningLeaveMessage: 'If you leave this page now, some changes may be lost. Do you want to drop editing?',
  leave: 'Leave',
  leavePage: 'Leave Page',
  imHere: 'I\'m here',
  hereModalTitle: 'Session is about to expire',
  stillHere: 'Are you still here? Your session is about to expire because of inactivity timeout.',
  sessionExpired: 'Your session has expired because of inactivity. Please log in again.',
  connectedError: 'Internal error: Unable to receive user data',
  successLogout: 'You have successfully logged out.',
  linkExpiredTitle: 'Your confirmation link has expired',
  linkExpiredText: 'Confirmation links are only valid 24 hours after being sent by e-mail. But don\'t worry, we can resend your confirmation anytime. ',
  linkUnverifiedText: 'Your email address is unverified. The verification link was sent , so please check your inbox.',
  forgotPassword: 'I forgot my password',
  forgotPasswordInfo: 'Please enter your RAK ID and we\'ll email you a link to reset your password.',
  backToLogin: 'Back to log in',
  emailMe: 'Email me',
  setNewPassword: 'Set your new password',
  saveNewPassword: 'Save new password',
  tokenInvalidTitle: 'The link is invalid',
  tokenInvalidText: 'Your password reset link is invalid. Please try to reset your password again.',
  tokenExpiredTitle: 'The link has expired',
  tokenExpiredText: ' Your password reset link has expired. Please try to reset your password again.',
  successResetPassword: 'Your password was successfully reset. Please login with the new credentials.',
  yourProfile: 'Your profile',
  RAKInfoLabel: 'Your RAK ID information',
  RAKInfoSubLabel: 'Your RAK ID is universal for all RAK applications and services. You can edit your RAK-ID through the RAK-ID Portal. ',
  RAKInfoName: 'Name',
  RAKInfoEmail: 'Email',
  RAKInfoCountry: 'Country (optional)',
  RAKInfoPhoneNumber: 'Phone Number (optional)',
  RAKInfoTimeZone: 'Time zone',
  RAKInfoDateFormat: 'Date format',
  RAKInfoEditButton: 'Edit your RAK ID',
  heliumWalletUpdateSuccess: 'Your new Helium Wallet Address was successfully saved.',
  heliumWalletUpdateError: 'Your new Helium Wallet Address wasn`t saved.',
  disconnectAccountError: 'Your account wasn`t disconnected',
  disconnectAccountModalTitle: 'Disconnect my account',
  disconnectAccountModalNotice1: 'By disconnecting your account, all of all its associated data, and information will be removed from WisHM space, but account still be active for other RAK spaces.',
  disconnectAccountModalNotice2: 'Before disconnecting your account, please remove your active Hotspot first.',
  disconnectAccountModalFieldLabel: 'Type "DISCONNECT"',
  disconnectAccountModalBtn: 'Disconnect',
  disconnectAccountLabel: 'Disconnect account',
  disconnectAccountBtn: 'Disconnect your account',
  disconnectAccountHint: 'By disconnecting your account, all of all its associated data, and information will be removed from WisHM space, but account still be active for other RAK spaces.',
  deleteAccountLabel: 'Delete account',
  deleteAccountBtn: 'Delete your account',
  deleteAccountHint: 'By deleting your account, all of all its associated data, and information will be removed.',
  deleteAccountModalTitle: 'Contact support to delete account',
  deleteAccountModalNotice: 'Message will be sended to Customer Support. You can expect answer in short time.',
  deleteAccountModalBtn: 'Send',

  // gateway table
  gatewayTableHeaderStatus: 'status',
  gatewayTableHeaderName: 'hotspot name',
  gatewayTableHeaderSN: 'serial number',
  gatewayTableHeaderEUI: 'eui',
  gatewayStatusConnected: 'Connected',
  gatewayStatusDisconnected: 'Disconnected (>24)',
  gatewayStatusPending: 'Pending',
  gatewayDeleteBtn: 'Delete',
  gatewayRelocateBtn: 'Re-locate',
  uri: 'URI',
  accessKey: 'Access Key',
  port: 'Port',
  certificate: 'Certificate',
  uptime: 'Uptime',
  latitude: 'Latitude',
  longitude: 'Longitude',
  gatewayCertificate: '{prefix} certificate',
  gatewayDeleteModalNotice: "Are you sure you want to delete {gatewayCount} hotspot{prefix} and miner{prefix}? Type 'DELETE'",
  gatewayDeleteModalFieldLabel: "Type 'DELETE'",
  gatewayDeleteModalTitle: 'Delete hotspot and miner',
  gatewaysDeleteModalTitle: 'Delete hotspots and miners',
  gatewayDeleteModalBtn: 'Delete',
  gatewayBulkActionDetails: 'View Hotspot Details',
  gatewayBulkActionRelocate: 'Re-locate Hotspot',
  gatewayBulkActionDelete: 'Delete Hotspot',

  gatewayConfirmRelocateModalTitle: 'Re-locate Hotspot',
  gatewayConfirmRelocateModalNotice: "If you choose to relocate your hotspot, the currently registered location will be erased from our systems and you will have to re-pay the 'Assert Gateway Location' fee.",
  gatewayConfirmRelocateModalFieldLabel: "Type 'RELOCATE'",
  gatewayConfirmRelocateModalButton: 'Re-locate Hotspot',

  cantFindHotspotDetails: 'For instruction on how to find your Hotspot EUI and Serial number ',

  hotspotHelpModalTitle: 'Locate Hotspot Information',
  hotspotHelpDocumentationButton: 'RAK Documentation Center',
  hotspotHelpIPText: 'Locate your hotspot EUI and Serial number by accessing it locally (default IP-address).',
  hotspotHelpDocumentationText: 'For detailed information on how to access your hotspot locally, visit the RAK Documentation Center.',

  connectProductTitle: 'Connecting RAK ID with WisHM',
  connectProductSubtitle: 'This is your first login to the WisHM product.{br}Do you want to proceed and authorize your access using your RAK ID in WisHM for future work?',
  connectProductFormSubmitBtn: 'Yes',
  connectProductFormCancelBtn: 'No',

  registrationPageCustomError1: 'A RAK ID with the provided email is already registered. Try ',
  registrationPageCustomError2: 'log in ',
  registrationPageCustomError3: 'instead. If you forgot your credentials, you can ',
  registrationPageCustomError4: 'reset your password.',
};

export default en;
