import {
  Map, fromJS,
} from 'immutable';

import * as actions from './actions';

export const initialState = Map({});

function hotspotsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.UPDATE_HOTSPOT_CREATION_TIMER: {
      return state.set('isStartCreation', fromJS(payload.isStartCreation));
    }
    case actions.UPDATE_HOTSPOT_RELOCATION_TIMER: {
      return state.set('isStartRelocation', fromJS(payload.isStartRelocation));
    }
    default:
      return state;
  }
}

export default hotspotsReducer;
